import * as React from "react";
const TaxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={39}
      height={39}
      x={0.5}
      y={0.5}
      fill="#707070"
      fillOpacity={0.12}
      rx={5.5}
    />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#ADB2BB" rx={5.5} />
    <path
      stroke="#6D7584"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.21 23.89A10 10 0 1 1 16 10.83M30 20a10 10 0 0 0-10-10v10h10Z"
    />
  </svg>
);
export default TaxIcon;
