import { Box, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import typography from "theme/typography";
import dayjs from "dayjs"; // Import dayjs for formatting
import { DateTimePicker } from "@mui/x-date-pickers";

interface ICustomDatePicker extends React.ComponentProps<typeof DatePicker> {
  label?: string;
  selectedDate: dayjs.Dayjs | null;
  onChange: (date: dayjs.Dayjs | null) => void;
  placeholder?: string;
  time?: boolean;
}

const CustomDatePicker = ({
  label,
  selectedDate,
  onChange,
  placeholder,
  time,
  ...props
}: ICustomDatePicker) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        {label && (
          <Typography variant="h6" fontWeight={500} mb={1}>
            {label}
          </Typography>
        )}
        {time ? (
          <DateTimePicker
            value={selectedDate}
            onChange={(date) => onChange(date)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                height: 48,
                "& fieldset": {
                  borderColor: "#D0D5DD",
                },
                "&:hover fieldset": {
                  borderColor: "#D0D5DD",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#D0D5DD",
                },
              },
              "& .MuiFormHelperText-root": {
                fontSize: "14px",
                marginLeft: 0,
                marginTop: "2px",
              },
            }}
            slotProps={{
              textField: {
                placeholder: placeholder,
                fullWidth: true,
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "12px 14px",
                    ...typography.h6,
                    color: "primary.900",
                    fontWeight: 400,
                    "&::placeholder": {
                      ...typography.h6,
                      color: "primary.main",
                    },
                  },
                },
              },
            }}
            {...props}
          />
        ) : (
          <DatePicker
            value={selectedDate}
            onChange={(date) => onChange(date)}
            format="MMMM, DD"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                height: 48,
                "& fieldset": {
                  borderColor: "#D0D5DD",
                },
                "&:hover fieldset": {
                  borderColor: "#D0D5DD",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#D0D5DD",
                },
              },
              "& .MuiFormHelperText-root": {
                fontSize: "14px",
                marginLeft: 0,
                marginTop: "2px",
              },
            }}
            slotProps={{
              textField: {
                placeholder: placeholder,
                fullWidth: true,
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "12px 14px",
                    ...typography.h6,
                    color: "primary.900",
                    fontWeight: 400,
                    "&::placeholder": {
                      ...typography.h6,
                      color: "primary.main",
                    },
                  },
                },
              },
            }}
            {...props}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
