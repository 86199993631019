import * as React from "react";
const ArrowLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#0C425F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M15.833 10.001H4.166m0 0 5.833 5.834M4.166 10l5.833-5.833"
    />
  </svg>
);
export default ArrowLeftIcon;
