import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/system";
import React, { ReactElement } from "react";

// Define the CustomTooltip component with TypeScript
interface CustomTooltipProps extends TooltipProps {
  children: ReactElement;
}

const CustomTooltip = styled(
  ({ className, children, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1C2433", // Dark background color
    color: "#ffffff", // White text color
    boxShadow: theme.shadows[1],
    fontSize: 11,
    borderRadius: "8px",
    padding: "8px 12px",
    height: 34,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#1C2433", // Dark arrow to match the background
  },
}));

export default CustomTooltip;
