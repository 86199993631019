import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

// Create a new unit
export const createUnit = createAsyncThunk(
  "admin/createUnit",
  async (data: { name: string }, { rejectWithValue }) => {
    try {
      const response = await request.post("/admin/units", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Update the name of a unit by ID
export const updateUnitName = createAsyncThunk(
  "admin/updateUnitName",
  async ({ id, name }: { id: string; name: string }, { rejectWithValue }) => {
    try {
      const response = await request.patch(`/admin/units/${id}`, { name });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Get all units
export const getUnits = createAsyncThunk(
  "admin/getUnits",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get("/admin/units");
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Get a unit by ID
export const getUnitById = createAsyncThunk(
  "admin/getUnitById",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await request.get(`/admin/units/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Delete a unit if empty by ID
export const deleteUnitIfEmpty = createAsyncThunk(
  "admin/deleteUnitIfEmpty",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await request.delete(`/admin/units/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Get all users with role Client
export const getClients = createAsyncThunk(
  "admin/getClients",
  async (
    { page, limit }: { page: number; limit: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get("/admin/clients", {
        params: {
          page,
          limit,
        },
      });

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Get all users with role Commander
export const getCommanders = createAsyncThunk(
  "admin/getCommanders",
  async (
    { page, limit }: { page: number; limit: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get("/admin/commanders", {
        params: {
          page,
          limit,
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Get a user by ID
export const getUserById = createAsyncThunk(
  "admin/getUserById",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await request.get(`/admin/users/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Delete a user by ID
export const deleteUserById = createAsyncThunk(
  "admin/deleteUserById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await request.delete(`/admin/users/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
