import { Box, Button, Typography } from "@mui/material";
import { WarningIcon } from "assets/icons";
import { CenterModal } from "components";
import React from "react";

const DeleteGoalModal = ({ open, handleCloseModal, handleDelete }) => {
  return (
    <CenterModal
      close={false}
      open={open}
      onClose={handleCloseModal}
      sx={{ width: 480, py: 6, alignItems: "center" }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <WarningIcon />
      </Box>

      <Typography
        variant="h4"
        fontWeight={600}
        textAlign={"center"}
        color={"primary.900"}
        my={6}
      >
        Are you sure you want to delete the goal?
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
          sx={{
            width: "45%",
            borderRadius: "8px",
            textTransform: "none",
            fontWeight: 500,
          }}
        >
          Cancel
        </Button>

        {/* Delete Button */}
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          sx={{
            width: "45%",
            borderRadius: "8px",
            textTransform: "none",
            fontWeight: 500,
          }}
        >
          Delete
        </Button>
      </Box>
    </CenterModal>
  );
};

export default DeleteGoalModal;
