export const periodTabs = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];
export const notificationaTabs = [
  {
    label: "All Notifications",
    value: "All Notifications",
  },
  {
    label: "Saved Notification",
    value: "Saved Notification",
  },
  {
    label: "Resolved Notification",
    value: "Resolved Notification",
  },
];
