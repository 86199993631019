import { Box, LinearProgress, Typography } from "@mui/material";
import { BarElement, Chart as ChartJS } from "chart.js";
import { Select } from "components";
import { periodOptions } from "constants/common";
import { months } from "datas/chart";
import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "store";
import { getTransactionsAnalytics } from "store/actions/FinancialStatementsAction";
import { selectTransactionsAnalytics } from "store/reducers/TransactionReducer";
import { TransactionPeriodStatus } from "types/types";
import convertToMonthlyArray from "utils/convertToMonthlyArray";
const FinancialAnalytics = () => {
  const dispatch = useAppDispatch();
  const { monthlyExpense, monthlyIncome } = useAppSelector(
    selectTransactionsAnalytics
  );
  const [loading, setLoading] = useState(false);

  ChartJS.register(BarElement);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true, // Disable stacking on the x-axis
      },
      y: {
        stacked: true, // Disable stacking on the y-axis
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "K"; // Format y-axis values
            }
            return value;
          },
        },
      },
    },
  };
  const financialAnalyticsData = {
    labels: months,
    datasets: [
      {
        label: "Expenses",
        data: convertToMonthlyArray(monthlyExpense),
        backgroundColor: "#FFA500",
        barPercentage: 0.9,
        borderRadius: 8,
      },
      {
        label: "Incomes",
        data: convertToMonthlyArray(monthlyIncome),
        backgroundColor: "#32D583",
        barPercentage: 0.9,
        borderRadius: 8,
      },
    ],
  };
  const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0]?.value);

  const getAnalyticsData = useCallback(
    async (value?: TransactionPeriodStatus) => {
      setLoading(true);
      await dispatch(
        getTransactionsAnalytics({
          datePeriod: (value?.toLocaleLowerCase() ||
            selectedPeriod?.toLowerCase()) as TransactionPeriodStatus,
        })
      );
      setLoading(false);
    },
    [dispatch, selectedPeriod]
  );

  useEffect(() => {
    if (
      Object?.keys(monthlyIncome || {})?.length > 0 &&
      Object?.keys(monthlyExpense || {})?.length > 0
    )
      return;
    getAnalyticsData();
  }, [
    dispatch,
    selectedPeriod,
    getAnalyticsData,
    monthlyExpense,
    monthlyIncome,
  ]);
  return (
    <Box
      px={6}
      py={4}
      bgcolor="white"
      borderRadius={4}
      sx={{ border: "1px solid #E0E0E0" }}
      height={"100%"}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5" fontWeight={600} color={"primary.700"}>
          Analytics
        </Typography>
        <Box display={"flex"} alignItems={"center"} gap={6}>
          {[...financialAnalyticsData?.datasets]
            ?.reverse()
            ?.map((dataset, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: 3,
                  fontSize: "14px",
                }}
              >
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: dataset.backgroundColor,
                    borderRadius: 4,
                  }}
                ></Box>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.500"}
                  ml={2}
                >
                  {dataset.label}
                </Typography>
              </Box>
            ))}
          <Select
            options={periodOptions}
            value={selectedPeriod}
            onChange={(e) => {
              getAnalyticsData(e.target.value as TransactionPeriodStatus);
              setSelectedPeriod(e.target.value);
            }}
            sx={{
              width: 252,
              height: 44,
              borderColor: "red",
              fontWeight: 400,
              color: "primary.500",
            }}
          />
        </Box>
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <Bar data={financialAnalyticsData} options={options as unknown} />
      )}
    </Box>
  );
};

export default FinancialAnalytics;
