import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Spinner from "../../assets/images/spinner.gif";
import { useAppDispatch, useAppSelector } from "store";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { Success, Error } from "components";
import { signOnFiserv } from "store/actions/AuthAction";
import { getUser } from "store/reducers/AuthReducer";

const ConnectAcoountModal = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);
  const user = useAppSelector(getUser);
  const [sessionToken, setsessionToken] = useState(null);

  const connectMyPayAccount = useCallback(async () => {
    const response = await dispatch(
      signOnFiserv({
        userId: user?.data?.email,
        password: localStorage.getItem("password") || "Test123!",
      })
    );
    setsessionToken(response?.payload?.token);
  }, [dispatch, user?.data?.email]);

  useEffect(() => {
    connectMyPayAccount();
  }, [connectMyPayAccount]);

  const handleConnectOtherAccounts = () => {
    // Open the redirection.html file located in the public folder in a new tab

    // Open the redirection.html file and pass sessionToken, return_url, and error_url as query parameters
    window.open(`/redirection.html?sessionToken=${sessionToken}`, "_blank");
  };

  return (
    <>
      {!loading ? (
        sessionToken ? (
          <>
            <Success
              message={"You have successfully connected MyPay Account"}
              nextButtonText={"Connect other accounts"}
              onNext={handleConnectOtherAccounts} // Open redirection.html
              secondBtn
              onSkip={onNext}
            />
          </>
        ) : (
          <Error
            nextButtonText={"Connect Later"}
            onNext={() => onNext()}
            secondBtn
            onClickSecontBtn={connectMyPayAccount}
          />
        )
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={Spinner} alt="spinner" width={90} height={90} />
          <Typography
            variant="h4"
            color={"primary.900"}
            fontWeight={500}
            ml={-5}
          >
            Redirecting to MyPay...
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ConnectAcoountModal;
