import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  getAllNotifications,
  getNotificationSettings,
  updateNotificationSettings,
  updateNotificationStatus,
} from "../actions/NotificationAction";
import { RootState } from "store";

// Define the initial state for the notifications slice
const initialState = {
  notifications: [],
  settings: [],
};

// Create the notification slice
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // You can add any reducers here if needed (for synchronous state changes)
  },
  extraReducers: (builder) => {
    // Get all notifications
    builder.addCase(getAllNotifications.fulfilled, (state, { payload }) => {
      state.notifications = payload;
    });

    // Update notification status
    builder.addCase(
      updateNotificationStatus.fulfilled,
      (state, { payload }) => {
        // Update the notification status in the list (if necessary)
        const updatedNotification = payload?.data;
        const index = state.notifications.findIndex(
          (n) => n.id === updatedNotification?.id
        );
        if (index !== -1) {
          state.notifications[index] = updatedNotification;
        }
        if (index !== -1 && payload.remove) {
          //remove this notification
          state.notifications.splice(index, 1);
        } else if (payload.remove) {
          //add item
          state.notifications.push(updatedNotification);
        }
      }
    );

    //get notification settings

    builder.addCase(getNotificationSettings.fulfilled, (state, { payload }) => {
      state.settings = payload;
    });

    //update notification settings
    builder.addCase(
      updateNotificationSettings.fulfilled,
      (state, { payload }) => {
        const index = state.settings.findIndex((s) => s.name === payload.name);
        if (index !== -1) {
          state.settings[index] = payload;
        }
      }
    );
  },
});

const selectNotificationState = (state: RootState) => state.notifications;
export const selectNotifications = createSelector(
  selectNotificationState,
  (state) => state?.notifications
);
export const selectNotificationSettings = createSelector(
  selectNotificationState,
  (state) => state?.settings
);
//check isAllRead
export const selectIsAllRead = createSelector(
  selectNotifications,
  (notifications) => notifications.every((notification) => notification.read)
);

// Export the reducer
export default notificationSlice.reducer;
