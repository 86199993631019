import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import React from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { spouseOtpSend } from "store/actions/AuthAction";
import { setRole } from "store/reducers/AuthReducer";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { Button, Input } from "../";
import { registerSchema } from "../../validation/authValidation";

interface IRegisterForm {
  onNext: (data: string) => void;
}
interface RegisterFormInputs {
  email: string;
}
const SpouseRegisterForm = ({ onNext }: IRegisterForm) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(registerSchema) as Resolver<RegisterFormInputs>,
    mode: "onChange",
  });

  const onSubmit = async (data: RegisterFormInputs) => {
    const inviteToken = window.location.pathname.split("/")[2];
    const body = { ...data, inviteToken };
    const response = await dispatch(spouseOtpSend(body));
    if (response?.meta?.requestStatus === "fulfilled") {
      dispatch(setRole("FamilyMember"));
      onNext(data.email);
    }
  };
  return (
    <Box width={"100%"}>
      <FormControl
        fullWidth
        sx={{ gap: 6, mb: 1 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              required
              label="Enter email"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...field}
            />
          )}
        />
      </FormControl>
      <Button
        loading={loading}
        onClick={handleSubmit(onSubmit)}
        type="submit"
        disabled={!isDirty || !isValid}
        variant="contained"
        fullWidth
        label="Continue"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default SpouseRegisterForm;
