import * as React from "react";
const SmallMaximizeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#F9F5FF" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#D6BBFB" rx={5.5} />
    <path
      stroke="#6941C6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18 10h4m0 0v4m0-4-4.667 4.667M14 22h-4m0 0v-4m0 4 4.667-4.667"
    />
  </svg>
);
export default SmallMaximizeIcon;
