import React from "react";
import { Slider as MUISlider, styled } from "@mui/material";

// Custom styling for the slider
const CustomSlider = styled(MUISlider)({
  color: "primary.main",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.3,
    backgroundColor: "primary.100",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "white",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgba(10, 58, 89, 0.16)",
    },
    "&.Mui-active": {
      boxShadow: "0px 0px 0px 14px rgba(10, 58, 89, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "#0A3A59",
  },
});
interface SliderProps
  extends Omit<React.ComponentProps<typeof MUISlider>, "onChange"> {
  onChange: (event: Event, value: number | number[]) => void;
}

const Slider = ({ onChange, ...props }: SliderProps) => {
  return <CustomSlider onChange={onChange} {...props} />;
};

export default Slider;
