import React, { FC, ReactNode } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

interface CustomButtonProps extends MuiButtonProps {
  borderRadius?: number;
  label?: string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  disabledBgColor?: string;
  disabledTextColor?: string;
  loading?: boolean;
  disabledHoverColor?: boolean;
}

const Button: FC<CustomButtonProps> = ({
  variant = "outlined",
  size = "large",
  fullWidth,
  borderRadius = 2,
  label,
  labelSx,
  children,
  sx,
  disabledBgColor,
  disabledTextColor,
  loading,
  disabledHoverColor,
  ...props
}) => {
  return (
    <MuiButton
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      sx={{
        textTransform: "initial",
        minWidth: "unset",
        borderRadius: borderRadius,
        "&.Mui-disabled": {
          backgroundColor: disabledBgColor || "primary.200",
          color: disabledTextColor || "white",
        },
        "&.MuiButtonBase-root:hover": {
          bgcolor: disabledHoverColor ? "transparent" : undefined,
        },
        ...sx,
      }}
      disabled={loading || props.disabled}
      {...props}
    >
      {loading ? (
        <CircularProgress size={24} sx={{ color: "inherit" }} />
      ) : (
        <>
          {children}
          {label && (
            <Typography variant="h6" sx={{ textTransform: "none", ...labelSx }}>
              {label}
            </Typography>
          )}
        </>
      )}
    </MuiButton>
  );
};

export default Button;
