import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

export const getNotificationById = createAsyncThunk(
  "clientNotification/getById",
  async (notificationId: string, { rejectWithValue }) => {
    try {
      const response = await request.get(`/notifications/${notificationId}`); // API call to get a goal
      return response.data; // Return the data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle the error
      return rejectWithValue(errorMessage); // Reject with an error message
    }
  }
);
export const getAllNotifications = createAsyncThunk(
  "clientNotification/getAll",
  async (
    {
      dateFilter,
      limit = 10,
      offset = 0,
      read,
      resolved,
      saved,
    }: {
      dateFilter?: string;
      limit?: number;
      offset?: number;
      read?: boolean;
      resolved?: boolean;
      saved?: boolean;
      remove?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get("/notifications/all", {
        params: {
          dateFilter, // 'day', 'week', 'month'
          limit, // Max number of notifications to return
          offset, // Number of notifications to skip (for pagination)
          read, // 'true' or 'false'
          resolved, // 'true' or 'false'
          saved, // 'true' or 'false'
        },
      });
      return response.data; // Return data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle any errors
      return rejectWithValue(errorMessage); // Reject with error message
    }
  }
);

export const getNotificationSettings = createAsyncThunk(
  "clientNotification/getSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get("/notifications/settings"); // API call to get notification settings
      return response.data; // Return the settings data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle any errors
      return rejectWithValue(errorMessage); // Reject with error message
    }
  }
);
export const updateNotificationSettings = createAsyncThunk(
  "clientNotification/updateSettings",
  async (
    { type, settingsData }: { type: string; settingsData: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.patch(
        `/notifications/settings/${type}`,
        settingsData
      ); // API call to update notification settings
      return response.data; // Return the updated settings data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle any errors
      return rejectWithValue(errorMessage); // Reject with error message
    }
  }
);

export const updateNotificationStatus = createAsyncThunk(
  "commanderNotification/updateStatus",
  async (
    {
      notificationId,
      statusData, // statusData will now be an object containing { read, saved, resolved }
      remove = false,
    }: {
      notificationId: string;
      statusData: { read?: boolean; saved?: boolean; resolved?: boolean };
      remove?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.patch(
        `/notifications/${notificationId}/status`, // API call to update the notification status
        statusData
      );
      return {
        data: response.data,
        remove,
      }; // Return the updated notification data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle any errors
      return rejectWithValue(errorMessage); // Reject with an error message
    }
  }
);
