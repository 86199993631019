import { Box, FormControl, Grid, Typography } from "@mui/material";
import React from "react";
import Input from "components/custom/Input";
import { Controller, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/custom/Button";
import { connectAccountByCardSchema } from "validation/authValidation";
import formatCardNumber from "utils/formatCardNumber";
import formatExpirationDate from "utils/formatExpirationDate";

interface ConnectAccountByCardInputs {
  accountHolderName: string;
  accountNumber: string;
  expirationDate: string;
  cvv: string;
}

const ConnectAccountByCardModal = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ConnectAccountByCardInputs>({
    resolver: yupResolver(
      connectAccountByCardSchema
    ) as Resolver<ConnectAccountByCardInputs>, // Use your validation schema
    mode: "onSubmit",
  });

  const onSubmit = async (data: ConnectAccountByCardInputs) => {
    // Handle form submission
  };

  return (
    <Box p={6} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography variant="h4" fontWeight={600} color={"primary.900"}>
        Connect account by card
      </Typography>
      <Box width={"100%"} mt={6}>
        <FormControl
          fullWidth
          sx={{ gap: 4 }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="accountHolderName"
            control={control}
            render={({ field }) => (
              <Input
                required
                label="Account holder’s name"
                placeholder="Amanta Scott"
                error={!!errors.accountHolderName}
                helperText={errors.accountHolderName?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="accountNumber"
            control={control}
            render={({ field }) => (
              <Input
                // type="number"
                required
                label="Account number"
                placeholder="4441 5656 7653 6524"
                error={!!errors.accountNumber}
                helperText={errors.accountNumber?.message}
                {...field}
                onChange={(e) => {
                  const formatted = formatCardNumber(e.target.value);
                  field.onChange(formatted);
                }}
                inputProps={{ maxLength: 19 }}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="expirationDate"
                control={control}
                render={({ field }) => (
                  <Input
                    required
                    label="Expired date"
                    placeholder="12/28"
                    error={!!errors.expirationDate}
                    helperText={errors.expirationDate?.message}
                    {...field}
                    onChange={(e) => {
                      const formatted = formatExpirationDate(e.target.value);
                      field.onChange(formatted);
                    }}
                    inputProps={{ maxLength: 5 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cvv"
                control={control}
                render={({ field }) => (
                  <Input
                    required
                    label="CVV"
                    placeholder="333"
                    error={!!errors.cvv}
                    helperText={errors.cvv?.message}
                    {...field}
                    inputProps={{
                      max: 3,
                    }}
                    onChange={(e) => {
                      const formatted = e.target.value
                        ?.replace(/\D/g, "")
                        ?.slice(0, 3);
                      field.onChange(formatted);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormControl>

        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          disabled={!isDirty || !isValid}
          variant="contained"
          fullWidth
          label="Connect Account"
          sx={{ mt: 6, height: 48 }}
        />
      </Box>
    </Box>
  );
};

export default ConnectAccountByCardModal;
