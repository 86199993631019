import {
  BagIcon,
  BulbIcon,
  ContactIcon,
  EducationIcon,
  EnvelopeIcon,
  HeadSetIcon,
  MenuCalendarIcon,
  MenuNotificationIcon,
  StarIcon,
  UserIcon,
  UserPlusIcon,
  WalletIcon,
} from "../assets/icons/index";

const adminMenus = [
  { icon: UserIcon, text: "Clients", href: "/clients" },
  { icon: UserIcon, text: "Commanders", href: "/commanders" },
  { icon: UserIcon, text: "Units", href: "/units" },
];

const clientMenus = [
  {
    icon: WalletIcon,
    text: "Financial Statement",
    href: "/financial-statement",
  },
  { icon: UserPlusIcon, text: "Family member", href: "/family-member" },
  { icon: EducationIcon, text: "Education", href: "/education" },
  { icon: EnvelopeIcon, text: "Chat", href: "/chat" },
  { icon: StarIcon, text: "Goals", href: "/goals" },
  { icon: HeadSetIcon, text: "Counselors", href: "/counselors" },
  { icon: MenuCalendarIcon, text: "My Appointments", href: "/appointments" },
  { icon: BulbIcon, text: "Calculator", href: "/calculator" },
  { icon: MenuNotificationIcon, text: "Notification", href: "/notifications" },
];
const commmanderMenus = [
  {
    icon: BagIcon,
    text: "Unit Score",
    href: "/unit-score",
  },
  { icon: UserIcon, text: "Unit Rosters", href: "/unit-rosters" },
  {
    icon: EducationIcon,
    text: "Training & Education",
    href: "/training-education",
  },
  {
    icon: ContactIcon,
    text: "Inbound/Outbound Personnel",
    href: "/inbound-Outbound-Personnel",
  },
  {
    icon: WalletIcon,
    text: "Unit Financial Trainers",
    href: "/unit-financial-trainers",
  },
];

export { clientMenus, commmanderMenus, adminMenus };
