import React from "react";
import {
  Switch,
  FormControlLabel,
  SwitchProps,
  Box,
  Typography,
  styled,
} from "@mui/material";

// Custom styled switch to mimic iOS-like switches
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#667085",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#667085",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface CustomSwitchProps extends SwitchProps {
  label?: string;
  description?: string;
  labelPlacement?: "end" | "start" | "top" | "bottom";
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  label = "",
  description = "",
  labelPlacement = "end",
  ...props // This will include all SwitchProps
}) => {
  return (
    <Box>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ mr: 2, mt: 1 }}
            {...props} // Spread props here to pass all SwitchProps
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {description && (
        <Typography
          variant="h6"
          fontWeight={400}
          color={"primary.500"}
          ml={10.5}
          mt={-1}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default CustomSwitch;
