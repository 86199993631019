import * as React from "react";
const InfoCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#0C425F"
      d="M12.5 11.588a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-1.5 0v-5a.75.75 0 0 1 .75-.75ZM12.5 9.838a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
    <path
      fill="#0C425F"
      fillRule="evenodd"
      d="M3.75 12.838a8.75 8.75 0 1 1 17.5 0 8.75 8.75 0 0 1-17.5 0Zm8.75-7.25a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default InfoCircleIcon;
