import * as React from "react";
const OpenBookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#ECFDF3" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#6CE9A6" rx={5.5} />
    <path
      stroke="#027A48"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.003 12.667A2.667 2.667 0 0 0 13.336 10h-4v10h4.667a2 2 0 0 1 2 2m0-9.333V22m0-9.333A2.667 2.667 0 0 1 18.669 10h4v10h-4.666a2 2 0 0 0-2 2"
    />
  </svg>
);
export default OpenBookIcon;
