import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

export const checkHealth = createAsyncThunk(
  "goals/create",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get("/health");
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
