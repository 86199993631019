import { Box, Paper, Typography } from "@mui/material";
import { CheckIcon, InfoCircleIcon } from "assets/icons";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Button } from "components";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { StatusScoreCardProps } from "types/types";
import getFinancialStatusColorWithHex from "utils/getFinancialStatusColorWithHex";

ChartJS.register(ArcElement, Tooltip, Legend);

const ScoreCard = ({
  data,
  onDetailsClick,
  value,
}: {
  data: StatusScoreCardProps;
  onDetailsClick?: () => void;
  value?: number;
}) => {
  const { title, status, percentage, details, path } = data;
  const navigate = useNavigate();

  const chartData = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [getFinancialStatusColorWithHex(status), "#F2F4F7"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    rotation: 270, // Start angle for the chart (270 degrees)
    circumference: 180, // Half doughnut (180 degrees)
    cutout: "70%",

    plugins: {
      tooltip: { enabled: false }, // Disable tooltip
      legend: { display: false }, // Disable legend
    },
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        textAlign: "center",
        boxShadow: 0,
        border: "1px solid #EAECF0",
      }}
    >
      <Box width={300} mx={"auto"}>
        <Typography variant="h5" fontWeight={600} color={"primary.main"}>
          {title}
        </Typography>
        <Box position="relative" display="inline-flex" mt={-13}>
          <Doughnut data={chartData} options={options} />
          <Box
            top={80}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6" fontWeight={400} color="primary.800">
              {status}
            </Typography>
            <Typography
              fontSize={"3rem"}
              fontWeight={600}
              lineHeight={"3.75rem"}
              color="primary.900"
            >
              {value || `${percentage}%`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "secondary.300",
            borderRadius: 2,
            p: 2,
            display: "flex",
            gap: 2,
            mb: 7.5,
            mt: -12,
          }}
        >
          {status === "Good" ? <CheckIcon /> : <InfoCircleIcon />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              maxWidth: "85%",
            }}
          >
            <Typography variant="body1" color={"primary.main"}>
              {status}
            </Typography>
            <Typography variant="h6" color={"primary.800"} textAlign={"left"}>
              {details}
            </Typography>
          </Box>
        </Box>
        <Button
          onClick={() => {
            onDetailsClick ? onDetailsClick() : navigate(`/unit-score/${path}`);
          }}
          fullWidth
          variant="outlined"
          label="Details"
        />
      </Box>
    </Paper>
  );
};

export default ScoreCard;
