import { Box, Grid, Typography } from "@mui/material";
import { ShareIcon } from "assets/icons";
import {
  Button,
  CalculationBreakdown,
  DashboardHeader,
  TaskList,
} from "components";
import React from "react";
import { useAppSelector } from "store";
import { selectFinancialReport } from "store/reducers/UnitReducer";

const AverageFinancialReadinessScore = () => {
  const financialReport = useAppSelector(selectFinancialReport);

  return (
    <Box width={"100%"}>
      <DashboardHeader page="Average Financial Readiness" goBack>
        <Button sx={{ gap: 2, borderColor: "secondary.main", height: 40 }}>
          <ShareIcon />
          <Typography>Share</Typography>
        </Button>
      </DashboardHeader>
      <Grid container px={10} py={6} columnSpacing={6}>
        <Grid item xs={8}>
          <CalculationBreakdown
            average
            status="Ready"
            value={financialReport?.averageFinancialReadinessScore || 0}
          />
          {/* <RelatedInformation data={mockRelatedData?.slice(0,2)}/> */}
        </Grid>
        <Grid item xs={4}>
          <TaskList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AverageFinancialReadinessScore;
