import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { useAppDispatch, useAppSelector } from "store";
import { otpResend, otpVerify } from "store/actions/AuthAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { showToast } from "utils/showToast";
import { useTimer } from "../../hooks/useTimer";
import Button from "../custom/Button";

interface IOtp {
  source: string;
  onNext: () => void;
}
const Otp = ({ source, onNext }: IOtp) => {
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState("");
  const loading = useAppSelector(getIsLoading);
  const [error, setError] = useState<null | string>(null);
  const { startTimer, timeLeft } = useTimer(30);
  const handleClick = async (otp?: string) => {
    const response = (await dispatch(
      otpVerify({
        email: source,
        otp_code: otp,
      })
    )) as unknown as {
      error: boolean;
      payload: string;
    };

    if (!response.error) {
      onNext();
    } else {
      setError(response.payload);
    }
    setOtp("");
  };
  const reSendCode = async () => {
    setError(null);
    if (timeLeft !== 0) return false;
    const response = (await dispatch(
      otpResend({
        email: source,
      })
    )) as unknown as {
      error: boolean;
      payload: string;
    };
    if (!response.error) {
      startTimer();
      showToast("Code has been sent", "success");
    } else {
      setError(response.payload);
    }
  };
  return (
    <Box>
      <Typography variant="h6" color={"primary.600"}>
        Enter the security code we sent on
      </Typography>
      <Typography
        mt={1}
        mb={4}
        variant="h6"
        color={"primary.600"}
        fontWeight={600}
      >
        {source}
      </Typography>
      <OTPInput
        inputStyle={{
          fontFamily: "Poppins",
          width: 82,
          height: 64,
          borderRadius: 8,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#D0D5DD",
          fontSize: 48,
          lineHeight: 60,
          fontWeight: 500,
          color: "#6D8E9F",
        }}
        containerStyle={{ gap: 20 }}
        placeholder="0000"
        value={otp}
        onChange={(otp: string) => {
          setOtp(otp);
          if (otp.length === 4) {
            handleClick(otp);
          }
        }}
        numInputs={4}
        renderInput={(props) => (
          <input {...props} className={props.className || "otp"} />
        )}
      />
      {error && (
        <Typography mt={1} color={"error.main"}>
          {error}
        </Typography>
      )}
      <Box display={"flex"} mt={4}>
        <Typography
          sx={{ maxWidth: 35, width: 35 }}
          variant="h6"
          mr={1}
          color={"primary.600"}
          fontWeight={400}
        >
          00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
        </Typography>
        <Button
          onClick={reSendCode}
          variant="text"
          label="Resend the Сode"
          sx={{ height: 20, width: 130, padding: 0 }}
          labelSx={{ fontWeight: 600 }}
          // disableRipple //!if you want to disable ripple when click you can add disableRipple props
        />
      </Box>
      <Button
        // onClick={}
        type="submit"
        onClick={() => handleClick()}
        disabled={otp.length !== 4}
        variant="contained"
        fullWidth
        size="large"
        label="Continue"
        sx={{ mt: 10, height: 48 }}
        loading={loading}
      />
    </Box>
  );
};

export default Otp;
