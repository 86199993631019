import React from "react";
import { Box, Typography, LinearProgress, Paper, Divider } from "@mui/material";
import { DashboardHeader } from "components";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getUserById } from "store/actions/AdminAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import dayjs from "dayjs";

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const loading = useAppSelector(getIsLoading);

  const getUser = useCallback(async () => {
    const response = await dispatch(getUserById(id));
    if (response?.meta?.requestStatus === "fulfilled") {
      setData(response?.payload);
    }
  }, [dispatch, id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Box width="100%" p={3} bgcolor="#F4F7FC">
      {loading ? (
        <LinearProgress sx={{ backgroundColor: "#ddd", height: 6 }} />
      ) : (
        <>
          <DashboardHeader goBack page={data?.userName} />

          <Paper
            elevation={4}
            sx={{
              p: 4,
              mt: 3,
              bgcolor: "#FFFFFF",
              borderRadius: 2,
              boxShadow: 6,
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="#2F3A56" mb={2}>
              User Details
            </Typography>
            <Divider sx={{ bgcolor: "#E1E4E8" }} />

            <Box
              mt={4}
              sx={{ display: "grid", gap: 3, gridTemplateColumns: "1fr 1fr" }}
            >
              <Typography fontWeight="500" color="#616C79">
                <strong>Username:</strong> {data?.userName}
              </Typography>
              <Typography fontWeight="500" color="#616C79">
                <strong>Email:</strong> {data?.email}
              </Typography>
              <Typography fontWeight="500" color="#616C79">
                <strong>Phone Number:</strong> {data?.phone_number}
              </Typography>
              <Typography fontWeight="500" color="#616C79">
                <strong>First Name:</strong> {data?.firstName}
              </Typography>
              <Typography fontWeight="500" color="#616C79">
                <strong>Last Name:</strong> {data?.lastName}
              </Typography>
              <Typography fontWeight="500" color="#616C79">
                <strong>Role:</strong> {data?.role}
              </Typography>
              <Typography fontWeight="500" color="#616C79">
                <strong>Registration Date: </strong>
                {dayjs(new Date(data?.createdAt))?.format(
                  "DD MMM YYYY, HH:mm A"
                )}
              </Typography>
            </Box>
          </Paper>

          {data?.address && (
            <Paper
              elevation={4}
              sx={{
                p: 4,
                mt: 3,
                bgcolor: "#FFFFFF",
                borderRadius: 2,
                boxShadow: 6,
              }}
            >
              <Box mt={4}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color="#2F3A56"
                  mb={2}
                >
                  Address Details
                </Typography>
                <Divider sx={{ bgcolor: "#E1E4E8" }} />
                <Box
                  mt={2}
                  sx={{
                    display: "grid",
                    gap: 3,
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Typography fontWeight="500" color="#616C79">
                    <strong>Address Line 1:</strong> {data?.address?.addr1}
                  </Typography>
                  <Typography fontWeight="500" color="#616C79">
                    <strong>Address Line 2:</strong> {data?.address?.addr2}
                  </Typography>
                  <Typography fontWeight="500" color="#616C79">
                    <strong>City:</strong> {data?.address?.city}
                  </Typography>
                  <Typography fontWeight="500" color="#616C79">
                    <strong>State/Province:</strong> {data?.address?.stateProv}
                  </Typography>
                  <Typography fontWeight="500" color="#616C79">
                    <strong>Postal Code:</strong> {data?.address?.postalCode}
                  </Typography>
                  <Typography fontWeight="500" color="#616C79">
                    <strong>Country:</strong> {data?.address?.country}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          )}
        </>
      )}
    </Box>
  );
};

export default UserDetails;
