import * as React from "react";
const ArrowDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#F04438"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.997 4.167v11.666m0 0L15.831 10m-5.834 5.833L4.164 10"
    />
  </svg>
);
export default ArrowDownIcon;
