import {
  Box,
  Card,
  CardMedia,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { TrashIcon } from "assets/icons";
import {
  Button,
  CounselorSupport,
  CreateGoalModal,
  DashboardHeader,
  DeleteGoalModal,
  FinancialCard,
} from "components";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { deleteGoal, getGoal } from "store/actions/GoalAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import colors from "theme/colors";
import typography from "theme/typography";
import checkGoalComplete from "utils/checkGoalComplete";
import { showToast } from "utils/showToast";

const GoalDetails = () => {
  const {
    isVisible: isDeleteModalVisible,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useModal();
  const {
    isVisible: isEditModalVisible,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen,
  } = useModal();

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const loading = useAppSelector(getIsLoading);
  const location = useLocation(); // Get the location object
  const accountBalance = location.state?.accountBalance || 0; // Default to 0 if not available
  const estimatedMonthlyIncome = location.state?.estimatedMonthlyIncome || 0;

  const getGoalById = useCallback(async () => {
    const response = await dispatch(getGoal(id));
    if (response?.meta?.requestStatus === "fulfilled") {
      setData(response?.payload);
    }
  }, [dispatch, id]);

  useEffect(() => {
    getGoalById();
  }, [getGoalById]);
  if (loading) return <LinearProgress />;

  return (
    <Box width={"100%"}>
      <DashboardHeader goBack page={data?.name}>
        <Button onClick={onDeleteModalOpen}>
          <TrashIcon />
          <Typography ml={2} textTransform={"initial"}>
            Delete goal
          </Typography>
        </Button>
        <Button
          sx={{ height: 40, ml: 4 }}
          variant="contained"
          label="Edit goal"
          onClick={onEditModalOpen}
        />
      </DashboardHeader>

      <Box px={10} py={6}>
        <Grid container spacing={4}>
          <Grid item xs={7}>
            <Paper
              elevation={0}
              sx={{ padding: 4, border: "1px solid #EAECF0" }}
            >
              <Typography variant="h5" color={"primary.700"} fontWeight={600}>
                Target amount:
              </Typography>
              <Typography
                variant="h3"
                fontWeight={600}
                color={"primary.900"}
                mt={2}
              >
                ${data?.goalAmount?.toLocaleString()}
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={4}
              >
                Current amount
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h5" color={"primary.700"} fontWeight={600}>
                  ${accountBalance?.toLocaleString()}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.500"}
                  sx={{ marginTop: 1 }}
                >
                  {checkGoalComplete(accountBalance, data?.goalAmount)}%
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={checkGoalComplete(accountBalance, data?.goalAmount)}
                sx={{
                  height: 8,
                  borderRadius: 8,
                  marginTop: 2,
                  background: colors.primary["50"],
                }}
              />
            </Paper>

            <Paper
              elevation={0}
              sx={{ padding: 4, mt: 6, border: "1px solid #EAECF0" }}
            >
              <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                Description:{" "}
                <Typography
                  display={"inline-block"}
                  fontWeight={500}
                  color={"primary.700"}
                  ml={1}
                >
                  {data?.description}
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={4}
              >
                Date to complete:{" "}
                <Typography
                  display={"inline-block"}
                  fontWeight={500}
                  color={"primary.700"}
                  ml={1}
                >
                  {dayjs(data?.dateToCompete)?.format("DD MMMM YYYY")}
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={4}
              >
                Photo:
              </Typography>
              <Card sx={{ marginTop: 2 }}>
                <CardMedia
                  component="img"
                  height="230"
                  image={data?.imageSignedUrl}
                  alt="Car"
                />
              </Card>
            </Paper>
            {/* Financial Overview */}
            <Grid container spacing={4} sx={{ marginTop: 4 }}>
              <Grid item xs={4}>
                <FinancialCard
                  title="Account balance"
                  value={
                    accountBalance
                      ? `$${accountBalance?.toLocaleString()}`
                      : "$0"
                  }
                  change="40%"
                  changeType="increase"
                  period="month"
                  valueSx={{ ...typography.h4, marginBottom: 2 }}
                />
              </Grid>
              <Grid item xs={4}>
                <FinancialCard
                  title="Est monthly income"
                  value={`$${estimatedMonthlyIncome?.toLocaleString()}`}
                  change="10%"
                  changeType="decrease"
                  period="month"
                  valueSx={{ ...typography.h4, marginBottom: 2 }}
                />
              </Grid>
              <Grid item xs={4}>
                <FinancialCard
                  title="Goal balance"
                  value={
                    accountBalance
                      ? `$${accountBalance?.toLocaleString()}`
                      : "$0"
                  }
                  change="40%"
                  changeType="increase"
                  period="month"
                  valueSx={{ ...typography.h4, marginBottom: 2 }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={5}>
            <CounselorSupport />
          </Grid>
        </Grid>
      </Box>
      <CreateGoalModal
        open={isEditModalVisible as boolean}
        handleClose={onEditModalClose}
        editId={id}
        editData={data}
      />
      <DeleteGoalModal
        handleCloseModal={onDeleteModalClose}
        open={isDeleteModalVisible}
        handleDelete={async () => {
          const response = await dispatch(deleteGoal(id));
          if (response?.meta?.requestStatus === "fulfilled") {
            showToast("Goal deleted successfully", "success");
            onDeleteModalClose();
            navigate("/goals");
          }
        }}
      />
    </Box>
  );
};

export default GoalDetails;
