import { useState } from "react";

const useModal = () => {
  const [isVisible, setIsVisible] = useState<boolean | number>(false);

  const onOpen = () => {
    setIsVisible(true);
  };
  const onOpenWithValue = (value: number) => {
    setIsVisible(value);
  };

  const onClose = () => {
    setIsVisible(false);
  };

  return {
    isVisible,
    onOpen,
    onOpenWithValue,
    onClose,
  };
};

export default useModal;
