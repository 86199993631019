import { FormControl } from "@mui/material";
import { Input } from "components";
import CustomDatePicker from "components/custom/CustomDatePicker";
import dayjs from "dayjs";
import React from "react";
import { Controller } from "react-hook-form";

const GoalFormStepOne = ({ control, errors, date, handleDateChange }) => (
  <FormControl fullWidth sx={{ gap: 6, mb: 1 }}>
    <Controller
      name="name"
      control={control}
      render={({ field }) => (
        <Input
          required
          label="Name"
          placeholder="Enter goal name"
          // error={!!errors.name}
          // helperText={errors.name?.message}
          fullWidth
          {...field}
        />
      )}
    />
    <Controller
      name="description"
      control={control}
      render={({ field }) => (
        <Input
          required
          label="Description"
          placeholder="Enter goal description"
          // error={!!errors.description}
          // helperText={errors.description?.message}
          fullWidth
          {...field}
        />
      )}
    />

    <CustomDatePicker
      selectedDate={dayjs(date)}
      onChange={handleDateChange}
      label={"Date to compete"}
      placeholder="Select a date"
    />
  </FormControl>
);

export default GoalFormStepOne;
