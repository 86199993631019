import { TFinancialStatus } from "types/types";

const getFinancialStatusColor = (status: TFinancialStatus) => {
  switch (status) {
    case "Ready":
      return "success.400";
    case "Marginally Ready":
      return "warning.400";
    case "Not Ready":
      return "error.400";
    default:
      return "grey";
  }
};

export default getFinancialStatusColor;
