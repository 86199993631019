import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { setRole } from "store/reducers/AuthReducer";
import { Button } from "..";

const ChooseRole = ({ nextStep }) => {
  const { selectedRole } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const roles = isLoginPage
    ? ["Client", "Commander", "Super Admin", "Counselor", "Family Member"]
    : ["Client", "Commander", "Counselor"];

  return (
    <>
      <Typography color={"primary.700"} variant="body1" mt={6}>
        Choose Role
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 6,
          mt: 8,
        }}
        width={"100%"}
        maxWidth={386}
      >
        {roles?.map((role, index) => (
          <Button
            disabled={role === "Counselor"}
            key={index}
            variant={selectedRole === role ? "contained" : "outlined"}
            onClick={() => {
              dispatch(setRole(role));
              nextStep();
            }}
            size="large"
            fullWidth
            label={role}
            sx={{
              height: 52,
            }}
            labelSx={{ fontWeight: 600 }}
            disabledBgColor="primary.100"
            disabledTextColor="#D0D5DD"
          />
        ))}
      </Box>
    </>
  );
};

export default ChooseRole;
