import { Box, Grid, Typography } from "@mui/material";
import { Button, Input, Slider } from "components";
import CustomDatePicker from "components/custom/CustomDatePicker";
import dayjs from "dayjs";
import React, { useState } from "react";

const CalculatorForm = ({ onCalculate }) => {
  const [calculatorValues, setCalculatorValues] = useState({
    autoPrice: 50000,
    loanTerm: 60,
    interestRate: 4.55,
    cashIncentives: 200,
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleSliderChange = (key) => (event, newValue) => {
    setCalculatorValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };

  const handleInputChange = (key) => (event) => {
    setCalculatorValues((prevValues) => ({
      ...prevValues,
      [key]: event.target.value === "" ? "" : Number(event.target.value),
    }));
  };

  const handleDateChange = (key) => (newDate) => {
    setCalculatorValues((prevValues) => ({
      ...prevValues,
      [key]: newDate,
    }));
  };

  const calculateResults = () => {
    const { autoPrice, loanTerm, interestRate, cashIncentives } =
      calculatorValues;
    const saleTaxRate = 0.063;
    const upfrontPaymentPercentage = 0.15;

    // Calculate Sales Tax
    const saleTax = autoPrice * saleTaxRate;

    // Calculate Upfront Payment (15% of Auto Price)
    const upfrontPayment = autoPrice * upfrontPaymentPercentage;

    // Calculate Loan Amount (Auto Price - Upfront Payment + Sales Tax - Cash Incentives)
    const loanAmount = autoPrice - upfrontPayment + saleTax - cashIncentives;

    // Monthly Interest Rate
    const monthlyInterestRate = interestRate / 12 / 100;

    // Monthly Payment using the formula P = [r * PV] / [1 - (1 + r)^(-n)]
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTerm));

    // Total Payments over the term
    const totalPayments = monthlyPayment * loanTerm;

    // Total Interest Paid
    const totalInterest = totalPayments - loanAmount;

    // Calculate interest and principal percentages
    const interestPercentage = (totalInterest / totalPayments) * 100;
    const principalPercentage = 100 - interestPercentage;

    // Pass the results back to the parent component or display them
    onCalculate({
      totalLoanAmount: loanAmount,
      saleTax,
      upfrontPayment,
      totalPayments,
      totalInterest,
      loanTerm,
      interestPercentage: interestPercentage.toFixed(2),
      principalPercentage: principalPercentage.toFixed(2),
    });
  };

  return (
    <Box
      p={4}
      bgcolor={"white"}
      border={"1px solid #EAECF0"}
      borderRadius={2}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Typography variant="h5" fontWeight={600} color={"primary.700"} mb={2}>
          Calculator
        </Typography>
        {/* Auto Price */}
        <Typography variant="h6" color={"primary.700"} mt={6}>
          Auto Price
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={6}
        >
          <Slider
            value={calculatorValues.autoPrice}
            onChange={handleSliderChange("autoPrice")}
            min={0}
            max={100000}
          />
          <Input
            value={calculatorValues.autoPrice}
            onChange={handleInputChange("autoPrice")}
            InputProps={{ startAdornment: "$", readOnly: true }}
            sx={{
              minWidth: 144,
              maxWidth: 144,
              "& .MuiInputBase-input": {
                pl: "4px!important",
              },
            }}
          />
        </Box>

        {/* Loan Term */}
        <Typography variant="h6" color={"primary.700"} mt={6}>
          Loan Term
        </Typography>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={6}
        >
          <Slider
            value={calculatorValues.loanTerm}
            onChange={handleSliderChange("loanTerm")}
            min={12}
            max={84}
          />
          <Input
            value={calculatorValues.loanTerm}
            onChange={handleInputChange("loanTerm")}
            InputProps={{
              endAdornment: (
                <Typography
                  variant="body1"
                  fontWeight={400}
                  color={"primary.400"}
                  ml={-30}
                  mb={0.5}
                >
                  months
                </Typography>
              ),

              readOnly: true,
            }}
            sx={{
              minWidth: 144,
              maxWidth: 144,

              "& .MuiInputBase-input": {
                pl: 3.5,
              },
            }}
          />
        </Box>

        {/* Interest Rate */}
        <Typography variant="h6" color={"primary.700"} mt={6}>
          Interest Rate
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={6}
        >
          <Slider
            value={calculatorValues.interestRate}
            onChange={handleSliderChange("interestRate")}
            min={0}
            max={25}
            step={0.01}
          />
          <Input
            value={calculatorValues.interestRate}
            onChange={handleInputChange("interestRate")}
            InputProps={{
              endAdornment: (
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.400"}
                  ml={-27}
                  mb={0.5}
                >
                  %
                </Typography>
              ),

              readOnly: true,
            }}
            sx={{
              minWidth: 144,
              maxWidth: 144,

              "& .MuiInputBase-input": {
                pl: 3.5,
              },
            }}
          />
        </Box>

        {/* Cash Incentives */}
        <Typography variant="h6" color={"primary.700"} mt={6}>
          Cash Incentives
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={6}
        >
          <Slider
            value={calculatorValues.cashIncentives}
            onChange={handleSliderChange("cashIncentives")}
            min={0}
            max={10000}
          />
          <Input
            value={calculatorValues.cashIncentives}
            onChange={handleInputChange("cashIncentives")}
            InputProps={{ startAdornment: "$", readOnly: true }}
            sx={{
              minWidth: 144,
              maxWidth: 144,
              "& .MuiInputBase-input": {
                pl: "4px!important",
              },
            }}
          />
        </Box>

        {/* Date Pickers */}
        <Grid container spacing={6} sx={{ mt: 6 }}>
          <Grid item xs={4}>
            <CustomDatePicker
              label="Start date"
              selectedDate={dayjs(calculatorValues.startDate)}
              onChange={handleDateChange("startDate")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomDatePicker
              label="End date"
              selectedDate={dayjs(calculatorValues.endDate)}
              onChange={handleDateChange("endDate")}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Calculate Button */}
      <Button
        label="Calculate"
        sx={{ width: 235, height: 40 }}
        variant="contained"
        color="primary"
        fullWidth
        onClick={calculateResults}
      />
    </Box>
  );
};

export default CalculatorForm;
