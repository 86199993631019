const categories = {
  needs: [
    "Grocery",
    "Housing & Utilities",
    "Education",
    "Transportation",
    "Business Services, Fees & Charges",
  ],
  wants: [
    "Personal Care, Health & Fitness",
    "Entertainment",
    "Food & Dining",
    "Shopping",
    "Others, Gifts & Donations",
    "Travel",
  ],
  savings: ["Savings", "Investments"],
};

const categorizeTransactions = (transactions) => {
  let needs = { transactionCount: 0, amount: 0 };
  let wants = { transactionCount: 0, amount: 0 };
  let savings = { transactionCount: 0, amount: 0 };
  let totalTransactionCount = transactions.length;

  transactions.forEach((transaction) => {
    const { amt } = transaction.amount;

    if (categories.needs.includes(transaction.category)) {
      needs.transactionCount += 1;
      needs.amount += +amt;
    } else if (categories.wants.includes(transaction.category)) {
      wants.transactionCount += 1;
      wants.amount += +amt;
    } else if (categories.savings.includes(transaction.category)) {
      savings.transactionCount += 1;
      savings.amount += amt;
    }
  });

  // Calculate percentages
  const needsPercentage = totalTransactionCount
    ? (needs.transactionCount / totalTransactionCount) * 100
    : 0;
  const wantsPercentage = totalTransactionCount
    ? (wants.transactionCount / totalTransactionCount) * 100
    : 0;
  const savingsPercentage = totalTransactionCount
    ? (savings.transactionCount / totalTransactionCount) * 100
    : 0;

  return {
    needs: {
      ...needs,
      percentage: needsPercentage.toFixed(2),
    },
    wants: {
      ...wants,
      percentage: wantsPercentage.toFixed(2),
    },
    savings: {
      ...savings,
      percentage: savingsPercentage.toFixed(2),
    },
  };
};
export default categorizeTransactions;
