import {
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useAppDispatch, useAppSelector } from "store";
import { getAccountDiagram } from "store/actions/FinancialStatementsAction";
import { selectDiagram } from "store/reducers/TransactionReducer";
import priceNumberToDollar from "utils/priceNumberToDollar";
import { showToast } from "utils/showToast";

const AssetInfo = ({ data }) => {
  const diagram = useAppSelector(selectDiagram);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  // Custom tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            minWidth: 146,
            minHeight: 55,
            bgcolor: "white",
            p: 2,
            borderRadius: 1.75,
            border: "1px solid #ccc",
          }}
        >
          <Typography variant="body2">
            {payload[0]?.payload?.date &&
              dayjs(payload[0]?.payload?.date)?.format("DD MMM YYYY")}
          </Typography>
          <Box display={"flex"} mt={1}>
            <Typography variant="h6" fontWeight="bold">
              ${payload[0]?.value?.toFixed(2)}
            </Typography>
            <Box
              sx={{
                px: 2,
                height: 22,
                border: "0.88px solid #C2D0D7",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: 3,
              }}
            >
              <Typography variant="body2" color="primary.main">
                {payload[0]?.payload?.percentage}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const getDiagramData = useCallback(async () => {
    if (!data?.accountId) {
      showToast("We don't have enought information for diagram", "error");
    } else {
      setLoading(true);
      await dispatch(getAccountDiagram(data?.accountId));
      setLoading(false);
    }
  }, [dispatch, data?.accountId]);

  useEffect(() => {
    getDiagramData();
  }, [getDiagramData]);

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Box
      sx={{
        backgroundColor: "#F7F8FC",
        borderRadius: 2,
        mt: 12,
        py: 4,
        flex: 1,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight={600}
          color={"primary.700"}
          textAlign={"center"}
        >
          Savings Account
        </Typography>
        <Divider sx={{ mt: 4 }} />

        <Box sx={{ px: 4 }}>
          <Typography
            variant="h6"
            fontWeight={400}
            color={"primary.500"}
            mt={4}
          >
            Type:
            <Typography
              display={"inline-block"}
              variant="h6"
              fontWeight={500}
              color={"primary.700"}
              pl={2}
            >
              {data?.type}
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            color={"primary.500"}
            mt={4}
          >
            Current balance:
            <Typography
              display={"inline-block"}
              variant="h6"
              fontWeight={500}
              color={"primary.700"}
              pl={2}
            >
              ${data?.currentBalance || data?.currentValue}
            </Typography>
          </Typography>
          {diagram && (
            <>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={4}
              >
                Purchase Cost:
                <Typography
                  display={"inline-block"}
                  variant="h6"
                  fontWeight={500}
                  color={"primary.700"}
                  pl={2}
                >
                  ${diagram?.purchaseCost}
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={4}
              >
                Value Increase:
                <Typography
                  display={"inline-block"}
                  variant="h6"
                  fontWeight={500}
                  color={"primary.700"}
                  pl={2}
                >
                  {priceNumberToDollar(diagram?.valueIncrease)}
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={4}
              >
                Percentage Change:
                <Typography
                  display={"inline-block"}
                  variant="h6"
                  fontWeight={500}
                  color={"primary.700"}
                  pl={2}
                >
                  {diagram?.percentageChange}%
                </Typography>
              </Typography>
            </>
          )}
        </Box>

        <Divider sx={{ my: 4 }} />
      </Box>

      {/* Graph Section */}
      {diagram?.transactions && (
        <Card sx={{ maxWidth: 400, m: 2 }}>
          <CardContent>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              ASSET GENERATED
            </Typography>
            <Typography variant="h4" component="div" fontWeight="bold" mb={4}>
              {(
                +Object?.values(diagram?.transactions || {})?.reduce(
                  (acc, amount) => +acc + +amount,
                  0
                ) as number
              )?.toFixed(2)}
            </Typography>
            <Box sx={{ height: 200, mt: 2 }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={Object.entries(diagram?.transactions).map(
                    ([date, uv]) => ({
                      uv: Math.round(uv as number),
                      date,
                      percentage:
                        -(
                          ((uv as number) /
                            (Object?.values(
                              diagram?.transactions || {}
                            )?.[0] as number) -
                            1) *
                          100
                        )?.toFixed(2) + "%",
                    })
                  )}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#48944B" stopOpacity={1} />
                      <stop
                        offset="95%"
                        stopColor="#48944B"
                        stopOpacity={0.2}
                      />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={(value) => `$${value}`} />
                  <Tooltip
                    content={
                      <CustomTooltip
                        active={true}
                        payload={null}
                        label={undefined}
                      />
                    }
                  />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default AssetInfo;
