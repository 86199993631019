import { Box, IconButton, Typography } from "@mui/material";
import { CheckIcon, CloseIcon } from "assets/icons";
import { Button } from "components";
import React from "react";
import toast from "react-hot-toast";
type ToastType = "success";

const toastStyles = {
  success: {
    background: "#487070",
    color: "white",
    minWidth: 431,
  },
};

export const showBottomToast = (
  message: string,
  type: ToastType,
  description?: string,
  onUndo?: () => void
  // required just one
) => {
  toast(
    <Box width={"100%"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Box display={"flex"} alignItems={"center"} gap={3}>
          <Box
            minWidth={36}
            height={36}
            borderRadius={36}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={"white"}
          >
            <CheckIcon />
          </Box>
          <Typography maxWidth={280} textAlign={"left"}>
            {message}
          </Typography>
        </Box>

        <IconButton
          onClick={() => {
            toast.dismiss();
          }}
        >
          <CloseIcon fill="white" />
        </IconButton>
      </Box>
      {onUndo && (
        <Box mt={2}>
          <Typography>{description}</Typography>
          <Button
            onClick={onUndo}
            variant="contained"
            label="Undo"
            //change hover color
            disabledHoverColor
            sx={{
              width: 74,
              height: 32,
              backgroundColor: "transparent",
              boxShadow: 0,
              border: "1px solid white",
              mt: 2,
            }}
          />
        </Box>
      )}
    </Box>,
    {
      style: {
        ...toastStyles[type],
      },
      duration: 3000,
      position: "bottom-center",
    }
  );
};
