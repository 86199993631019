import * as React from "react";
const MenuNotificationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m21.992 16.57-.262-.317c-1.179-1.426-1.892-2.286-1.892-6.322 0-2.09-.5-3.803-1.485-5.09-.726-.949-1.708-1.67-3.002-2.202a.162.162 0 0 1-.044-.036C14.84 1.044 13.567 0 12.13 0c-1.436 0-2.71 1.044-3.175 2.601a.166.166 0 0 1-.044.035C5.891 3.88 4.423 6.266 4.423 9.93c0 4.038-.712 4.898-1.891 6.322-.086.103-.174.208-.262.318a1.883 1.883 0 0 0-.25 2.015c.331.696 1.035 1.129 1.84 1.129h16.547c.8 0 1.5-.432 1.832-1.125a1.885 1.885 0 0 0-.247-2.018ZM12.13 24a4.29 4.29 0 0 0 3.774-2.257.214.214 0 0 0-.19-.314H8.548a.214.214 0 0 0-.19.314A4.288 4.288 0 0 0 12.13 24Z"
    />
  </svg>
);

export default MenuNotificationIcon;
