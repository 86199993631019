import * as React from "react";
const TrackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={47} height={47} x={0.5} y={0.5} fill="#F9F5FF" rx={5.5} />
    <rect width={47} height={47} x={0.5} y={0.5} stroke="#D6BBFB" rx={5.5} />
    <path
      stroke="#7F56D9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28 28V15H13v13h15Zm0 0h7v-5l-3-3h-4v8Zm-8 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm13 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
    />
  </svg>
);
export default TrackIcon;
