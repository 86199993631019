const getCategoryPercentages = (expenses, topN = 4) => {
  // Step 1: Calculate total amount spent per category

  const categoryTotals = expenses.reduce((acc, expense) => {
    const category = expense.category;
    const amount = Math.abs(expense.amount.amt); // Ensure positive amounts
    acc[category] = (acc[category] || 0) + amount;
    return acc;
  }, {});

  // Step 2: Convert totals to percentages and sort by highest percentages
  let categoryPercentages = Object.entries(categoryTotals)
    .map(([category, total]) => ({
      name: category,
      value: +(
        (+expenses.filter((expense) => expense.category === category).length /
          +expenses?.length) *
        100
      ).toFixed(2), // Convert to percentage, rounded to 2 decimals
      total: -(+total)?.toFixed(2), // Include the total amount spent for each category
      transactionCount: expenses.filter(
        (expense) => expense.category === category
      ).length,
    }))
    .sort((a, b) => {
      return +b.transactionCount - +a.transactionCount;
    });

  // Step : Select top N categories and categorize the rest as "Other"
  const topCategories = //chech if if name not includes other;
    categoryPercentages
      .filter((category) => !category.name.includes("Other"))
      .slice(0, topN);
  const topPercentage = topCategories.reduce(
    (acc, { value }) => acc + value,
    0
  );
  const totalPercentage = categoryPercentages.reduce(
    (acc, { value }) => acc + value,
    0
  );

  const otherPercentage = totalPercentage - topPercentage;

  // Add "Other" if there are additional categories
  if (otherPercentage > 0) {
    topCategories.push({
      name: "Other",
      value: +otherPercentage.toFixed(2),
      total: -1, // Include the total amount spent for "Other"
      transactionCount: 0,
      // transactionCount: expenses.filter(
      //   (expense) =>
      //     !topCategories.some((category) => category.name === expense.category)
      // ).length,
    });
  }

  return {
    topCategories,
    allCategories: categoryPercentages?.filter(
      (item) => item?.name !== "Others, Gifts & Donations"
    ),
  };
};

export default getCategoryPercentages;
