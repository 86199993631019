import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import Button from "./Button";

interface IPagination {
  count: number;
  page: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<IPagination> = ({ count, page, onPageChange }) => {
  const handlePrevious = useCallback(() => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  }, [page, onPageChange]);

  const handleNext = useCallback(() => {
    if (page < count) {
      onPageChange(page + 1);
    }
  }, [page, count, onPageChange]);

  return (
    <Box
      height={72}
      width="100%"
      px={6}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={"background.default"}
    >
      <Button
        variant="outlined"
        onClick={handlePrevious}
        label="Previous"
        disabled={page === 1}
        disabledBgColor="primary.100"
        disabledTextColor="primary.300"
      />
      <Typography variant="h6" color={"primary.700"}>
        Page {page} of {count}
      </Typography>
      <Button
        variant="outlined"
        onClick={handleNext}
        label="Next"
        disabled={page === count}
        disabledBgColor="primary.100"
        disabledTextColor="primary.300"
      />
    </Box>
  );
};

export default Pagination;
