import { Chip } from "@mui/material";
import React from "react";
import colors from "theme/colors";
import { TCounselorSupportStatus } from "types/types";

const SupportStatusChip = ({ status }: { status: TCounselorSupportStatus }) => {
  return (
    <Chip
      label={status}
      sx={{
        marginRight: 2,
        height: 18,
        bgcolor:
          status === "Applied" ? colors.success.main : colors.error["50"],
        color:
          status === "Applied" ? colors.success["700"] : colors.error["700"],
        fontWeight: 500,
      }}
    />
  );
};

export default SupportStatusChip;
