import { Box, LinearProgress, Typography } from "@mui/material";
import {
  AssetInfo,
  DashboardHeader,
  Input,
  LiabilityHeader,
  RightSidebar,
  Table,
} from "components";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getLiabilities } from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { selectLiabilities } from "store/reducers/TransactionReducer";
import { IColumn } from "types/types";

const Liabilities = () => {
  const { isVisible, onOpenWithValue, onClose } = useModal();
  const dispatch = useAppDispatch();
  const liabilities = useAppSelector(selectLiabilities);
  const loading = useAppSelector(getIsLoading);
  const [search, setSearch] = React.useState("");
  const [searchParams] = useSearchParams();

  // Read page and limit from URL, or default to page 1 and limit 10
  const page = +searchParams.get("page") || 1;
  const limit = +searchParams.get("limit") || 10;

  const columns: IColumn[] = [
    {
      id: "title",
      label: "Title",
      render: (row) => (
        <Typography variant="body1" fontWeight={600} color={"primary.700"}>
          {row.title}
        </Typography>
      ),
    },
    {
      id: "type",
      label: "Type",
      style: { fontWeight: 500, color: "primary.700" },
    },
    {
      id: "term",
      label: "Term",
    },
    {
      id: "lastUpdate",
      render: (row) => (
        <Typography variant="h6" fontWeight={400} color={"primary.500"}>{`${
          row?.lastUpdated || row?.nextPaymentDate
            ? dayjs(row?.lastUpdated || row?.nextPaymentDate).format(
                "DD MMM YYYY, HH:mm A"
              )
            : ""
        }`}</Typography>
      ),
      label: "Last Update",
    },
    {
      id: "percentage",
      label: "Percentage",
      render: (row) => (
        <Typography variant="body1" fontWeight={600} color={"primary.700"}>
          {row?.percentage && `+${row?.percentage}%`}
        </Typography>
      ),
    },
    {
      id: "currentBalance",
      label: "Current balance",
      render: (row) => (
        <Typography
          variant="body1"
          fontWeight={600}
          color={"primary.700"}
        >{`$ ${row.currentBalance || row?.currentValue}`}</Typography>
      ),
    },
  ];

  // const updateURLParams = useCallback(
  //   ({ newPage, newLimit }) => {
  //     const params = new URLSearchParams();
  //     params.set("page", newPage ? newPage.toString() : page.toString());
  //     params.set("limit", newLimit ? newLimit.toString() : limit.toString());
  //     setSearchParams(params);
  //   },
  //   [page, limit, setSearchParams]
  // );

  useEffect(() => {
    // Fetch liabilities data with pagination params on component mount and page/limit changes
    dispatch(getLiabilities({ page, limit }));
  }, [dispatch, page, limit]);

  return (
    <Box width={"100%"}>
      <DashboardHeader goBack page="Liabilities">
        <Box width={426}>
          <Input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            fullWidth
            placeholder="Search by Title"
            search
          />
        </Box>
      </DashboardHeader>
      <Box sx={{ px: 10, py: 6 }}>
        <LiabilityHeader data={liabilities} />
        {loading ? (
          <LinearProgress sx={{ marginTop: 2 }} />
        ) : (
          <Table
            total={liabilities?.totalItems}
            data={liabilities?.accounts?.filter((item) =>
              item?.title?.toLowerCase()?.includes(search?.toLowerCase())
            )}
            columns={columns}
            sx={{ mt: 6 }}
            onRowClick={(row) => onOpenWithValue(row?.accountId || row?.id)}
          />
        )}
        <RightSidebar
          open={isVisible as boolean}
          onClose={onClose}
          title="Short term liabilities"
          subTitle={(() => {
            const date =
              liabilities?.accounts?.find(
                (item) =>
                  item?.accountId === isVisible || item?.id === isVisible
              )?.lastUpdated ||
              liabilities?.accounts?.find(
                (item) =>
                  item?.accountId === isVisible || item?.id === isVisible
              )?.nextPaymentDate;

            return date ? dayjs(date).format("DD MMM YYYY, HH:mm A") : "";
          })()}
        >
          <AssetInfo
            data={liabilities?.accounts?.find(
              (item) => item?.accountId === isVisible || item?.id === isVisible
            )}
          />
        </RightSidebar>
      </Box>
    </Box>
  );
};

export default Liabilities;
