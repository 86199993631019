import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { DashboardHeader, DeleteAdminModal, Table } from "components";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { deleteUserById, getCommanders } from "store/actions/AdminAction";
import { selectCommanders } from "store/reducers/AdminReducer";
import { IColumn } from "types/types";
import { showToast } from "utils/showToast";

const Commanders = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const commandersData = useAppSelector(selectCommanders);
  const [searchParams] = useSearchParams();
  const {
    isVisible: isDeleteModalVisible,
    onClose: onDeleteModalClose,
    onOpenWithValue: onDeleteModalOpen,
  } = useModal();

  // Retrieve the current page and limit from URL parameters, default to page 1 and limit 10 if not set
  const page = Number(searchParams.get("page")) || 1;
  const limit = Number(searchParams.get("limit")) || 10;

  // Columns configuration for the Table component
  const columns: IColumn[] = [
    {
      id: "name",
      label: "First & Last Name",
      render: (row) => (
        <Typography>
          {row.firstName} {row.lastName}
        </Typography>
      ),
    },
    {
      id: "email",
      label: "Email",
      render: (row) => <Typography>{row.email}</Typography>,
    },
    {
      id: "role",
      label: "Role",
      render: (row) => (
        <Typography variant="h6" fontWeight={400} color={"primary.500"}>
          {row.role}
        </Typography>
      ),
    },
    {
      id: "createdAt",
      label: "Registration Date",
      render: (row) => (
        <Typography variant="h6" fontWeight={400} color={"primary.500"}>
          {dayjs(new Date(row.createdAt)).format("DD MMM YYYY, HH:mm A")}
        </Typography>
      ),
    },
    {
      id: "action",
      label: "Delete",
      handleClick(row) {
        onDeleteModalOpen(row?.id);
      },
      render: (row) => (
        <Button
          variant="contained"
          color="error"
          size="small"
          sx={{ width: 70, height: 30, minWidth: 0, ml: -3 }}
          onClick={() => onDeleteModalOpen(row?.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  // Fetch client data with pagination parameters
  const fetchCommandersData = useCallback(async () => {
    setLoading(true);
    await dispatch(getCommanders({ page, limit }));
    setLoading(false);
  }, [dispatch, page, limit]);

  useEffect(() => {
    fetchCommandersData();
  }, [fetchCommandersData]);

  // Handle page change by updating URL query parameters

  return (
    <Box>
      <DashboardHeader page="Commanders">
        {/* Add button, can be uncommented if needed */}
        {/* <Button
          sx={{ backgroundColor: "primary.main", mr: 7.5, px: 2.5, py: 0.75 }}
        >
          <SmallPlusIcon />
          <Typography variant="h6" fontWeight={600} ml={0.5}>
            Add
          </Typography>
        </Button> */}
      </DashboardHeader>

      <Box py={6} px={10}>
        {loading ? (
          <LinearProgress />
        ) : (
          <Table
            total={commandersData?.total}
            data={commandersData?.data}
            columns={columns}
            onRowClick={(row) => navigate(`/commanders/${row?.id}`)}
          />
        )}
      </Box>
      <DeleteAdminModal
        title="commander"
        handleCloseModal={onDeleteModalClose}
        open={isDeleteModalVisible}
        handleDelete={async () => {
          const response = await dispatch(
            deleteUserById(isDeleteModalVisible as number)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            showToast("Client deleted successfully", "success");
            onDeleteModalClose();
          }
        }}
      />
    </Box>
  );
};

export default Commanders;
