export type TFinancialStatus =
  | "Ready"
  | "Marginally Ready"
  | "Not Ready"
  | "Good";

const getStatusByScore = (
  score: number
): { status: TFinancialStatus; message: string } => {
  if (score < 580) {
    return {
      status: "Not Ready",
      message:
        "Consider focusing on your financial health to see improvements.",
    };
  } else if (score >= 580 && score < 670) {
    return {
      status: "Marginally Ready",
      message:
        "You're on the right path; however, there is room for improvement.",
    };
  } else if (score >= 670 && score < 740) {
    return {
      status: "Good",
      message: "Good job! Keep an eye on areas for further growth.",
    };
  } else {
    return {
      status: "Ready",
      message: "You're doing great! Keep up the excellent work.",
    };
  }
};

export default getStatusByScore;
