import * as React from "react";
const NotificationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0C425F"
      fillRule="evenodd"
      d="M13.001 3a1 1 0 0 0-2 0v.75h-.557A4.214 4.214 0 0 0 6.238 7.7l-.22 3.534a7.377 7.377 0 0 1-1.308 3.754 1.617 1.617 0 0 0 1.134 2.529l3.407.408V19a2.75 2.75 0 0 0 5.5 0v-1.075l3.407-.409a1.617 1.617 0 0 0 1.135-2.528 7.378 7.378 0 0 1-1.308-3.754l-.22-3.533a4.214 4.214 0 0 0-4.206-3.951H13V3Zm-2.557 2.25a2.714 2.714 0 0 0-2.709 2.544l-.22 3.534a8.877 8.877 0 0 1-1.574 4.516.117.117 0 0 0 .082.183l3.737.449c1.489.178 2.993.178 4.482 0l3.738-.449a.117.117 0 0 0 .082-.183 8.876 8.876 0 0 1-1.574-4.516l-.22-3.534a2.714 2.714 0 0 0-2.71-2.544h-3.114Zm1.557 15c-.69 0-1.25-.56-1.25-1.25v-.75h2.5V19c0 .69-.56 1.25-1.25 1.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default NotificationIcon;
