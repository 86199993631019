import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowRightIcon, QuestionIcon, SmallPlusIcon } from "assets/icons";
import React from "react";

interface IFinancialStatementTypes {
  name: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
}
const FinancialStatementTypes = ({
  name,
  Icon,
  disabled,
}: IFinancialStatementTypes) => {
  return (
    <Grid xs={3} item>
      <Button
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: 124,
          width: "100%",
          gap: 6,
          px: 4,
          backgroundColor: disabled ? "#F1F1F4" : "white",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "primary.300",
          borderRadius: 2,
        }}
      >
        <Box
          width={"100%"}
          height={40}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} alignItems={"center"} gap={3}>
            <Icon fill="#fff" />
            <Typography color={"#344054B2"} textTransform={"capitalize"}>
              {name}
            </Typography>
          </Box>
          {disabled && <QuestionIcon />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            // onClick={onAdd}
            sx={{
              border: 1,
              borderColor: "#ADB2BB",
              borderRadius: 2,
              mr: 7.5,
              px: 2.5,
              py: 0.75,
            }}
          >
            <SmallPlusIcon fill={"#ADB2BB"} />
            <Typography
              variant="h6"
              fontWeight={600}
              ml={0.5}
              color={"#ADB2BB"}
            >
              Add
            </Typography>
          </Button>
          <ArrowRightIcon />
        </Box>
      </Button>
    </Grid>
  );
};

export default FinancialStatementTypes;
