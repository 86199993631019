import * as React from "react";
const CopyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#0C425F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.164 12.5h-.833a1.666 1.666 0 0 1-1.667-1.667v-7.5a1.667 1.667 0 0 1 1.667-1.667h7.5a1.666 1.666 0 0 1 1.666 1.667v.833M9.164 7.499h7.5c.92 0 1.667.747 1.667 1.667v7.5c0 .92-.747 1.667-1.667 1.667h-7.5c-.92 0-1.667-.746-1.667-1.667v-7.5c0-.92.747-1.667 1.667-1.667Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CopyIcon;
