import { Avatar, Box, Divider, Input, Typography } from "@mui/material";
import { Button, CenterModal } from "components";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useRef, useState } from "react";
import generatePDF, { Options } from "react-to-pdf";
import typography from "theme/typography";
import priceNumberToDollar from "utils/priceNumberToDollar";
import GreenWall from "../../assets/images/green-wall.png";

const TransferInfo = ({ data }) => {
  const { onOpen, onClose, isVisible } = useModal();
  const [note, setNote] = useState("");
  const targetRef = useRef();
  const options: Options = {
    filename: `ProofOfPayment_${data?.transactionId || data?.title}.pdf`,
    page: {
      margin: 20,
      orientation: "portrait",
    },
  };

  const handleDownloadPDF = () => {
    onOpen();
    const timeout = setTimeout(() => {
      onClose();
      generatePDF(targetRef, options);
    }, 100);
    return () => clearTimeout(timeout);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bgcolor={"#F9FAFB"}
      mt={12}
      // p={4}
      border={"1px solid #EAECF0"}
      borderRadius={2}
    >
      <Avatar
        sx={{ width: 64, height: 64, mt: -12.5 }}
        alt="avatar"
        src={GreenWall}
      />
      <Typography variant="h5" fontWeight={600} color={"primary.700"} mt={2}>
        {priceNumberToDollar(data?.amount?.amt)}
      </Typography>
      <Typography variant="h6" fontWeight={400} color="primary.500" mt={2}>
        {data?.title}
      </Typography>
      <Box
        sx={{
          textAlign: "left",
          mt: 8,
          backgroundColor: "#F9FAFB",
          borderRadius: 1,
          width: "100%",
          border: "1px solid #EAECF0",
          borderLeft: 0,
          borderRight: 0,
          p: 4,
        }}
      >
        {/* Resource Data */}
        <Typography variant="h6" fontWeight={400} color={"primary.500"} my={4}>
          Resource data:{" "}
          <Typography fontWeight={500} display={"inline-block"} ml={2}>
            {data?.accountNumber}
          </Typography>
        </Typography>

        {/* Transaction ID */}
        <Typography variant="body2" color="textSecondary">
          Transaction ID:
          <Typography fontWeight={500} display={"inline-block"} ml={2}>
            {data?.transactionId}
          </Typography>
        </Typography>
      </Box>

      {/* Add a Note */}
      <Box px={4} mt={8}>
        <Typography variant="h6" fontWeight={600} color={"primary.500"} mb={2}>
          Add a note
        </Typography>
        <Input
          onChange={
            //set note
            (e) => {
              setNote(e.target.value);
            }
          }
          disableUnderline
          multiline
          placeholder="Type here to put a note"
          sx={{
            width: "100%",
            ...typography.h6,
            fontWeight: 400,
            color: "primary.400",
          }}
          inputProps={{
            style: { borderBottom: "1px solid #EAECF0", paddingBottom: 2 },
          }}
        />
        <Button
          fullWidth
          onClick={onOpen}
          variant="outlined"
          label="Open proof of transfer"
          sx={{ mt: 12 }}
        />
        <Button
          fullWidth
          onClick={handleDownloadPDF}
          variant="contained"
          label="Download proof of transfer"
          sx={{ my: 4 }}
        />
      </Box>
      <CenterModal
        open={isVisible}
        onClose={onClose}
        title={"Proof of transfer #847378"}
        sx={{ width: 480 }}
      >
        <Box>
          <Box ref={targetRef}>
            <Divider sx={{ my: 4 }} />
            {/* Date, Name, and Payment Info */}
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  variant="subtitle1"
                  color="primary.500"
                  fontWeight={400}
                >
                  {dayjs(data?.date).format("DD MMM YYYY")}
                </Typography>
                <Typography variant="h6" color="primary.700" mt={2}>
                  {data?.title}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                minWidth={150}
              >
                <Typography
                  variant="subtitle1"
                  color="primary.500"
                  fontWeight={400}
                >
                  Payment sent
                </Typography>
                <Typography variant="h6" color="primary.700" mt={2}>
                  {priceNumberToDollar(data?.amount?.amt)}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Paid with and Note */}

            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  variant="subtitle1"
                  color="primary.500"
                  fontWeight={400}
                >
                  Paid with
                </Typography>
                <Typography variant="h6" color="primary.700" mt={2}>
                  MyPay
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                minWidth={150}
              >
                <Typography
                  variant="subtitle1"
                  color="primary.500"
                  fontWeight={400}
                >
                  Note
                </Typography>
                <Typography variant="h6" color="primary.700" mt={2}>
                  {note}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Transaction ID and Details */}

            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  variant="subtitle1"
                  color="primary.500"
                  fontWeight={400}
                >
                  Transaction ID
                </Typography>
                <Typography variant="h6" color="primary.700" mt={2}>
                  {data?.transactionId}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                minWidth={150}
              >
                <Typography
                  variant="subtitle1"
                  color="primary.500"
                  fontWeight={400}
                >
                  Details
                </Typography>
                <Typography variant="h6" color="primary.700" mt={2}>
                  {data?.details || "Success"}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 4 }} />
          </Box>
          {/* Share Button */}
          <Box mt={4} textAlign="center">
            <Button
              fullWidth
              disabled
              variant="contained"
              color="primary"
              label="Share"
              disabledBgColor="#F2F4F7"
              labelSx={{
                color: "primary.300",
              }}
              sx={{ mt: 4 }}
            />
          </Box>
        </Box>
      </CenterModal>
    </Box>
  );
};

export default TransferInfo;
