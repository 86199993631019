import { Box, Card, CardContent, Typography } from "@mui/material";
import { Button, StatusPercentage } from "components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TFinancialStatus } from "types/types";

interface StatusCardProps {
  title: string;
  score: number;
  statusName?: string;
  status?: TFinancialStatus;
  path?: string;
}

const StatusCard: React.FC<StatusCardProps> = ({
  title,
  score,
  statusName,
  status,
  path,
}) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        height: 352,
        p: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "secondary.300",
        boxShadow: 0,
        borderRadius: 2,
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box flex={1}>
          <Typography variant="body1" color={"primary.main"}>
            {title}
          </Typography>
          <Typography
            fontSize={"3.5rem"}
            lineHeight={"4.25rem"}
            fontWeight={500}
            mt={4}
          >
            {score}
          </Typography>
          {status && (
            <Box mt={13}>
              <Typography variant="subtitle2">{statusName}</Typography>
              <StatusPercentage status={status} />
            </Box>
          )}
        </Box>
        <Button
          label="Details"
          onClick={
            path
              ? () => {
                  navigate(`/unit-score/${path}`);
                }
              : undefined
          }
        />
      </CardContent>
    </Card>
  );
};

export default StatusCard;
