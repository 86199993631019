import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Button, Input } from "components";
import React from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { register, resetPassword } from "store/actions/AuthAction";
import { getRole } from "store/reducers/AuthReducer";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { showToast } from "utils/showToast";
import { newPasswordSchema } from "validation/authValidation";

interface INewPasswordForm {
  password: string;
  repeatPassword: string;
}
interface INewPassword {
  onNext: () => void;
  reset?: boolean;
  spouse?: boolean;
}
const NewPasswordForm = ({ onNext, reset, spouse }: INewPassword) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(getIsLoading);
  const role = useAppSelector(getRole);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<INewPasswordForm>({
    resolver: yupResolver(newPasswordSchema) as Resolver<INewPasswordForm>,
    mode: "onChange",
  });

  const onSubmit = async (data: INewPasswordForm) => {
    const addressData = JSON.parse(localStorage.getItem("addressData"));
    const registerData = JSON.parse(localStorage.getItem("registerData"));
    const body = {
      address: addressData,
      ...registerData,
      password: data.password,
      role,
    };
    let response = null;
    if (reset) {
      response = await dispatch(resetPassword({ password: data?.password }));
      if (response?.meta?.requestStatus === "fulfilled") {
        showToast(
          "Password reset successfully. You can now log in with your new password.",
          "success"
        );
        navigate("/login");
      }
    } else {
      response = await dispatch(register(body));
      if (response?.meta?.requestStatus === "fulfilled") {
        localStorage.setItem("password", data.password);
        onNext();
      }
    }
  };
  const password = watch("password");

  const validatePasswordLength = password?.length >= 8;
  const validatePasswordCase = /[A-Z]/.test(password) && /[a-z]/.test(password);
  const validatePasswordNumberOrSymbol =
    /\d/.test(password) || /[!@#$%^&*]/.test(password);

  return (
    <Box>
      <FormControl
        fullWidth
        sx={{ mb: 1 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              required
              label="Enter new password"
              type={"password"}
              error={!!errors.password}
              helperText={errors.password?.message}
              {...field}
            />
          )}
        />
        <Box mt={4} mb={6} display={"flex"} flexDirection={"column"} gap={3}>
          <FormControlLabel
            control={<Checkbox checked={validatePasswordLength} />}
            label="Use at least 8 characters."
          />
          <FormControlLabel
            control={<Checkbox checked={validatePasswordCase} />}
            label="Include at least one uppercase letter and one lowercase letter."
          />
          <FormControlLabel
            control={<Checkbox checked={validatePasswordNumberOrSymbol} />}
            label="Use at least one number or symbol."
          />
        </Box>
        <Controller
          name="repeatPassword"
          control={control}
          render={({ field }) => (
            <Input
              required
              label="Repeat password"
              type="password"
              error={!!errors.repeatPassword}
              helperText={errors.repeatPassword?.message}
              {...field}
            />
          )}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          disabled={!isDirty || !isValid}
          variant="contained"
          fullWidth
          label="Continue"
          sx={{ mt: 10 }}
          size="large"
          loading={loading}
        />
      </FormControl>
    </Box>
  );
};

export default NewPasswordForm;
