import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  createUnit,
  updateUnitName,
  getUnits,
  getUnitById,
  deleteUnitIfEmpty,
  getClients,
  getCommanders,
  getUserById,
  deleteUserById,
} from "../actions/AdminAction";
import { RootState } from "store";

// Define the initial state for the admin slice
const initialState = {
  units: [],
  clients: {
    data: [],
    total: 0,
  },
  commanders: {
    data: [],
    total: 0,
  },
  selectedUnit: null,
  selectedUser: null,
};

// Create the admin slice
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all units
    builder.addCase(getUnits.fulfilled, (state, { payload }) => {
      state.units = payload;
    });

    // Get a specific unit by ID
    builder.addCase(getUnitById.fulfilled, (state, { payload }) => {
      state.selectedUnit = payload;
    });

    // Create a new unit
    builder.addCase(createUnit.fulfilled, (state, { payload }) => {
      state.units.push(payload);
    });

    // Update the name of a unit by ID
    builder.addCase(updateUnitName.fulfilled, (state, { payload }) => {
      const index = state.units.findIndex((unit) => unit.id === payload.id);
      if (index !== -1) {
        state.units[index] = payload;
      }
    });

    // Delete a unit if empty by ID
    builder.addCase(deleteUnitIfEmpty.fulfilled, (state, { meta }) => {
      state.units = state.units.filter((unit) => unit.id !== meta.arg);
    });

    // Get all clients
    builder.addCase(getClients.fulfilled, (state, { payload }) => {
      state.clients = payload;
    });

    // Get all commanders
    builder.addCase(getCommanders.fulfilled, (state, { payload }) => {
      state.commanders = payload;
    });

    // Get a specific user by ID
    builder.addCase(getUserById.fulfilled, (state, { payload }) => {
      state.selectedUser = payload;
    });

    // Delete a user by ID
    builder.addCase(deleteUserById.fulfilled, (state, { meta }) => {
      state.clients.data = state.clients?.data?.filter(
        (client) => client.id !== meta.arg
      );
      state.commanders.data = state.commanders.data.filter(
        (commander) => commander.id !== meta.arg
      );
    });
  },
});

// Selectors
const selectAdminState = (state: RootState) => state.admin;

export const selectUnits = createSelector(
  selectAdminState,
  (state) => state.units
);
export const selectClients = createSelector(
  selectAdminState,
  (state) => state.clients
);
export const selectCommanders = createSelector(
  selectAdminState,
  (state) => state.commanders
);
export const selectSelectedUnit = createSelector(
  selectAdminState,
  (state) => state.selectedUnit
);
export const selectSelectedUser = createSelector(
  selectAdminState,
  (state) => state.selectedUser
);

// Export the reducer
export default adminSlice.reducer;
