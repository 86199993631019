import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import React from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { Button, Input } from "../";
import { userInfoSchema } from "../../validation/authValidation";
import formatPhoneNumber from "utils/formatPhoneNumber";

interface IUserName {
  onNext: () => void;
}
interface IUserNameFormInputs {
  userName: string;
  firstName: string;
  lastName: string;
  phone_number: string;
}
const UserName = ({ onNext }: IUserName) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<IUserNameFormInputs>({
    resolver: yupResolver(userInfoSchema) as Resolver<IUserNameFormInputs>,
    mode: "onChange",
  });

  const onSubmit = (data: IUserNameFormInputs) => {
    localStorage.setItem("registerData", JSON.stringify(data));
    onNext();
  };
  return (
    <Box width={"100%"}>
      <FormControl
        fullWidth
        sx={{ gap: 6, mb: 1 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="userName"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="Create your username"
              placeholder="John"
              error={!!errors.userName}
              helperText={errors.userName?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="Create your firstname"
              placeholder="John"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="Create your lastname"
              placeholder="Miller"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="phone_number"
          control={control}
          render={({ field }) => (
            <Input
              type="tel"
              required
              label="Phone number"
              placeholder="+1 234 567 9121"
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message}
              {...field}
              onChange={(e) => {
                const formatted = formatPhoneNumber(e.target.value);
                field.onChange(formatted); // Update form value
              }}
            />
          )}
        />
      </FormControl>
      <Button
        onClick={handleSubmit(onSubmit)}
        type="submit"
        disabled={!isDirty || !isValid}
        variant="contained"
        fullWidth
        label="Continue"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default UserName;
