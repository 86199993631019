import * as React from "react";
const CoinsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={39}
      height={39}
      x={0.5}
      y={0.5}
      fill="#707070"
      fillOpacity={0.12}
      rx={5.5}
    />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#ADB2BB" rx={5.5} />
    <path
      stroke="#6D7584"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.422 12.158a7.368 7.368 0 1 1 10.42 10.42m-3.105.053a7.369 7.369 0 1 1-14.738 0 7.369 7.369 0 0 1 14.738 0Z"
    />
  </svg>
);
export default CoinsIcon;
