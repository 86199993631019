import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { ArrowRightIcon, SmallPlusIcon } from "assets/icons";
import { Button } from "components";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import priceNumberToDollar from "utils/priceNumberToDollar";

type SummaryCardData =
  | {
      lastUpdated: Date;
      category: React.JSX.Element;
      id: number;
      image: string;
      title: string;
      date: Date;
      amount: { amt: number; currency: string };
    }[];
interface SummaryCardProps {
  title: string;
  totalAmount?: number;
  data: SummaryCardData;
  onAdd: () => void;
}

const SummaryCard = ({ title, totalAmount, data, onAdd }: SummaryCardProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: "1px solid #e0e0e0",
        backgroundColor: "#fff",
        marginBottom: 2,
        height: 476,
        maxHeight: 476,
        overflowY: "hidden",
        py: 4,
        px: 1.5,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={2.5}
      >
        <Box display={"flex"}>
          <Typography variant="h5" fontWeight={600} color={"primary.700"}>
            {title}
          </Typography>
          {totalAmount && (
            <Typography
              variant="h5"
              fontWeight={600}
              ml={2}
              color={totalAmount < 0 ? "error.600" : "success.600"}
            >
              {priceNumberToDollar(totalAmount)}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            onClick={onAdd}
            sx={{ borderColor: "primary.main", mr: 7.5, px: 2.5, py: 0.75 }}
          >
            <SmallPlusIcon />
            <Typography variant="h6" fontWeight={600} ml={0.5}>
              Add
            </Typography>
          </Button>
          <IconButton
            onClick={() => {
              navigate(`${title?.toLowerCase()}`);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <List
        sx={{
          pt: 0,
          maxHeight: 5 * 80,
          overflowY: "auto",
          px: 2.5,
        }}
      >
        {data?.map((item) => (
          <React.Fragment key={item?.id}>
            <ListItem
              sx={{
                justifyContent: "space-between",
                px: 0,
                py: 4,
              }}
              alignItems="flex-start"
            >
              <Box display={"flex"} alignItems={"center"}>
                <Avatar
                  alt={item?.image}
                  src={item?.image}
                  sx={{ width: 48, height: 48, borderRadius: 48, mr: 3 }}
                />
                <Box maxWidth={"70%"}>
                  <Typography variant="body1" color={"primary.700"}>
                    {item.title}
                  </Typography>
                  {item.category && (
                    <Typography
                      variant="subtitle1"
                      color={"primary.500"}
                      mt={1}
                    >
                      {item.category}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                minWidth={100}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-end"}
              >
                <Typography
                  variant="body1"
                  color={"primary.700"}
                  fontWeight={600}
                  textAlign={"right"}
                >
                  {priceNumberToDollar(item?.amount?.amt || item?.amount)}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={"primary.500"}
                  mt={1}
                  whiteSpace={"pre"}
                >
                  {item?.date || item?.lastUpdated
                    ? dayjs(item?.date || item?.lastUpdated)?.format(
                        "DD MMM YYYY, HH:mm A"
                      )
                    : ""}
                </Typography>
              </Box>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default SummaryCard;
