import { Box, Card, CardContent, Typography } from "@mui/material";
import { Button, DistributionBar, StatusPercentage } from "components";
import React from "react";

const StatusDistribution = ({ title, notReady, marginallyReady, ready }) => {
  return (
    <Card
      sx={{
        height: 352,
        p: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "secondary.300",
        boxShadow: 0,
        borderRadius: 2,
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box flex={1}>
          <Typography variant="body1" color={"primary.main"}>
            {title}
          </Typography>
          <Box mt={4}>
            <DistributionBar
              notReady={notReady}
              marginallyReady={marginallyReady}
              ready={ready}
            />
          </Box>
          <Box mt={17} display={"flex"} flexDirection={"column"} gap={3}>
            <StatusPercentage status={"Not Ready"} percentage={notReady} />
            <StatusPercentage
              status={"Marginally Ready"}
              percentage={marginallyReady}
            />
            <StatusPercentage status={"Ready"} percentage={ready} />
          </Box>
        </Box>
        <Button label="Details" />
      </CardContent>
    </Card>
  );
};

export default StatusDistribution;
