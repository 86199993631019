import { combineReducers } from "@reduxjs/toolkit";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./AuthReducer";
import { loadingReducer } from "./LoadingReducer";
import FamilyMemberReducer from "./FamilyMemberReducer";
import GoalReducer from "./GoalReducer";
import UnitReducer from "./UnitReducer";
import NotificationReducer from "./NotificationReducer";
import TransactionReducer from "./TransactionReducer";
import AdminReducer from "./AdminReducer";
export type RootReducer = ReturnType<typeof reducers>;

export const reducers = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  familyMember: FamilyMemberReducer,
  goals: GoalReducer,
  unit: UnitReducer,
  notifications: NotificationReducer,
  transactions: TransactionReducer,
  admin: AdminReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "unit"],
};

export const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  reducers
);
