import * as React from "react";
const SuccessIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={76}
    fill="none"
    {...props}
  >
    <rect width={66} height={66} x={5} y={5} fill="#D1FADF" rx={33} />
    <rect
      width={66}
      height={66}
      x={5}
      y={5}
      stroke="#ECFDF3"
      strokeWidth={10}
      rx={33}
    />
    <path
      stroke="#0C425F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.333}
      d="M49.67 36.926V38a11.667 11.667 0 1 1-6.92-10.663m6.92 1.33L38.002 40.344l-3.5-3.5"
    />
  </svg>
);
export default SuccessIcon;
