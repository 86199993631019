export const statusOptions = [
  { value: "All", label: "All" },
  { value: "Ready", label: "Ready" },
  { value: "Marginally Ready", label: "Marginally Ready" },
  { value: "Not Ready", label: "Not Ready" },
];
export const traningOptions = [
  { value: "All", label: "All" },
  { value: "Required", label: "Required" },
  { value: "Completed", label: "Completed" },
];

export const longStatusOptions = [
  { value: "All", label: "All" },
  { value: "3 days", label: "3 days" },
  { value: "5 days", label: "5 days" },
  { value: "10 days", label: "10 days" },
];
export const consultationOptions = [
  { value: "All", label: "All" },
  { value: "Provided", label: "Provided" },
  { value: "Not provided", label: "Not provided" },
];
export const expenseCategories = [
  { value: "Grocery", label: "Grocery" },
  { value: "Housing & Utilities", label: "Housing & Utilities" },
  { value: "Education", label: "Education" },
  { value: "Transportation", label: "Transportation" },
  {
    value: "Business Services, Fees & Charges",
    label: "Business Services, Fees & Charges",
  },
  {
    value: "Personal Care, Health & Fitness",
    label: "Personal Care, Health & Fitness",
  },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Food & Dining", label: "Food & Dining" },
  { value: "Shopping", label: "Shopping" },
  { value: "Others, Gifts & Donations", label: "Others, Gifts & Donations" },
  { value: "Travel", label: "Travel" },
  { value: "Savings", label: "Savings" },
  { value: "Investments", label: "Investments" },
];
export const assetsTypes = [
  { value: "Savings Demand Account", label: "Savings Demand Account" },
  { value: "Demand Deposit Account", label: "Demand Deposit Account" },
  {
    value: "Certificate of Deposit Account",
    label: "Certificate of Deposit Account",
  },
  { value: "Money Market Account", label: "Money Market Account" },
  {
    value: "Individual Retirement Account",
    label: "Individual Retirement Account",
  },

  // { value: "Health Savings Account", label: "Health Savings Account" },
  // { value: "Brokerage Account", label: "Brokerage Account" },
  // { value: "Time Deposit Account", label: "Time Deposit Account" },
  // { value: "Foreign Currency Account", label: "Foreign Currency Account" },
  // {
  //   value: "Brokerage Portfolio Account",
  //   label: "Brokerage Portfolio Account",
  // },
  // { value: "Whole Life Insurance", label: "Whole Life Insurance" },
  // { value: "Investments", label: "Investments" },
];
