import {
  Avatar,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

import { adminMenus, clientMenus, commmanderMenus } from "constants/menu";
import { useAppDispatch, useAppSelector } from "store";
import { getUser, signOut } from "store/reducers/AuthReducer";
import { LogoutIcon } from "../../assets/icons";
import Button from "../custom/Button";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const role = user?.data?.role || "Client";

  const menuForRole =
    role === "Client" || role === "FamilyMember"
      ? clientMenus
      : role === "Commander"
      ? commmanderMenus
      : adminMenus;

  return (
    <Box position={"fixed"}>
      <Box
        sx={{
          width: 280,
          bgcolor: "primary.main",
          color: "#fff",
          height: "100vh",
          py: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box width={88} height={40} sx={{ margin: "0 auto" }}>
          <img src={Logo} alt="Logo" style={{ width: "100%" }} />
        </Box>
        <List sx={{ mt: 14, width: "100%", px: 2 }}>
          {menuForRole.map((item, index) => (
            <ListItemButton
              onClick={() => navigate(item?.href)}
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                height: 48,
                width: "100%",
                mb: 1,
                borderRadius: 2,
                backgroundColor: location.pathname.includes(item?.href)
                  ? "primary.800"
                  : "transparent",
              }}
              key={index}
            >
              {<item.icon />}
              <ListItemText primary={item.text} sx={{ ml: 2 }} />
            </ListItemButton>
          ))}
        </List>
        <Box
          sx={{
            mt: "auto",
            px: 3,
            pt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: "#EAECF033",
          }}
        >
          <Avatar
            alt={user?.data?.userName}
            src="path-to-avatar.png"
            sx={{ width: 48, height: 48, borderRadius: 48, mr: 3 }}
          />
          <Box flex={1}>
            <Typography
              maxWidth={150}
              variant="body1"
              fontWeight={500}
              sx={{ wordWrap: "break-word", overflowWrap: "break-word" }}
            >
              {user?.data?.userName}
            </Typography>
            <Typography variant="subtitle1" color={"primary.300"}>
              {user?.data?.role}
            </Typography>
          </Box>
          <Button
            onClick={async () => {
              dispatch(signOut());
              navigate("/login");
            }}
            sx={{ minWidth: 24, p: 0 }}
          >
            <LogoutIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
