import * as React from "react";
const EnvelopeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.29 4.907a54.396 54.396 0 0 1 9.421 0l1.51.132a2.889 2.889 0 0 1 2.313 1.545.236.236 0 0 1-.09.307l-6.267 3.88a4.25 4.25 0 0 1-4.399.045L3.47 7.088a.236.236 0 0 1-.103-.293A2.889 2.889 0 0 1 5.78 5.039l1.51-.132Z"
    />
    <path
      fill="#fff"
      d="M3.362 8.767a.248.248 0 0 0-.373.187 30.35 30.35 0 0 0 .184 7.56A2.888 2.888 0 0 0 5.78 18.96l1.51.131c3.135.273 6.287.273 9.422 0l1.51-.131a2.888 2.888 0 0 0 2.606-2.448 30.35 30.35 0 0 0 .161-7.779.248.248 0 0 0-.377-.183l-5.644 3.495a5.75 5.75 0 0 1-5.952.06l-5.653-3.34Z"
    />
  </svg>
);
export default EnvelopeIcon;
