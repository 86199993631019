import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { SettingsIcon } from "assets/icons";
import { DashboardHeader, EmptyClientNotification, TabBar } from "components";
import { periodTabs } from "constants/tabs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store"; // Adjust this according to your root reducer
import { getAllNotifications } from "store/actions/NotificationAction";
import { selectNotifications } from "store/reducers/NotificationReducer";
import { getIsLoading } from "store/reducers/LoadingReducer";

const ClientNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(getIsLoading);

  // State to manage the selected tab
  const defaultTabValue = parseInt(searchParams.get("period")) || 0;
  const [tabValue, setTabValue] = useState(defaultTabValue);

  // Get data from Redux store
  const notifications = useAppSelector(selectNotifications);
  const periodFilter = ["day", "week", "month"][tabValue];
  const getAllNotificationsData = useCallback(
    async () =>
      dispatch(
        getAllNotifications({ dateFilter: periodFilter, limit: 10, offset: 0 })
      ),
    [dispatch, periodFilter]
  );

  // Effect to fetch notifications when the component mounts or tab changes
  useEffect(() => {
    getAllNotificationsData();
  }, [dispatch, getAllNotificationsData]);

  // Set the default searchParams when the component mounts
  useEffect(() => {
    if (!searchParams.get("period")) {
      setSearchParams({ period: "0" }); // Default to "Daily" tab
    }
  }, [searchParams, setSearchParams]);

  // Handle tab change
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    setSearchParams({ period: newValue });
  };

  return (
    <Box>
      <DashboardHeader page="Notifications">
        <IconButton onClick={() => navigate("settings")}>
          <SettingsIcon
            width={32}
            height={32}
            viewBox="0 0 16 16"
            strokeWidth={1}
          />
        </IconButton>
      </DashboardHeader>
      <Box px={10} py={8}>
        <TabBar
          value={tabValue}
          onChange={handleTabChange}
          tabs={periodTabs}
          containerSx={{
            boxShadow: "0px 1px 2px 0px #1018280D",
            mb: 6,
          }}
          tabSx={{
            width: 147,
          }}
          selectedColor={"primary.main"}
        />

        <Box>
          {/* Handle loading and error states */}
          {isLoading ? (
            <LinearProgress />
          ) : notifications?.length > 0 ? (
            notifications?.map((item) => (
              <Paper
                key={item.id} // Ensure to add a unique key
                sx={{
                  height: 60,
                  backgroundColor: !item?.read ? "#E0E9ED" : "white",
                  border: "1px solid #E0E9ED",
                  mt: 4,
                  borderRadius: 2,
                  p: 2,
                  gap: 1,
                }}
              >
                <Typography variant="h6" fontWeight={500} color={"primary.700"}>
                  {item?.name}
                </Typography>
                <Typography variant="h6" color={"primary.500"}>
                  {item?.articles || 1} articles
                </Typography>
              </Paper>
            ))
          ) : (
            <EmptyClientNotification />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ClientNotifications;
