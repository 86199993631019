import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Chip,
} from "@mui/material";

import { ArrowRightIcon, CoinsStockIcon } from "assets/icons";

interface RelatedInfoCardProps {
  image: string;
  title: string;
  description: string;
}
interface RelatedInformationProps {
  data: RelatedInfoCardProps[];
  row?: number;
}

const RelatedInfoCard: React.FC<RelatedInfoCardProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "#F9FAFC",
        p: 2,
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{ height: 154, borderRadius: 1.5 }}
      />
      <CardContent sx={{ p: 0, mt: 4 }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"primary.700"}>{title} </Typography>
          <Chip
            sx={{
              width: 100,
              height: 20,
              color: "#1075F2",
              borderRadius: 3,
              bgcolor: "#F0F7FF",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#ABC2F9",
            }}
            icon={<CoinsStockIcon />}
            label="Finances"
          />
        </Box>
        <Typography
          variant="h6"
          color="primary.500"
          fontWeight={400}
          mt={2}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};
//mock data give for relatedInfo

const RelatedInformation = ({ data, row = 6 }: RelatedInformationProps) => {
  return (
    <Box mt={6} borderRadius={2} bgcolor={"white"} p={4}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h5" fontWeight={600} color={"primary.700"}>
          Explore related information
        </Typography>
        <ArrowRightIcon />
      </Box>
      <Grid container spacing={7}>
        {data?.map((item) => (
          <Grid item xs={row}>
            <RelatedInfoCard {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RelatedInformation;
