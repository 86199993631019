import { Box, Typography } from "@mui/material";
import { SearchOffIcon } from "assets/icons";
import React from "react";

const EmptySearchResult = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        py: 5,
        bgcolor: "white",
        pt: 22,
      }}
    >
      <SearchOffIcon />
      <Typography
        variant="h3"
        sx={{
          mt: 2,
          fontWeight: 600,
          color: "primary.main",
          padding: "0 10px",
          borderRadius: 2,
        }}
      >
        No results
      </Typography>
      <Typography variant="body2" color="primary.800" sx={{ mt: 1 }}>
        No data here, please try another search query.
      </Typography>
    </Box>
  );
};

export default EmptySearchResult;
