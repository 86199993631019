import * as React from "react";
const IncreaseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#32D583"
      stroke="#32D583"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.078 16-9-9-9 9h18Z"
    />
  </svg>
);
export default IncreaseIcon;
