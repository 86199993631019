import { Box, Grid, Typography } from "@mui/material";
import { ArrowRightIcon } from "assets/icons";
import {
  Button,
  CenterModal,
  ConnectAccountByCardModal,
  Input,
  LoginAccountModal,
} from "components";
import useModal from "hooks/useModal";
import React, { useMemo, useState } from "react";
import Logo from "../../assets/images/logo.png";

const AccountsList = ({ onNext }) => {
  const [hover, setHover] = useState(null);
  const [search, setSearch] = useState("");
  const {
    isVisible: isLoginAccountModalOpen,
    onClose: closeLoginAccountModal,
    onOpen: openLoginAccountModal,
  } = useModal();
  const {
    isVisible: isConnectAccountModalOpen,
    onClose: closeConnectAccountModal,
    onOpen: openConnectAccountModal,
  } = useModal();

  const accounts = useMemo(
    () =>
      [
        { id: 1, name: "Account name 1" },
        { id: 2, name: "Account name 2" },
        { id: 3, name: "Account name 3" },
        { id: 4, name: "Account name 4" },
        { id: 5, name: "Account name 5" },
        { id: 6, name: "Account name 6" },
      ]?.filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase())
      ),
    [search]
  );

  return (
    <Box
      display={"flex"}
      height={"100%"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Typography variant="h2" color={"primary.900"} fontWeight={600} mb={8}>
          Connect accounts
        </Typography>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by account name"
          variant="outlined"
          fullWidth
          search
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
          }}
        />
        <Grid container spacing={5} mt={4}>
          {accounts.map((account, index) => (
            <Grid item xs={4} key={account.id}>
              <Button
                fullWidth
                onMouseOver={() => {
                  setHover(index);
                }}
                onClick={openLoginAccountModal}
                onMouseOut={() => setHover(false)}
                sx={{
                  cursor: "pointer",
                  height: 102,
                  border: "1px solid #EAECF0",
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    borderColor: "#CBD5E1",
                    transition:
                      "background-color 0.7s, border-color 9.7s, color 0.7s",
                  },
                }}
              >
                <img src={Logo} alt="Logo" style={{ width: 60, height: 30 }} />
                <Box display={"flex"} alignItems={"center"} mt={4}>
                  <Typography
                    variant="h6"
                    color={hover === index ? "white" : "primary.600"}
                    mr={1}
                  >
                    {account.name}
                  </Typography>
                  {hover === index && <ArrowRightIcon color="white" />}
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between" gap={5}>
        <Button
          onClick={openConnectAccountModal}
          sx={{ height: 48 }}
          fullWidth
          variant="outlined"
          label="Connect by card number"
        />
        <Button
          onClick={onNext}
          sx={{ height: 48 }}
          fullWidth
          variant="contained"
          color="primary"
          label="Continue"
        />
      </Box>
      <CenterModal
        open={isLoginAccountModalOpen}
        onClose={closeLoginAccountModal}
        close={false}
        sx={{ width: 463 }}
      >
        <LoginAccountModal />
      </CenterModal>
      <CenterModal
        open={isConnectAccountModalOpen}
        onClose={closeConnectAccountModal}
        close={false}
        sx={{ width: 463 }}
      >
        <ConnectAccountByCardModal />
      </CenterModal>
    </Box>
  );
};

export default AccountsList;
