import { Box, LinearProgress, Typography } from "@mui/material";
import { PointsIcon } from "assets/icons";
import { Button, FilterList, Select, Table } from "components";
import FinancialStatus from "components/common/FinancialStatus";
import { statusOptions, traningOptions } from "datas/select";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams
import { useAppDispatch, useAppSelector } from "store";
import { getUnitRosters } from "store/actions/UnitRostersAction";
import { IColumn } from "types/types";
import typography from "../../theme/typography";
import OptionsPopover from "./OptionsModal";
import { getAllUnitsByCommanderId } from "store/actions/UnitsAction";
import { getUser } from "store/reducers/AuthReducer";
import { setOverallUnitId } from "store/reducers/UnitReducer";

const RosterTable = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rosters, setRosters] = useState(null); // Holds the fetched data
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  // Use URLSearchParams to manage filters in the URL
  const [searchParams, setSearchParams] = useSearchParams();

  // Initial filters, checking if they exist in the searchParams
  const [selectedFilters, setSelectedFilters] = useState({
    financialStatus: searchParams.get("financialStatus") || "All",
    trainingStatus: searchParams.get("trainingStatus") || "All",
  });

  // Get `page`, `limit`, and `unitId` from URL search params
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 8;
  const [selectedUnit, setSelectedUnit] = useState(
    searchParams.get("unitId") || ""
  );

  // Function to handle the popover open event
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  // Filter options for the toolbar
  const toolbarFilters = {
    financialStatus: {
      label: "Financial Readiness Status",
      options: statusOptions,
    },
    trainingStatus: {
      label: "Training Status",
      options: traningOptions,
    },
  };

  // Define table columns
  const columns: IColumn[] = [
    {
      id: "firstName",
      label: "First & Last Name",
      style: { ...typography.body1 },
      sortable: true,
      render: (row) => (
        <Typography sx={{ ...typography.body1 }}>
          {row.firstName} {row?.lastName}
        </Typography>
      ),
    },
    {
      id: "rank",
      label: "Rank",
      style: { ...typography.body1 },
      sortable: true,
    },
    {
      id: "financialReadinessStatus",
      label: "Financial Readiness Status",
      render: (row) => (
        <FinancialStatus status={row.financialReadinessStatus} />
      ),
    },
    {
      id: "financialReadinessScore",
      label: "Financial Readiness Score",
      style: { ...typography.body1 },
    },
    {
      id: "historicalFinancialReadinessScore",
      label: "Historical financial readiness score",
      style: { ...typography.body1 },
    },
    {
      id: "currentOnTraining",
      label: "Current on Training",
      style: { ...typography.body1 },
    },
    {
      id: "trainingStatus",
      label: "Training Status",
      render: (row) => (
        <Button
          variant="contained"
          label={row.trainingStatus}
          disabled={row.trainingStatus === "Completed"}
          sx={{
            ...typography.body1,
            width: 106,
            height: 36,
            bgcolor: "success.500",
          }}
        />
      ),
    },
    {
      id: "options",
      label: "",
      render: () => (
        <Button variant="text" onClick={handleOpenPopover}>
          <PointsIcon />
        </Button>
      ),
    },
  ];

  // Function to fetch rosters and set them in the state
  const getRosters = useCallback(async () => {
    if (selectedUnit) {
      setLoading(true);
      const response = await dispatch(
        getUnitRosters({
          unitId: selectedUnit, // Pass selectedUnit from state
          financialStatus: selectedFilters.financialStatus,
          trainingStatus: selectedFilters.trainingStatus,
          page: Number(page), // Use the page from URL search params
          limit: Number(limit), // Use the limit from URL search params
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setRosters(response?.payload); // Update the state with API data
      }
      setLoading(false);
    }
  }, [
    dispatch,
    selectedUnit,
    selectedFilters.financialStatus,
    selectedFilters.trainingStatus,
    page,
    limit,
  ]);

  const getAllUnits = useCallback(async () => {
    const response = await dispatch(getAllUnitsByCommanderId(user?.data?.id));
    if (response?.meta?.requestStatus === "fulfilled") {
      setUnits(response?.payload);
      // Set default selected unit if not already set
      if (!selectedUnit) {
        setSelectedUnit(response?.payload[0]?.id);
      }
    }
  }, [dispatch, user?.data?.id, selectedUnit]);

  useEffect(() => {
    getAllUnits();
  }, [getAllUnits]);

  // Update filters, unit, and pagination in URL when they change
  useEffect(() => {
    const newSearchParams = new URLSearchParams();

    if (selectedFilters.financialStatus !== "All") {
      newSearchParams.set("financialStatus", selectedFilters.financialStatus);
    }
    if (selectedFilters.trainingStatus !== "All") {
      newSearchParams.set("trainingStatus", selectedFilters.trainingStatus);
    }
    if (selectedUnit) {
      newSearchParams.set("unitId", selectedUnit); // Set selected unit in URL
    }
    newSearchParams.set("page", page.toString()); // Set page in the URL
    newSearchParams.set("limit", limit.toString()); // Set limit in the URL

    setSearchParams(newSearchParams); // Update the URL with filters, unit, and pagination
    getRosters(); // Fetch data whenever filters, unit, or pagination change
  }, [selectedFilters, selectedUnit, page, limit, setSearchParams, getRosters]);

  return (
    <Box width="100%" mt={8}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h2" fontWeight={500} color={"secondary.200"}>
          Unit Rosters
        </Typography>

        <FilterList
          currentFilters={selectedFilters}
          filters={toolbarFilters}
          onApplyFilters={(newFilters) => {
            //set page to 1
            setSearchParams({ page: "1" });
            setSelectedFilters(newFilters); // Update selected filters
          }}
        />
      </Box>
      <Box my={8} display={"flex"}>
        {/* Select Unit Dropdown */}
        {units && (
          <Select
            options={units?.map((unit) => ({
              label: unit.name,
              value: unit.id,
            }))}
            label="Unit"
            value={units?.find((item) => item?.id === selectedUnit)?.name || ""}
            onChange={(e) => {
              setSearchParams({ page: "1" });
              setSelectedUnit(e.target.value); // Update selected unit
              dispatch(setOverallUnitId(e.target.value));
            }}
            sx={{ width: 252, height: 44 }}
          />
        )}
      </Box>

      {/* Render the table with filtered data */}
      {loading || !units ? (
        <LinearProgress />
      ) : (
        <Table
          data={rosters?.data}
          columns={columns}
          total={rosters?.total || 0}
        />
      )}

      <OptionsPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClosePopover}
      />
    </Box>
  );
};

export default RosterTable;
