import * as React from "react";
const MaximizeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={39}
      height={39}
      x={0.5}
      y={0.5}
      fill="#707070"
      fillOpacity={0.12}
      rx={5.5}
    />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#ADB2BB" rx={5.5} />
    <path
      stroke="#6D7584"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M23 11h6m0 0v6m0-6-7 7m-5 11h-6m0 0v-6m0 6 7-7"
    />
  </svg>
);
export default MaximizeIcon;
