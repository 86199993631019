import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { BigTrashIcon } from "assets/icons";
import {
  BasicCheckbox,
  Button,
  DashboardHeader,
  DeleteFamilyMember,
  InviteFamilyMember,
} from "components";
import CenterModal from "components/common/CenterModal";
import { permissions } from "constants/common";
import useModal from "hooks/useModal";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  deleteFamilyMember,
  getFamilyMembers,
} from "store/actions/SpouseAction";
import { getUser } from "store/reducers/AuthReducer";
import { getFamilyMembersList } from "store/reducers/FamilyMemberReducer";
import { showToast } from "utils/showToast";

const FamilyMember = () => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const familyMembers = useAppSelector(getFamilyMembersList);
  const isFamilyMember = user?.data?.role === "Counselor"; //FamilyMember role will be,Counselor for test
  const {
    isVisible: openInviteModal,
    onClose: handleCloseInviteModal,
    onOpen: handleOpenInviteModal,
  } = useModal();
  const {
    isVisible: openDeleteModal,
    onClose: handleCloseDeleteModal,
    onOpenWithValue: handleOpenDeleteModal,
  } = useModal();

  const handeSelect = (permissionId: number) => {
    if (selectedPermissions.includes(permissionId)) {
      setSelectedPermissions(
        selectedPermissions.filter((id) => id !== permissionId)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    }
  };

  //get Spouses useeffect

  useEffect(() => {
    dispatch(getFamilyMembers()); // Fetch family members on component mount
  }, [dispatch]);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      flex={1}
    >
      <DashboardHeader page="Family member">
        {!isFamilyMember && (
          <Button
            onClick={() => handleOpenInviteModal()}
            variant="contained"
            label="Add new Spouse"
          />
        )}
      </DashboardHeader>

      <Grid container spacing={6} mt={2} pb={6} px={10} height={"100%"}>
        <Grid item xs={8}>
          <Box
            p={4}
            bgcolor={"white"}
            height={"100%"}
            border={"1px solid #EAECF0"}
            borderRadius={2}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              color={"primary.700"}
              mb={2}
            >
              Your family member
            </Typography>
            <List>
              {familyMembers?.map((member,index) => (
                <ListItem
                key={index}
                  sx={{
                    mx: 0,
                    backgroundColor: "background.default",
                    border: "1px solid #EAECF0",
                    mb: 4,
                    borderRadius: 2,
                    px: 2,
                  }}
                  secondaryAction={
                    !isFamilyMember ? (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          handleOpenDeleteModal(member.id as number)
                        }
                      >
                        <BigTrashIcon />
                      </IconButton>
                    ) : (
                      <Typography
                        variant="h6"
                        fontWeight={400}
                        color={"primary.500"}
                      >
                        #{member?.id}
                      </Typography>
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar alt={member.name} src={member.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={member.userName} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            p={4}
            bgcolor={"white"}
            borderRadius={2}
            height={"100%"}
            border={"1px solid #EAECF0"}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              color={"primary.700"}
              mb={2}
            >
              Permissions
            </Typography>

            {permissions.map((permission,index) => (
              <ListItem
              key={index}
                sx={{
                  alignItems: "flex-start",
                  border: "1px solid #EAECF0",
                  mb: 4,
                  borderRadius: 2,
                  h: 80,
                }}
              >
                <BasicCheckbox
                  checked={selectedPermissions.includes(permission.id)}
                  onChange={() => handeSelect(permission.id)}
                  disabled={isFamilyMember}
                />
                <ListItemText
                  primary={permission.label}
                  secondary={permission.description}
                  sx={{
                    mt: -1,
                    ml: 2,
                  }}
                />
              </ListItem>
            ))}
          </Box>
        </Grid>
      </Grid>
      <CenterModal
        title="Invite Your Family member"
        open={openInviteModal}
        onClose={handleCloseInviteModal}
        sx={{ width: 480 }}
        blur
      >
        <InviteFamilyMember />
      </CenterModal>
      <CenterModal
        title={`Delete ${
          familyMembers?.find((member) => member.id === openDeleteModal)
            ?.userName || ""
        } from Family Member`}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        sx={{ width: 480 }}
        blur
      >
        <DeleteFamilyMember
          name={
            familyMembers?.find((member) => member.id === openDeleteModal)
              ?.userName || ""
          }
          onConfirm={async () => {
            const result = await dispatch(
              deleteFamilyMember(openDeleteModal as number)
            );
            if (result.meta.requestStatus === "fulfilled") {
              handleCloseDeleteModal();
              showToast("Family member deleted successfully", "success");
            }
          }}
        />
      </CenterModal>
    </Box>
  );
};

export default FamilyMember;
