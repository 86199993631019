// src/themes/theme.ts
import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import colors from "./colors";

const theme = createTheme({
  palette: colors,
  spacing: 4, // 1 == 4px
  typography,
});

export default theme;
