import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "store";
import actionTypesforLoader from "../actions/actionTypesforLoader";

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    const pendingActionTypes = actionTypesforLoader.map(
      (action) => action.pending
    );
    const fulfilledActionTypes = actionTypesforLoader.flatMap((action) => [
      action.fulfilled,
      action.rejected,
    ]);
    builder.addMatcher(isAnyOf(...pendingActionTypes), (state, action) => {
      state.isLoading = true;
    });

    builder.addMatcher(isAnyOf(...fulfilledActionTypes), (state, action) => {
      state.isLoading = false;
    });
  },
});

export const getIsLoading = (state: RootState) => state.loading.isLoading;
export const { setLoading } = loadingSlice.actions;

export const loadingReducer = loadingSlice.reducer;
