import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { ErrorIcon } from "assets/icons";

const NetworkError = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        padding: 2,
      }}
    >
      <ErrorIcon />
      {/* Example color and size */}
      <Typography variant="h5" fontWeight={600} mt={2}>
        Network Error
      </Typography>
      <Typography variant="body1" color="textSecondary" mt={1}>
        Something went wrong. Please, check your internet connection and try
        again.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={onRetry}
      >
        Refresh
      </Button>
    </Box>
  );
};

export default NetworkError;
