import * as React from "react";
const CoinsStackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={47} height={47} x={0.5} y={0.5} fill="#F4F3FF" rx={5.5} />
    <rect width={47} height={47} x={0.5} y={0.5} stroke="#BDB4FE" rx={5.5} />
    <path
      stroke="#6938EF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.175 16.222C26.177 14.994 23.674 14 20.588 14c-3.084 0-5.586.996-5.588 2.222m0 0c0 1.228 2.501 2.223 5.588 2.223s5.589-.995 5.589-2.223v8.622M15 16.223v13.334c.001 1.227 2.502 2.222 5.588 2.222 1.497 0 2.848-.237 3.85-.618m-9.437-10.493c0 1.227 2.502 2.222 5.588 2.222 3.087 0 5.589-.994 5.589-2.222m-1.672 6.028c-1.008.394-2.391.639-3.918.639-3.086 0-5.587-.995-5.587-2.223m17.526.354a4.98 4.98 0 0 1 0 7.07 5.051 5.051 0 0 1-7.113 0 4.98 4.98 0 0 1 0-7.07 5.051 5.051 0 0 1 7.113 0Z"
    />
  </svg>
);
export default CoinsStackIcon;
