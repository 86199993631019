import * as React from "react";
const EducationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.355 3.302c-.243-.07-.5-.07-.743 0-.519.148-1.042.284-1.568.421C7.701 4.335 5.12 5.01 2.913 6.81l-1.04.85c-.83.677-.83 2.003 0 2.68l1.024.834a12.01 12.01 0 0 0 2.353 1.491v4.629a2.75 2.75 0 0 0 1.751 2.562l4 1.56a2.75 2.75 0 0 0 1.998 0l4-1.56a2.75 2.75 0 0 0 1.751-2.562v-4.62c.803-.392 1.586-.871 2.337-1.484l.163-.133V16a.75.75 0 0 0 1.5 0V9c0-.5-.208-1.002-.623-1.34l-1.024-.834c-2.224-1.81-4.913-2.512-7.184-3.104-.524-.137-1.046-.273-1.564-.42ZM8.3 12.812a.75.75 0 0 0-.598 1.376c1.278.555 2.6 1.01 3.956 1.358.236.06.484.06.72 0a26.176 26.176 0 0 0 3.945-1.359.75.75 0 0 0-.6-1.374c-1.197.522-2.435.95-3.705 1.277a24.828 24.828 0 0 1-3.718-1.278Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EducationIcon;
