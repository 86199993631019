import { Box, Typography, LinearProgress } from "@mui/material";
import { FinancialStatus, Select, Table } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  consultationOptions,
  longStatusOptions,
  statusOptions,
} from "datas/select";
import typography from "theme/typography";
import { IColumn } from "types/types";
import {
  getAllUnitsByCommanderId,
  getconsultationReportByUnitId,
} from "store/actions/UnitsAction";
import { getUser } from "store/reducers/AuthReducer";

const IndividualsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    status: searchParams.get("status") || statusOptions[0]?.value || "",
    longStatus:
      searchParams.get("longStatus") || longStatusOptions[0]?.value || "",
    consultation:
      searchParams.get("consultation") || consultationOptions[0]?.value || "",
  });
  const [individualsData, setIndividualsData] = useState(null); // State to store fetched data
  const [loading, setLoading] = useState(false); // Loading state
  const [units, setUnits] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(
    searchParams.get("unitId") || ""
  );
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();

  const columns: IColumn[] = [
    {
      id: "firstName",
      label: "First & Last Name",
      style: { ...typography.body1 },
      sortable: true,
      render: (row) => (
        <Typography sx={{ ...typography.body1 }}>
          {row.firstName} {row?.lastName}
        </Typography>
      ),
    },
    {
      id: "rank",
      label: "Rank",
      style: { fontWeight: 400 },
      sortable: true,
    },
    {
      id: "financialReadinessStatus",
      label: "Status",
      render: (row) => (
        <FinancialStatus status={row.financialReadinessStatus} />
      ),
    },
    {
      id: "statusDuration",
      label: "How Long This Status",
      render: (row) => (
        <Typography
          sx={{ ...typography.body1, fontWeight: 400 }}
          color={"primary.main"}
        >
          {row?.statusDuration} {row?.statusDuration > 1 ? "days" : "day"}
        </Typography>
      ),
    },
    {
      id: "consultationStatus",
      label: "Consultation",
      style: { fontWeight: 400 },
    },
  ];

  // Function to fetch consultation report data based on filters
  const fetchConsultationData = useCallback(async () => {
    if (selectedUnit) {
      setLoading(true);
      const response = await dispatch(
        getconsultationReportByUnitId({
          unitId: selectedUnit,
          financialStatus: filter.status,
          consultationStatus: filter.consultation,
          statusDuration: filter.longStatus?.split(" ")?.[0],
          page: parseInt(searchParams.get("page") || "1"),
          limit: parseInt(searchParams.get("limit") || "8"),
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setIndividualsData(response?.payload);
      }
      setLoading(false);
    }
  }, [
    dispatch,
    filter.consultation,
    filter.longStatus,
    filter.status,
    searchParams,
    selectedUnit,
  ]);
  const getAllUnits = useCallback(async () => {
    const response = await dispatch(getAllUnitsByCommanderId(user?.data?.id));
    if (response?.meta?.requestStatus === "fulfilled") {
      setUnits(response?.payload);
      // Set default selected unit if not already set
      if (!selectedUnit) {
        setSelectedUnit(response?.payload[0]?.id);
      }
    }
  }, [dispatch, user?.data?.id, selectedUnit]);

  useEffect(() => {
    getAllUnits();
  }, [getAllUnits]);

  // Fetch data whenever filters or page change
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    params.set("status", filter.status);
    params.set("longStatus", filter.longStatus);
    params.set(
      "consultation",
      filter.consultation === "Not provided"
        ? "Not Provided"
        : filter.consultation
    );
    params.set("page", searchParams.get("page") || "1");
    params.set("limit", searchParams.get("limit") || "8");
    setSearchParams(params);

    fetchConsultationData();
  }, [filter, searchParams, fetchConsultationData, setSearchParams]);

  // Function to update filters
  const updateFilters = (updatedFilter: Partial<typeof filter>) => {
    setFilter((prev) => ({
      ...prev,
      ...updatedFilter,
    }));
    //set page to 1
    searchParams.set("page", "1");
  };

  return (
    <Box mt={8}>
      <Typography variant="h2">Information about Commanders</Typography>
      <Box my={8} display="flex" gap={6}>
        {units && (
          <Select
            options={units?.map((unit) => ({
              label: unit.name,
              value: unit.id,
            }))}
            label="Unit"
            value={units?.find((item) => item?.id === selectedUnit)?.name || ""}
            onChange={(e) => {
              setSearchParams({ page: "1" });
              setSelectedUnit(e.target.value); // Update selected unit
            }}
            sx={{ width: 252, height: 44 }}
          />
        )}
        <Select
          options={statusOptions}
          label="Status"
          value={filter.status}
          onChange={(e) => updateFilters({ status: e.target.value })}
          sx={{ width: 252, height: 44, borderColor: "red" }}
        />
        <Select
          options={longStatusOptions}
          label="How Long This Status"
          value={filter.longStatus}
          onChange={(e) => updateFilters({ longStatus: e.target.value })}
          sx={{ width: 252, height: 44, borderColor: "red" }}
        />
        <Select
          options={consultationOptions}
          label="Consultation"
          value={filter.consultation}
          onChange={(e) => updateFilters({ consultation: e.target.value })}
          sx={{ width: 252, height: 44, borderColor: "red" }}
        />
      </Box>

      {loading && <LinearProgress />}
      <Table
        data={individualsData?.data}
        columns={columns}
        total={individualsData?.total}
      />
    </Box>
  );
};

export default IndividualsList;
