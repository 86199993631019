import { Theme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  SxProps,
  Typography,
} from "@mui/material";
import { ArrowRightIcon } from "assets/icons";
import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Pie, PieChart, Sector } from "recharts";
import { useAppSelector } from "store";
import { selectExpenses } from "store/reducers/TransactionReducer";
import getCategoryPercentages from "utils/getCategoryPercentages";
import priceNumberToDollar from "utils/priceNumberToDollar";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

interface ExpensesCategoriesChartProps {
  listSx?: SxProps<Theme>;
}

const ExpensesCategoriesChart = ({ listSx }: ExpensesCategoriesChartProps) => {
  const navigate = useNavigate();
  const expenses = useAppSelector(selectExpenses);
  const { allCategories, topCategories } = getCategoryPercentages(expenses);

  const COLORS = ["#0B4A6F", "#D1E9FF", "#B2DDFF", "#2E90FA", "#175CD3"];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 0) * sin;

    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 45;
    const ey = my;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={10}
        />

        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={"#98A2B3"}
          fill="none"
          strokeDasharray="1.5"
        />
        <text
          x={ex + (cos >= 0 ? 0 : -1) * 25}
          y={ey}
          textAnchor={"start"}
          fill="#475467"
          fontSize={12}
          fontWeight={700}
          style={{ marginTop: 20 }}
        >{`${(percent * 100)?.toFixed(2)}%`}</text>
        <text
          x={ex + (cos >= 0 ? 0 : -1) * 25}
          y={ey}
          textAnchor="start"
          fill="#667085"
          fontSize={12}
        >
          {payload?.name
            ?.split(" ")
            ?.slice(0, 1)
            ?.map((word, index, array) => (
              <tspan
                key={index}
                x={ex + (cos >= 0 ? 0 : -1) * 25}
                dy={index === 0 ? 14 : 10}
              >
                {word}
              </tspan>
            ))}
        </text>
      </g>
    );
  };

  return (
    <Card
      sx={{
        // maxWidth: 450,
        minWidth: 350,
        marginLeft: "auto",
        height: "100%",
        boxShadow: 0,
        border: "1px solid #EAECF0",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          fontWeight={600}
          color={"primary.main"}
          mb={10}
        >
          Expenses categories
        </Typography>
        <Box
          position={"relative"}
          sx={{
            height: 200,
            "& .recharts-wrapper": {
              position: "absolute",
              top: -110,
              left: "calc(50% - 200px)",
              mb: 4,
            },
          }}
        >
          <Box
            position={"absolute"}
            top={-13}
            left={"calc(50% - 94px)"}
            width={176}
            height={176}
            borderRadius={176}
            bgcolor={"white"}
            boxShadow={" 0px 1.6px 20.03px 0px #00000014"}
          />
          <PieChart width={600} height={600}>
            <Pie
              activeIndex={[0, 1, 2, 3, 4]}
              activeShape={renderActiveShape}
              data={topCategories}
              cx={190}
              cy={180}
              innerRadius={45}
              outerRadius={80}
              dataKey="value"
              paddingAngle={4}
            >
              {topCategories?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                >
                  {renderActiveShape({
                    //   ...entry,
                    cx: 200,
                    cy: 200,
                    midAngle: 0,
                    innerRadius: 60,
                    outerRadius: 80,
                    startAngle: 0,
                    endAngle: 0,
                    fill: COLORS[index % COLORS.length],
                    payload: entry,
                    percent: +entry.value / 100,
                    value: +entry.value,
                  })}
                </Cell>
              ))}
            </Pie>
          </PieChart>
        </Box>
        <List
          sx={{
            pt: 0,
            height: "100%",
            maxHeight: 200,
            overflowY: "scroll",
            px: 2.5,
            mt: 2,
            ...listSx,
          }}
        >
          {allCategories?.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent: "space-between",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "primary.50",
                mt: 2,
                borderRadius: 2,
                p: 4,
                "&:hover": {
                  backgroundColor: "#F8FBFF",
                  borderColor: "#D6E3FC",
                },
              }}
              alignItems="flex-start"
            >
              <Box>
                <Typography variant="h6" fontWeight={600} color={"primary.700"}>
                  {item.name}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.500"}
                  mt={1}
                >
                  {item?.transactionCount} transactions
                </Typography>
              </Box>
              <Box mr={20}>
                <Typography variant="h6" fontWeight={600} color={"primary.700"}>
                  {priceNumberToDollar(item?.total)}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.500"}
                  mt={1}
                  textAlign={"right"}
                >
                  {item.value}%
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  navigate(
                    `/expenses?category=${encodeURIComponent(item?.name)}`
                  );
                }}
                sx={{
                  width: 52,
                  height: "100%",
                  bgcolor: "#0B4A6F",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  pt: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                  //remove hover color
                  "&:hover": {
                    bgcolor: "#0B4A6F",
                  },
                }}
              >
                <ArrowRightIcon color="white" />
              </Button>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default ExpensesCategoriesChart;
