import * as React from "react";
const QuestionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.508 7.84a3.185 3.185 0 0 1 3.47-1.806 3.09 3.09 0 0 1 2.266 1.614 2.68 2.68 0 0 1-1.562 3.689 1.98 1.98 0 0 0-1.276 1.787v.738"
    />
    <path
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.383 16.424h.008"
    />
    <path
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 20.5a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19Z"
    />
  </svg>
);
export default QuestionIcon;
