import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowDownRightIcon, ArrowUpRightIcon } from "assets/icons";
import { mockBudgetData } from "datas";
import React from "react";
import { useAppSelector } from "store";
import {
  selectExpenses,
  selectLastMonthExpense,
} from "store/reducers/TransactionReducer";
import categorizeTransactions from "utils/categorizeTransactions";
import GreenWall from "../../assets/images/green-wall.png";

const BudgetAnalysis = () => {
  const expenses = useAppSelector(selectExpenses);
  const lastMonthExpense = useAppSelector(selectLastMonthExpense);
  const categorizeData = categorizeTransactions(expenses);
  const lastMonthCategorizeData = categorizeTransactions(lastMonthExpense);

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "white",
        p: 4,
        borderRadius: 2,
        border: "1px solid #EAECF0",
      }}
    >
      <Typography variant="h5" fontWeight={600} color={"primary.700"} mb={4}>
        Budget Analysis
      </Typography>
      <Grid container spacing={6}>
        {mockBudgetData.map((item, index) => {
          const data = categorizeData[item?.category?.toLowerCase()];
          const dataLastMonth =
            lastMonthCategorizeData[item?.category?.toLowerCase()];
          const comparePercentage = +(
            data?.percentage - dataLastMonth?.percentage
          )?.toFixed(2);
          return (
            <Grid item xs={6} key={index}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: 0,
                  border: "1px solid #EAECF0",
                  height: 142,
                }}
              >
                <CardContent>
                  {/* Top Section: Title and Comparison */}
                  <Box display="flex" alignItems="center" mb={1}>
                    <Avatar src={GreenWall} alt="Green Wall" />
                    <Box ml={2}>
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        color={"primary.700"}
                      >
                        {item?.category}
                      </Typography>
                      <Box display="flex" alignItems="center" color="green">
                        {comparePercentage < 0 ? (
                          <ArrowDownRightIcon />
                        ) : (
                          <ArrowUpRightIcon />
                        )}
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color={"primary.500"}
                          noWrap
                        >
                          {+comparePercentage > 0
                            ? `+${comparePercentage}% compared with last month`
                            : `${comparePercentage}% compared with last month`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ my: 4 }} />

                  {/* Amount and Percentage */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={2}
                  >
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      color={"primary.600"}
                    >
                      ${data?.amount?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      color={"primary.600"}
                    >
                      {data?.percentage}%
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default BudgetAnalysis;
