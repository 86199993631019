import { Box, Paper, Typography } from "@mui/material";
import { mockBudgetData } from "datas";
import React from "react";
import BarChart from "./BarChart";
import { useAppSelector } from "store";
import { selectExpenses } from "store/reducers/TransactionReducer";
import categorizeTransactions from "utils/categorizeTransactions";

const RuleFiftyThirtyTwenty = () => {
  const expenses = useAppSelector(selectExpenses);
  const categorizeData = categorizeTransactions(expenses);
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "white",
        p: 4,
        borderRadius: 4,
        border: "1px solid #EAECF0",
        mt: 6,
      }}
    >
      <Typography variant="h5" fontWeight={600} color={"primary.700"} mb={4}>
        50 - 30 - 20 Rule
      </Typography>
      <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
        <BarChart
          data={mockBudgetData.map((item) => {
            const data = categorizeData[item?.category?.toLowerCase()];

            const mainCategoryPercentage =
              item?.category === "Needs"
                ? 50
                : item?.category === "Wants"
                ? 30
                : item?.category === "Savings"
                ? 20
                : 100;

            return mainCategoryPercentage > +data?.percentage
              ? mainCategoryPercentage - +data?.percentage
              : +data?.percentage;
          })}
          target={mockBudgetData.map((item) => {
            const data = categorizeData[item?.category?.toLowerCase()];
            const mainCategoryPercentage =
              item?.category === "Needs"
                ? 50
                : item?.category === "Wants"
                ? 30
                : item?.category === "Savings"
                ? 20
                : 100;

            return parseInt(
              mainCategoryPercentage > +data?.percentage ? data?.percentage : 0
            );
          })}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          minWidth={286}
        >
          {mockBudgetData?.map((item) => {
            const data = categorizeData[item?.category?.toLowerCase()];
            const mainCategoryPercentage =
              item?.category === "Needs"
                ? 50
                : item?.category === "Wants"
                ? 30
                : item?.category === "Savings"
                ? 20
                : 0;

            return (
              <Box>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Box
                    sx={{
                      width: 6,
                      height: 6,
                      borderRadius: 6,
                      backgroundColor: item?.color,
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    color={"primary.500"}
                  >
                    {item?.category?.toUpperCase()}
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <Typography variant="h4" color={"#242424"}>
                    {`${data?.percentage}% / ${mainCategoryPercentage}%`}
                  </Typography>
                  <Box
                    sx={{
                      width: 47,
                      height: 24,
                      borderRadius: 1.5,
                      backgroundColor: "primary.100",
                      px: 2.5,
                      py: 0.5,
                    }}
                  >
                    <Typography variant="h6" color={"primary.main"}>
                      {data?.percentage - mainCategoryPercentage}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
};

export default RuleFiftyThirtyTwenty;
