import axios from "axios";

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (res) => res,
  (err) => {
    const originalConfig = err.config;

    if (
      originalConfig &&
      originalConfig.url !== "/auth/signin" &&
      err.response
    ) {
      if (err.response.status === 401) {
        // LOGOUT OR REFRESH TOKEN CASE
      }
    }

    return Promise.reject(err);
  }
);

export default request;
