import React from "react";
import { Box, Typography } from "@mui/material";
import { SuccessIcon } from "assets/icons";
import { Button } from "components";

interface ISuccess {
  message: string;
  nextButtonText: string;
  onNext: () => void;
  onSkip?: () => void;
  secondBtn?: boolean;
}
const Success = ({
  message,
  nextButtonText,
  onNext,
  onSkip,
  secondBtn,
}: ISuccess) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={"100%"}
      height={"100%"}
      justifyItems={"center"}
      py={10}
    >
      <SuccessIcon />
      <Typography variant="h2" color={"primary.900"} sx={{ mt: 6 }}>
        Success
      </Typography>
      <Typography variant="h6" color={"primary.600"} sx={{ mt: 4 }}>
        {message}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onNext}
        sx={{ width: 386, height: 48, mt: 6 }}
        label={nextButtonText}
        size="large"
      />
      {secondBtn && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onSkip}
          sx={{ width: 386, height: 48, mt: 4, borderColor: "primary.main" }}
          label="Skip"
          size="large"
        />
      )}
    </Box>
  );
};
export default Success;
