import * as React from "react";
const HideEyeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.6 2.828a6.08 6.08 0 0 1 1.4-.16c4.667 0 7.334 5.333 7.334 5.333-.405.757-.888 1.47-1.44 2.127m-4.48-.713a2 2 0 1 1-2.827-2.827M.667.668l14.667 14.667M11.96 11.96A6.713 6.713 0 0 1 8 13.335C3.334 13.335.667 8 .667 8a12.3 12.3 0 0 1 3.373-3.96l7.92 7.92Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default HideEyeIcon;
