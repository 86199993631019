import { Box, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons";
import SpouseRegisterForm from "components/auth/SpouseRegisterForm";
import useModal from "hooks/useModal";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import { changeStatus } from "store/reducers/AuthReducer";
import { TSpouseRegisterSteps } from "types/types";
import {
  Address,
  Button,
  CenterModal,
  ChooseRole,
  ConnectAcoountModal,
  NewPasswordForm,
  Otp,
  SuccessPage,
  UserName,
} from "../../components";

const SpouseRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stepFromConnectAccount = location.state?.step;
  const [step, setStep] = useState<TSpouseRegisterSteps>(
    stepFromConnectAccount || "role"
  );
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useAppDispatch();
  const {
    isVisible: isConnectAccountModalOpen,
    onClose: closeConnectAccountModal,
    onOpen: openConnectAccountModal,
  } = useModal();
  const showFooterStepList = useMemo(() => ["role", "register", "otp"], []);

  const changeStep = useCallback((step: TSpouseRegisterSteps) => {
    setStep(step);
  }, []);

  const goBack = useCallback(() => {
    switch (step) {
      case "register":
        changeStep("role");
        break;
      case "otp":
        changeStep("register");
        break;
      case "username":
        changeStep("otp");
        break;
      case "address":
        changeStep("username");
        break;
      case "newPassword":
        changeStep("address");
        break;
      default:
        changeStep("register");
    }
  }, [step, changeStep]);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={step === "role" ? "center" : "flex-start"}
      >
        {step !== "role" &&
          step !== "successInfo" &&
          step !== "successRegister" && (
            <Button
              onClick={goBack}
              size="large"
              variant="outlined"
              sx={{ borderRadius: 2, gap: 2, mb: 12 }}
            >
              <ArrowLeftIcon />
              <Typography variant="h6" textTransform={"none"}>
                Back
              </Typography>
            </Button>
          )}
        {step !== "successInfo" && step !== "successRegister" && (
          <Typography color={"primary.900"} variant="h2" mb={20}>
            {step === "otp"
              ? "Email verification"
              : step === "username"
              ? "Username"
              : step === "address"
              ? "Address"
              : step === "newPassword"
              ? "Create password"
              : "Family Member Register"}
          </Typography>
        )}
        {step === "role" && (
          <ChooseRole
            nextStep={() => {
              changeStep("register");
            }}
          />
        )}
        {step === "register" && (
          <SpouseRegisterForm
            onNext={(data: string) => {
              changeStep("otp");
              setUserInfo(data);
            }}
          />
        )}
        {step === "otp" && (
          <Otp onNext={() => changeStep("successInfo")} source={userInfo} />
        )}
        {step === "successInfo" && (
          <SuccessPage
            onNext={() => changeStep("username")}
            message={"You have verified your email"}
            nextButtonText={"Continue"}
          />
        )}
        {step === "username" && (
          <UserName onNext={() => changeStep("address")} />
        )}
        {step === "address" && (
          <Address onNext={() => changeStep("newPassword")} />
        )}
        {step === "newPassword" && (
          <NewPasswordForm onNext={() => changeStep("successRegister")} />
        )}
        {step === "successRegister" && (
          <SuccessPage
            onNext={() => {
              openConnectAccountModal();
              // changeStep("invitation")
            }}
            message="You have verified your email"
            nextButtonText={"Connect MyPay"}
            onSkip={() => navigate("/login")}
            skipBtn
          />
        )}
      </Box>
      {showFooterStepList?.includes(step) && (
        <Typography
          width={"100%"}
          variant="h6"
          fontWeight={400}
          sx={{ mt: 2 }}
          textAlign={"center"}
        >
          Already have an account?
          <a
            href={"/login"}
            style={{
              color: "#0C425F",
              textDecoration: "underline",
              fontWeight: "bold",
              marginLeft: 4,
            }}
          >
            {"Log In"}
          </a>
        </Typography>
      )}
      <CenterModal
        sx={{ width: 463 }}
        open={isConnectAccountModalOpen}
        onClose={closeConnectAccountModal}
        blur
        close={false}
      >
        <ConnectAcoountModal
          onNext={() => {
            dispatch(changeStatus("active"));
            closeConnectAccountModal();
            navigate("/financial-statement");
            localStorage.removeItem("username");
          }}
        />
      </CenterModal>
    </Box>
  );
};

export default SpouseRegister;
