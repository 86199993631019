// utils/errorHandler.ts

import { AxiosError } from "axios";
import { showToast } from "./showToast";

export const handleError = (
  error: AxiosError<{
    message: string;
  }>
): string => {
  let message = "An unexpected error occurred.";

  if (error.response) {
    const status = error.response.status;
    message = error.response.data?.message || message;

    switch (status) {
      case 400:
        message = message || "Bad Request";
        break;
      case 401:
        message = message || "Unauthorized. Please check your credentials.";
        break;
      case 403:
        message =
          message ||
          "Forbidden. You don't have permission to access this resource.";
        break;
      case 404:
        message = message || "Resource not found.";
        break;
      case 500:
        message = "Internal Server Error. Please try again later.";
        break;
      default:
        break;
    }
  } else if (error.request) {
    message = "No response from server. Please check your network connection.";
  }

  // Show toast for the final message and return it
  showToast(message, "error");
  return message;
};
