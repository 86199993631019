import * as React from "react";
const ShieldIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={39}
      height={39}
      x={0.5}
      y={0.5}
      fill="#707070"
      fillOpacity={0.12}
      rx={5.5}
    />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#ADB2BB" rx={5.5} />
    <path
      stroke="#6D7584"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 30s8-4 8-10v-7l-8-3-8 3v7c0 6 8 10 8 10Z"
    />
  </svg>
);
export default ShieldIcon;
