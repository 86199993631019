import {
  ConnectAccount,
  Forgot,
  Login,
  Register,
  SpouseRegister,
} from "../pages";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { useAppSelector } from "store";
import { getStatus } from "store/reducers/AuthReducer";

const AuthRouter = () => {
  const status = useAppSelector(getStatus);

  return (
    <AuthLayout>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/connect-account"
          element={
            status === "connectAccount" ? (
              <ConnectAccount />
            ) : (
              <Navigate to="/register" replace />
            )
          }
        />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/invitation/*" element={<SpouseRegister />} />
      </Routes>
    </AuthLayout>
  );
};

export default AuthRouter;
