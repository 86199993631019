import { Theme } from "@emotion/react";
import { Box, SxProps } from "@mui/material";
import { CheckboxIcon } from "assets/icons";
import React from "react";

interface BasicCheckboxProps {
  checked: boolean;
  onChange?: () => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  fill?: string;
}
const BasicCheckbox = ({
  checked,
  onChange,
  disabled,
  sx,
  fill,
}: BasicCheckboxProps) => {
  return (
    <Box
      sx={{ border: `2px solid ${disabled ? "#D0D5DD" : "#101828"}`, ...sx }}
      minWidth={18}
      maxWidth={18}
      height={18}
      borderRadius={0.5}
      onClick={() => !disabled && onChange && onChange()}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {checked && (
        <CheckboxIcon fill={disabled ? "#D0D5DD" : fill ? fill : undefined} />
      )}
    </Box>
  );
};

export default BasicCheckbox;
