import * as React from "react";
const ShowEyeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M.667 8.001S3.334 2.668 8 2.668c4.667 0 7.334 5.333 7.334 5.333S12.667 13.335 8 13.335C3.334 13.335.667 8 .667 8Z" />
      <path d="M8 10.001a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ShowEyeIcon;
