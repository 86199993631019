import { Box, LinearProgress, Typography } from "@mui/material";
import {
  BasicCheckbox,
  Button,
  CommanderNotificationItem,
  DashboardHeader,
  EmptyFilteredNotification,
  EmptyNotification,
  Select,
  TabBar,
} from "components";
import { notificationStatusOptions } from "constants/common";
import { notificationaTabs } from "constants/tabs";
import { commanderNotificationsData } from "datas";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  getAllNotifications,
  updateNotificationStatus,
} from "store/actions/NotificationAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import {
  selectIsAllRead,
  selectNotifications,
} from "store/reducers/NotificationReducer";

const CommanderNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const notifications = useAppSelector(selectNotifications);
  const loading = useAppSelector(getIsLoading);
  const allRead = useAppSelector(selectIsAllRead);

  const dispatch = useAppDispatch();

  // Read initial values from URL params
  const [tabValue, setTabValue] = useState(
    parseInt(searchParams.get("tabValue")) || 0
  );
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState(searchParams.get("status") || "All");

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    setSearchParams({
      tabValue: String(newValue),
      status: String(status),
      step: String(step),
    });
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setSearchParams({
      tabValue: String(tabValue),
      status: String(status),
      step: String(step),
    });
  };

  // Sync state with URL params when component is mounted or URL changes
  useEffect(() => {
    const tabValueParam = parseInt(searchParams.get("tabValue"));
    const statusParam = searchParams.get("status");
    const stepParam = parseInt(searchParams.get("step"));

    if (tabValueParam !== tabValue) {
      setTabValue(tabValueParam || 0);
    }

    if (statusParam !== status) {
      setStatus(statusParam || "All");
    }
    if (stepParam !== step) {
      setStep(stepParam || 0);
    }
  }, [searchParams, tabValue, status, step]);

  const loadMoreNotifications = () => {
    setStep(step + 1);
  };
  const getAllNotificationsData = useCallback(
    async () =>
      dispatch(
        getAllNotifications({
          limit: 10,
          offset: step,
          saved: tabValue === 1 ? true : undefined,
          resolved: tabValue === 2 ? true : undefined,
          read:
            status === "Unread" ? false : status === "Read" ? true : undefined,
        })
      ),
    [dispatch, tabValue, step, status]
  );

  useEffect(() => {
    getAllNotificationsData();
  }, [dispatch, getAllNotificationsData]);
  return (
    <Box>
      <DashboardHeader
        goBack
        page="Notifications"
        // handleGoBack={() => navigate("")}
      />
      {loading ? (
        <LinearProgress />
      ) : commanderNotificationsData?.length === 0 ? (
        <EmptyNotification onRetry={() => {}} />
      ) : (
        <Box px={10} py={8}>
          <TabBar
            value={tabValue}
            onChange={handleTabChange}
            tabs={notificationaTabs}
            containerSx={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            selectedColor={"primary.main"}
          />
          <Box
            my={6}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <Select
              label="Status"
              options={notificationStatusOptions}
              value={status}
              onChange={handleStatusChange}
              sx={{ width: 252, height: 44 }}
            />
            <Button
              onClick={() => {
                //change all notification status to read
                notifications?.forEach((item) => {
                  dispatch(
                    updateNotificationStatus({
                      notificationId: item.id,
                      statusData: {
                        read: !allRead,
                      },
                    })
                  );
                });
              }}
              sx={{ height: 44 }}
              variant={!allRead ? "outlined" : "contained"}
            >
              <BasicCheckbox
                checked={true}
                sx={{
                  height: 18,
                  borderRadius: 1,
                  borderWidth: 1.5,
                  borderColor: allRead && "white",
                }}
                fill={allRead && "white"}
              />
              <Typography ml={2}>
                Mark All as {allRead ? "Unread" : "Read"}
              </Typography>
            </Button>
          </Box>

          {notifications?.length === 0 ? (
            <EmptyFilteredNotification title={status?.toLowerCase()} />
          ) : (
            notifications?.map((notification, index) => (
              <CommanderNotificationItem
                key={index}
                {...notification}
                tabValue={tabValue}
              />
            ))
          )}
          {step < Math.floor(notifications?.length / 7) && (
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <Button
                onClick={loadMoreNotifications}
                sx={{ width: 312, height: 40, mt: 8 }}
                variant="contained"
                label="Load More"
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CommanderNotifications;
