import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store";
import { getRole } from "store/reducers/AuthReducer";
import {
  Assets,
  AverageFinancialReadinessScore,
  BasicBudgetAnalysis,
  Calculate,
  Calculator,
  ClientNotifications,
  Clients,
  CommanderNotifications,
  Commanders,
  Education,
  Expenses,
  FamilyMember,
  FinancialHealthScore,
  FinancialReadlinesStatistics,
  FinancialStatements,
  GoalDetails,
  Goals,
  Incomes,
  Liabilities,
  NotificationSettings,
  ScoreForUnit,
  UnitRosters,
  Units,
  UnitScore,
  UserDetails,
} from "../pages";
import AppLayout from "./AppLayout";
import Rosters from "pages/admin/Rosters";

const AppRouter = () => {
  const role = useAppSelector(getRole);

  return (
    <AppLayout>
      <Routes>
        <Route
          path="/"
          element={
            role === "Client" || role.includes("Family") ? (
              <FinancialStatements />
            ) : role === "Super Admin" ? (
              <Clients />
            ) : (
              <UnitScore />
            )
          }
        />

        {/* Client */}
        <Route path="/financial-statement">
          <Route index element={<FinancialStatements />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="incomes" element={<Incomes />} />
          <Route path="assets" element={<Assets />} />
          <Route path="liabilities" element={<Liabilities />} />
          <Route
            path="basic-budget-analysis"
            element={<BasicBudgetAnalysis />}
          />
          <Route
            path="financial-health-score"
            element={<FinancialHealthScore />}
          />
        </Route>
        <Route path="expenses" element={<Expenses />} />
        <Route path="incomes" element={<Incomes />} />
        <Route path="assets" element={<Assets />} />
        <Route path="liabilities" element={<Liabilities />} />
        <Route path="basic-budget-analysis" element={<BasicBudgetAnalysis />} />
        <Route
          path="financial-health-score"
          element={<FinancialHealthScore />}
        />
        <Route path="family-member" element={<FamilyMember />} />
        <Route path="education" element={<Education />} />
        <Route path="goals" element={<Goals />} />
        <Route path="goals/:id" element={<GoalDetails />} />
        <Route path="/notifications">
          <Route
            index
            element={
              role === "Client" || role === "FamilyMember" ? (
                <ClientNotifications />
              ) : (
                <CommanderNotifications />
              )
            }
          />
          <Route path="settings" element={<NotificationSettings />} />
        </Route>
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/calculator/:name" element={<Calculate />} />

        {/* Commander */}
        <Route path="/unit-score" element={<UnitScore />} />
        <Route path="/unit-rosters" element={<UnitRosters />} />
        <Route
          path="/unit-score/financial-readlines-statistics"
          element={<FinancialReadlinesStatistics />}
        />
        <Route
          path="/unit-score/average-financial-readiness-score"
          element={<AverageFinancialReadinessScore />}
        />
        <Route path="/unit-score/score-for-unit" element={<ScoreForUnit />} />

        {/* Admin */}
        <Route path="/clients" element={<Clients />} />
        <Route path="/clients/:id" element={<UserDetails />} />
        <Route path="/commanders" element={<Commanders />} />
        <Route path="/commanders/:id" element={<UserDetails />} />
        <Route path="/units" element={<Units />} />
        <Route path="/units/:id/rosters" element={<Rosters />} />
      </Routes>
    </AppLayout>
  );
};

export default AppRouter;
