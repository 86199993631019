import { useCallback, useEffect, useState } from "react";

const REQUEST_CODE_PAUSE_TIME = 30;

export const useTimer = (time: number = REQUEST_CODE_PAUSE_TIME) => {
  const [timeLeft, setTimeLeft] = useState<number>(time); // Explicitly specify the type for timeLeft

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined; // Define the timer variable with the correct type
    if (timeLeft > 0) {
      timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timeLeft]);

  const startTimer = useCallback(() => {
    setTimeLeft(time);
  }, [time]);

  return { timeLeft: timeLeft, startTimer };
};
