import React, { forwardRef, useMemo, useState } from "react";
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import { Box, Typography, IconButton, InputAdornment } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import { HideEyeIcon, SearchIcon, ShowEyeIcon } from "../../assets/icons";
import Button from "./Button";
import typography from "theme/typography";
import { Theme } from "@emotion/react";

interface CustomInputProps extends Omit<OutlinedTextFieldProps, "variant"> {
  label?: string;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  variant?: "outlined" | "filled" | "standard";
  search?: boolean;
  readonly?: boolean;
}

const Input = forwardRef<HTMLInputElement, CustomInputProps>(
  (
    {
      label,
      sx,
      labelSx,
      variant = "outlined",
      type = "text",
      search,
      readonly = false,
      ...rest
    },
    ref
  ) => {
    const StyledTextField = useMemo(
      () =>
        styled(TextField)(({ theme }) => ({
          "& .MuiInputBase-root": {
            fontSize: "14px",
            fontWeight: 400,
            color: "#101828",
          },
          "& .MuiInputBase-input": {
            padding: "12px 14px",
            "&::placeholder": {
              ...typography.h6,
              color: theme.palette.primary.main,
            },
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            height: 48,
            "& fieldset": {
              borderColor: "#D0D5DD",
            },
            "&:hover fieldset": {
              borderColor: "#D0D5DD",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#D0D5DD",
            },
          },
          "& .MuiFormHelperText-root": {
            fontSize: "14px",
            marginLeft: 0,
            marginTop: "2px",
          },
        })),
      []
    );

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <Box sx={sx}>
        {label && (
          <Typography variant="h6" fontWeight={500} mb={1} sx={labelSx}>
            {label}
          </Typography>
        )}
        <Box display={"flex"}>
          <StyledTextField
            fullWidth
            variant={variant}
            type={
              type === "password" && showPassword
                ? "text"
                : type === "password"
                ? "password"
                : type
            }
            InputProps={{
              readOnly: readonly, // Set readOnly here
              endAdornment:
                (type === "password" && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <HideEyeIcon /> : <ShowEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                )) ||
                (search && (
                  <Button
                    variant="text"
                    sx={{
                      width: 46,
                      height: 48,
                      bgcolor: "transparent",
                      borderLeftWidth: 1,
                      borderLeftStyle: "solid",
                      borderLeftColor: "primary.50",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <SearchIcon />
                  </Button>
                )),
              sx: {
                paddingRight: search && 0,
              },
              ...rest?.InputProps,
            }}
            {...rest}
            ref={ref}
          />
        </Box>
      </Box>
    );
  }
);

export default Input;
