import * as React from "react";
const HeartIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={47} height={47} x={0.5} y={0.5} fill="#FEF3F2" rx={5.5} />
    <rect width={47} height={47} x={0.5} y={0.5} stroke="#FDA29B" rx={5.5} />
    <path
      stroke="#D92D20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M32.838 16.61a5.5 5.5 0 0 0-7.78 0l-1.06 1.06-1.06-1.06a5.501 5.501 0 0 0-7.78 7.78l1.06 1.06 7.78 7.78 7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78v0Z"
    />
  </svg>
);
export default HeartIcon;
