import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { SmallArrowRightIcon } from "assets/icons";
import { Button, DashboardHeader } from "components";
import { calculatorData } from "datas";
import React from "react";
import { useNavigate } from "react-router-dom";

const Calculator = () => {
  const navigate = useNavigate();
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      flex={1}
    >
      <DashboardHeader page="Calculator" />
      <Grid container spacing={6} mt={6} pb={6} px={10}>
        {calculatorData?.map((item, index) => (
          <Grid item xs={3} key={index}>
            <Card variant="outlined" sx={{ height: 244 }}>
              <CardContent>
                {<item.icon />}
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color={"primary.700"}
                  mt={5}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.500"}
                  mt={3}
                >
                  {item.description}
                </Typography>
                <Button
                  onClick={() =>
                    item?.title === "Car Payment"
                      ? navigate("/calculator/" + item.title)
                      : navigate("/calculator/")
                  }
                  variant="text"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 0,
                    mt: 6,
                  }}
                  disabledHoverColor
                >
                  <Typography
                    mr={2}
                    variant="h6"
                    fontWeight={600}
                    color="primary"
                  >
                    Calculate
                  </Typography>
                  <SmallArrowRightIcon />
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Calculator;
