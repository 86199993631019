import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import { Button, CustomDatePicker, Input, Select } from "components";
import { expenseCategories } from "datas/select";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { createExpense } from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { showToast } from "utils/showToast";
import { addExpenseSchema } from "validation/financialStatementValidation";

const AddExpenceForm = ({ handleClose,onSuccess }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(addExpenseSchema),

    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const response = await dispatch(createExpense(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      showToast("Expense added successfully", "success");
      onSuccess()
      handleClose();
    }
  };

  return (
    <FormControl
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%", gap: 6 }}
    >
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Title"
            placeholder="e.g., Salary"
            fullWidth
            error={!!errors.title}
            helperText={errors.title?.message}
            InputProps={{ style: { height: 40 } }}
          />
        )}
      />

      <Controller
        name="amount"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Amount"
            placeholder="e.g., $1,000.00"
            type="number"
            fullWidth
            error={!!errors.amount}
            helperText={errors.amount?.message}
            InputProps={{ style: { height: 40 } }}
          />
        )}
      />

      {/* <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <Select
            label="Status"
            placeholder="Select Status"
            {...field}
            options={[
              { value: "Completed", label: "Completed" },
              { value: "Pending", label: "Pending" },
            ]}
            sx={{
              width: "100%",
              height: 40,
              borderColor: "red",
              fontWeight: 400,
              color: "primary.500",
            }}
          />
        )}
      /> */}
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <Select
            label="Category"
            placeholder="Enter category"
            {...field}
            options={expenseCategories}
            sx={{
              width: "100%",
              height: 40,
              borderColor: "red",
              fontWeight: 400,
              color: "primary.500",
            }}
          />
        )}
      />

      <Controller
        name="lastUpdated"
        control={control}
        render={({ field }) => (
          <CustomDatePicker
            selectedDate={
              dayjs(watch("lastUpdated")) as Dayjs | null | undefined
            }
            onChange={field.onChange}
            format="DD MMM YYYY HH:mm"
            label="Date and Time"
            referenceDate={dayjs("2022-04-17T15:30")}
            time
          />
        )}
      />

      <Button
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: 4 }}
        disabled={!isDirty || !isValid}
        loading={loading}
        onClick={handleSubmit(onSubmit)}
      >
        Continue
      </Button>
    </FormControl>
  );
};

export default AddExpenceForm;
