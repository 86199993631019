import { Box, Grid, LinearProgress } from "@mui/material";
import { Empty, Select, StatusDistribution } from "components";
import DateRangeSelector from "components/UnitRoster/DateRangeSelector";
import { periodOptions } from "constants/common";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  getAllUnitsByCommanderId,
  getFinancialReportByUnitId,
} from "store/actions/UnitsAction";
import { getUser } from "store/reducers/AuthReducer";
import { selectFinancialReport } from "store/reducers/UnitReducer";
import StatusCard from "./StatusCard";
import { getIsLoading } from "store/reducers/LoadingReducer";

const FinancialStatusDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize `unitId` from searchParams
  const [selectedUnit, setSelectedUnit] = useState(
    searchParams.get("unitId") || null
  );

  const [selectedPeriod, setSelectedPeriod] = useState(
    searchParams.get("period") || periodOptions[0].value
  ); // Local state for period
  const [currentDate, setCurrentDate] = useState(dayjs()); // Local state for date
  const [units, setUnits] = useState([]);

  const financiatReport = useAppSelector(selectFinancialReport);
  const user = useAppSelector(getUser);
  const loading = useAppSelector(getIsLoading);

  const dispatch = useAppDispatch();

  // Fetch the financial report based on the selected unit
  const getFinancialReport = useCallback(
    async (unitId) => {
      if (unitId) {
        await dispatch(
          getFinancialReportByUnitId({
            unitId,
            period: selectedPeriod,
            dateRange: currentDate.format("MMMM, YYYY"),
          })
        );
      }
    },
    [dispatch, selectedPeriod, currentDate]
  );

  // Fetch all units for the logged-in user
  const getAllUnits = useCallback(async () => {
    const response = await dispatch(getAllUnitsByCommanderId(user?.data?.id));
    if (response?.meta?.requestStatus === "fulfilled") {
      setUnits(response?.payload);
      if (!selectedUnit) {
        const firstUnitId = response?.payload[0]?.id;
        setSelectedUnit(firstUnitId);
        setSearchParams({ unitId: firstUnitId, period: selectedPeriod });
        getFinancialReport(firstUnitId);
      }
    }
  }, [
    dispatch,
    user?.data?.id,
    selectedUnit,
    getFinancialReport,
    setSearchParams,
    selectedPeriod,
  ]);

  useEffect(() => {
    getAllUnits();
  }, [getAllUnits]);

  // Handle when the unit is changed
  const handleUnitChange = (e) => {
    const unitId = e.target.value;
    setSelectedUnit(unitId);
    setSearchParams({
      unitId: unitId,
      period: selectedPeriod,
    }); // Update the URL with the selected unitId
    getFinancialReport(unitId); // Fetch the new financial report based on the new unitId
  };

  // Handle period change locally
  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
    setSearchParams({
      unitId: selectedUnit,
      period: e.target.value,
    });
  };

  // Handle date change locally
  const handleDateChange = (newDate) => {
    setCurrentDate(newDate);
  };

  // Re-fetch financial report when the selected unit, period, or date changes
  useEffect(() => {
    if (selectedUnit) {
      getFinancialReport(selectedUnit);
    }
  }, [selectedUnit, selectedPeriod, currentDate, getFinancialReport]);

  if (loading) return <LinearProgress />;

  return (
    <Box>
      <Box mt={8} display={"flex"} gap={6}>
        {/* Unit Selector */}
        <Select
          options={units.map((unit) => ({ label: unit.name, value: unit.id }))}
          label="Unit"
          value={
            units?.find((item) => item?.id === selectedUnit)?.name ||
            units?.[0]?.name
          }
          onChange={handleUnitChange}
          sx={{ width: 252, height: 44 }}
        />

        {/* Period Selector (Local State) */}
        <Select
          options={periodOptions}
          label="Period"
          value={selectedPeriod}
          onChange={handlePeriodChange}
          sx={{ width: 252, height: 44 }}
        />

        {/* Date Range Selector (Local State) */}
        <DateRangeSelector
          currentDate={currentDate}
          setCurrentDate={handleDateChange}
        />
      </Box>

      {/* Dashboard Content */}
      <Grid
        container
        spacing={4}
        minHeight={300}
        sx={{ backgroundColor: "white", mt: 8, p: 4 }}
      >
        {financiatReport === null ? (
          <Empty />
        ) : (
          <>
            <Grid item xs={3}>
              <StatusCard
                title={"Average Financial Readiness Score"}
                score={financiatReport?.averageFinancialReadinessScore || 0}
              />
            </Grid>
            <Grid item xs={3}>
              <StatusCard
                title={"Median Financial Readiness Score"}
                score={financiatReport?.medianFinancialReadinessScore || 0}
              />
            </Grid>
            <Grid item xs={3}>
              <StatusDistribution
                title={"Distribution of Financial Readiness"}
                {...financiatReport?.financialReadinessDistribution}
              />
            </Grid>
            <Grid item xs={3}>
              <StatusCard
                title={"Size of Standard Deviation"}
                score={financiatReport?.standardDeviation || 0}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default FinancialStatusDashboard;
