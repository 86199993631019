import * as React from "react";
const PocketIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#EFF8FF" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#84CAFF" rx={5.5} />
    <path
      stroke="#175CD3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m13.336 14.667 2.667 2.666 2.666-2.666m-8-4.667h10.667a1.333 1.333 0 0 1 1.333 1.333v4a6.667 6.667 0 0 1-13.333 0v-4A1.333 1.333 0 0 1 10.669 10Z"
    />
  </svg>
);
export default PocketIcon;
