import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  deleteFamilyMember,
  getFamilyMembers,
} from "store/actions/SpouseAction";

const familySlice = createSlice({
  name: "family",
  initialState: {
    familyMembers: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFamilyMembers.fulfilled, (state, { payload }) => {
        state.familyMembers = payload;
      })
      .addCase(deleteFamilyMember.fulfilled, (state, { payload }) => {
        state.familyMembers = state.familyMembers.filter(
          (member) => member.id !== payload
        );
      });
  },
});

const selectFamilyMemberState = (state: RootState) => state.familyMember;
export const getFamilyMembersList = createSelector(
  selectFamilyMemberState,
  (state) => state?.familyMembers
);

export default familySlice.reducer;
