import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import React from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { forgotOtpSend } from "store/actions/AuthAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { Button, Input } from "../";
import { forgotSchema } from "../../validation/authValidation";
interface IForgotForm {
  onNext: (data: string) => void;
}
interface LoginFormInputs {
  email: string;
}
const ForgotForm = ({ onNext }: IForgotForm) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(forgotSchema) as Resolver<LoginFormInputs>,
    mode: "onChange",
  });

  const onSubmit = async (data: LoginFormInputs) => {
    const response = await dispatch(forgotOtpSend(data));

    if (response?.meta?.requestStatus === "fulfilled") {
      onNext(data.email);
    }
  };
  return (
    <Box>
      <FormControl
        fullWidth
        sx={{ gap: 6, mb: 1 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              required
              label="Enter email"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...field}
            />
          )}
        />
      </FormControl>
      <Button
        loading={loading}
        onClick={handleSubmit(onSubmit)}
        type="submit"
        disabled={!isDirty || !isValid}
        variant="contained"
        fullWidth
        size="large"
        label="Continue"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default ForgotForm;
