import { TFinancialStatus } from "types/types";

const getFinancialStatusColorWithHex = (status: TFinancialStatus) => {
  switch (status) {
    case "Ready":
      return "#32D583";
    case "Marginally Ready":
      return "#FDB022";
    case "Not Ready":
      return "#F97066";
    case "Good":
      return "#557B8F";
    default:
      return "grey";
  }
};
export default getFinancialStatusColorWithHex;
