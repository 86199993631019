import { CircularProgress, FormControl } from "@mui/material";
import { Button, CenterModal, Input } from "components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "store";
import { createUnit, updateUnitName } from "store/actions/AdminAction";
import { showToast } from "utils/showToast";

const CreateUnitModal = ({ open, handleClose }: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const isEdit = open?.id;

  useEffect(() => {
    reset();
  }, [isEdit, reset]);

  const onSubmit = async (data) => {
    setLoading(true);
    //if isEdit is true, we need to update the unit
    let response;
    if (isEdit) {
      response = await dispatch(
        updateUnitName({ id: open?.id, name: data.name })
      );
    } else {
      response = await dispatch(createUnit(data));
    }
    if (response?.meta?.requestStatus === "fulfilled") {
      showToast(
        isEdit ? "Unit updated successfully" : "Unit created successfully",
        "success"
      );
      handleClose();
      reset();
    }
    setLoading(false);
  };

  return (
    <CenterModal
      open={open}
      onClose={() => {
        handleClose();
      }}
      blur
      sx={{ width: 480, height: "auto" }}
      close={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ gap: 6, mb: 1 }}>
          <Controller
            name="name"
            control={control}
            defaultValue={open?.name || ""}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <Input
                required
                label="Name"
                placeholder="Enter unit name"
                error={!!errors.name}
                helperText={errors.name?.message as string}
                fullWidth
                {...field}
              />
            )}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          sx={{ mt: 4, height: 48 }}
          disabled={!isDirty || !isValid || loading}
        >
          {loading ? <CircularProgress size={24} /> : isEdit ? "Update" : "Add"}
        </Button>
      </form>
    </CenterModal>
  );
};

export default CreateUnitModal;
