import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import dayjs, { Dayjs } from "dayjs";
import { TAmountFilterType, TransactionPeriodStatus } from "types/types";
import { handleError } from "utils/handleError";
import selectCustomDateFromNow from "utils/selectCustomDateFromNow";

export const createIncome = createAsyncThunk(
  "income/create",
  async (data: {}, { rejectWithValue }) => {
    try {
      const response = await request.post("/income", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
//get income
interface GetAllParams {
  page?: number;
  limit?: number;
}
interface GetTransactionsParams {
  page?: number;
  limit?: number;
  startDate?: string | Dayjs;
  endDate?: string | Dayjs;
  categoriesFilter?: string;
  amountFilterType?: TAmountFilterType;
  singleValue?: number;
  rangeValue?: [number, number] | string;
}

export const createExpense = createAsyncThunk(
  "expense/create",
  async (
    data: {
      amount: number;
      description: string;
      category: string;
      date: string | Dayjs;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("/expense", {
        ...data,
        amount: data?.amount <= 0 ? data?.amount : -data?.amount,
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
//get transactions

export const getTransactions = createAsyncThunk(
  "financialStatement/getTransactions",
  async (
    {
      page = 1,
      limit = 10,
      startDate = selectCustomDateFromNow(-20),
      endDate = selectCustomDateFromNow(1),
      categoriesFilter,
      amountFilterType,
      singleValue,
      rangeValue,
    }: GetTransactionsParams,
    { rejectWithValue }
  ) => {
    try {
      let upperAmountFilterType = amountFilterType
        ? (amountFilterType
            ?.split(" ")?.[0]
            ?.toUpperCase() as TAmountFilterType)
        : undefined;
      const params: GetTransactionsParams = {
        page,
        limit,
        startDate: startDate && dayjs(startDate)?.format("YYYY-MM-DD"),
        endDate: endDate && dayjs(endDate)?.format("YYYY-MM-DD"),
        categoriesFilter: categoriesFilter?.includes("Business Services")
          ? "BusinessServices"
          : categoriesFilter?.replace(/[^a-zA-Z0-9]/g, ""),
        amountFilterType: upperAmountFilterType,
      };

      // Conditionally add singleValue or rangeValue based on the amountFilterType
      if (
        upperAmountFilterType === "EQUAL" ||
        upperAmountFilterType === "MORE" ||
        upperAmountFilterType === "LESS"
      ) {
        params.singleValue = singleValue;
      } else if (upperAmountFilterType === "BETWEEN" && rangeValue) {
        params.rangeValue = `${rangeValue[0]},${rangeValue[1]}`;
      }

      const response = await request.get("/fiserv/user/transactions/all", {
        params,
      });

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const getTransactionsLastMonth = createAsyncThunk(
  "financialStatement/getTransactionsLastMonth",
  async (
    {
      page = 1,
      limit = 10,
      startDate = selectCustomDateFromNow(-7),
      endDate = selectCustomDateFromNow(1),
    }: GetTransactionsParams,
    { rejectWithValue }
  ) => {
    try {
      const adjustedStartDate = dayjs(startDate)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      const adjustedEndDate = dayjs(endDate)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      const response = await request.get("/fiserv/user/transactions/all", {
        params: {
          page,
          limit,
          startDate: adjustedStartDate,
          endDate: adjustedEndDate,
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
export const getTransactionsAnalytics = createAsyncThunk(
  "financialStatement/getTransactionsAnalytics",
  async (
    {
      datePeriod,
    }: {
      datePeriod: TransactionPeriodStatus;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get(
        "/fiserv/user/transactions/analytics",
        {
          params: {
            datePeriod,
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const createAsset = createAsyncThunk(
  "asset/create",
  async (data: {}, { rejectWithValue }) => {
    try {
      const response = await request.post("/asset", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

//get assets with limit page
export const getAssets = createAsyncThunk(
  "asset/getAll",
  async (
    {
      page = 1,
      limit = 10,
      startDate,
      endDate,
      categoriesFilter,
      amountFilterType,
      singleValue,
      rangeValue,
    }: GetTransactionsParams,
    { rejectWithValue }
  ) => {
    try {
      let upperAmountFilterType = amountFilterType
        ? (amountFilterType
            ?.split(" ")?.[0]
            ?.toUpperCase() as TAmountFilterType)
        : undefined;
      let categoryMain = categoriesFilter
        ? categoriesFilter?.replace(/[^a-zA-Z0-9]/g, "")
        : null;
      const params: GetTransactionsParams = {
        page,
        limit,
        startDate: startDate && dayjs(startDate)?.format("YYYY-MM-DD"),
        endDate: endDate && dayjs(endDate)?.format("YYYY-MM-DD"),
        categoriesFilter: categoryMain
          ? categoryMain?.charAt(0).toLowerCase() + categoryMain?.slice(1)
          : undefined,
        amountFilterType: upperAmountFilterType,
      };

      // Conditionally add singleValue or rangeValue based on the amountFilterType
      if (
        upperAmountFilterType === "EQUAL" ||
        upperAmountFilterType === "MORE" ||
        upperAmountFilterType === "LESS"
      ) {
        params.singleValue = singleValue;
      } else if (upperAmountFilterType === "BETWEEN" && rangeValue) {
        params.rangeValue = `${rangeValue[0]},${rangeValue[1]}`;
      }
      const response = await request.get("/fiserv/user/accounts/asset", {
        params,
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const createLiability = createAsyncThunk(
  "liability/create",
  async (data: {}, { rejectWithValue }) => {
    try {
      const response = await request.post("/liabilities", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
//get liabilities with limit page
export const getLiabilities = createAsyncThunk(
  "liability/getAll",
  async ({ page = 1, limit = 10 }: GetAllParams, { rejectWithValue }) => {
    try {
      const response = await request.get("/fiserv/user/accounts/liabilities", {
        params: {
          page: page || 1,
          limit: limit || 8,
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
export const getScoreMe = createAsyncThunk(
  "financial/getScoreMe",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get("/scores/me/calculate");
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
// Define the updateExpense action
export const updateExpense = createAsyncThunk(
  "transactions/updateExpense",
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: {
        amount?: number;
        description?: string;
        category: string;
        date?: string | Dayjs;
        title?: string;
        lastUpdated?: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.put(`/expense/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get account diagram
export const getAccountDiagram = createAsyncThunk(
  "account/getAccountDiagram",
  async (accountId: string, { rejectWithValue }) => {
    try {
      const response = await request.get(
        `/fiserv/account/diagram/${accountId}`
      );
      return response.data;
    } catch (error) {
      // const errorMessage = handleError(error);
      // return rejectWithValue(errorMessage);
    }
  }
);
