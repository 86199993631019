import { Box, Typography } from "@mui/material";
import { SearchOffIcon } from "assets/icons";
import React from "react";

const EmptyClientNotification = () => {
  return (
    <Box
      bgcolor={"white"}
      p={4}
      borderRadius={2}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      height={240}
    >
      <SearchOffIcon />
      <Typography mt={6} variant="h3" fontWeight={700} color="primary.main">
        No notifications at the moment.
      </Typography>
      <Typography mt={4} variant="body1" fontWeight={400} color="primary.800">
        Check back later for new notifications.
      </Typography>
    </Box>
  );
};

export default EmptyClientNotification;
