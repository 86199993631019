import {
  BookIcon,
  CoinsStackIcon,
  CompassIcon,
  CreditCardIcon,
  FinanceIcon,
  HeartIcon,
  LayersIcon,
  OpenBookIcon,
  PocketIcon,
  RepeatIcon,
  SmallCoinIcon,
  SmallMaximizeIcon,
  TrackIcon,
  ZapIcon,
} from "assets/icons";
import colors from "theme/colors";
import { StatusScoreCardProps } from "types/types";
import CounselorImg from "../assets/images/green-wall.png";
import SummaryImg1 from "../assets/images/summaryImg1.png";
import SummaryImg2 from "../assets/images/summaryImg2.png";
import TeacherPng from "../assets/images/teacher.png";
import BusinessPng from "../assets/images/business.png";
import MoneyPng from "../assets/images/money.png";
import CouplePng from "../assets/images/couple.png";

export const expensesCategoriesData = [
  {
    id: 1,
    title: "Joe Collins",
    category: "Transfer to a card",
    date: "12 Jul 2023, 08:50 PM",
    status: "Completed",
    amount: 4623,
    transactionCount: 20,
    percentage: "30%",
  },
  {
    id: 2,
    title: "Joe Collins",
    category: "Grocery",
    date: "12 Jul 2023, 08:50 PM",
    status: "Rejected",
    amount: -502,
    transactionCount: 30,
    percentage: "30%",
  },
  {
    id: 3,
    title: "Joe Collins",
    category: "Restaurants",
    date: "12 Jul 2023, 08:50 PM",
    status: "Completed",
    amount: 4623,
    transactionCount: 15,
    percentage: "20%",
  },
  {
    id: 4,
    title: "Joe Collins",
    category: "Entertainment",
    date: "12 Jul 2023, 08:50 PM",
    status: "Completed",
    amount: 4623,
    transactionCount: 11,
    percentage: "10%",
  },
  {
    id: 5,
    title: "Joe Collins",
    category: "Other",
    date: "12 Jul 2023, 08:50 PM",
    status: "Completed",
    amount: 4623,
    transactionCount: 11,
    percentage: "10%",
  },
  {
    id: 6,
    title: "Joe Collins",
    category: "Transfer to a card",
    date: "12 Jul 2023, 08:50 PM",
    status: "Completed",
    amount: 4623,
    transactionCount: 11,
    percentage: "10%",
  },
  {
    id: 7,
    title: "Joe Collins",
    category: "Transfer to a card",
    date: "12 Jul 2023, 08:50 PM",
    status: "Completed",
    amount: 4623,
    transactionCount: 11,
    percentage: "10%",
  },
];

export const overallScoreData = [
  { label: "Readiness Levels", goalColor: "#B692F6" },
  {
    label: "Numbers of Individuals",
    goalColor: "#0086C9",
    target: 100,
    done: 40,
  },
  { label: "Report Levels" },
  { label: "Suffered Individuals" },
];
export const overallScoreDataForScoreTheUnit = [
  { label: "Present Financial Security", value: 68, color: "#B692F6" },
  {
    label: "Present Freedom of Choice",
    value: 68,
    color: "#B692F6",
  },
  {
    label: "Future Financial Security",
    value: 68,
    color: "#B692F6",
  },
  {
    label: "Future Freedom of Choice",
    value: 68,
    color: "#B692F6",
  },
];

// export const financialStatusData = [
//   {
//     title: "Average Financial Readiness Score",
//     score: 123,
//     statusName: "Average status",
//     status: "Ready",
//     path: "average-financial-readiness-score",
//   },
//   {
//     title: "Median Financial Readiness Score",
//     score: 123,
//     statusName: "Median status",
//     status: "Marginally Ready",
//     path: "median-financial-readiness-score",
//   },
//   {
//     title: "Distribution of Financial Readiness",
//     component: StatusDistribution,
//     path: "distribution-of-financial-readiness",
//   },
//   {
//     title: "Size of Standard Deviation",
//     score: 123,
//     path: "size-of-standard-deviation",
//   },
// ];
export const scoreDashboardData: StatusScoreCardProps[] = [
  {
    path: "score-for-Unit",
    title: "Score for the Unit",
    status: "Not Ready",
    details: "There are aspects that should be improved",
  },
  {
    path: "financial-readlines-statistics",
    title: "Financial Readlines Statistics",
    status: "Ready",
    details: "Keep going! There are aspects that could be improved",
  },
];
export const tasks = [
  {
    label: "Lorem ipsum dolor",
    description:
      "libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ",
    priority: 2,
  },
  {
    label: "Lorem ipsum dolor",
    description:
      "libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ",
    priority: 1,
  },
  {
    label: "Lorem ipsum dolor",
    description:
      "libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ",
    priority: 1,
  },
  {
    label: "Lorem ipsum dolor",
    description:
      "libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ",
    priority: 0.5,
  },
  {
    label: "Lorem ipsum dolor",
    description:
      "libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ",
    priority: 2,
  },
];

export const goalsData = [
  {
    id: 4,
    name: "Car",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 100,
    done: "$13,600.00",
    target: "$20,000.00",
    color: "#9E77ED",
    personal: false,
    isComplete: true,
  },
  {
    id: 5,
    name: "Goal name",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 100,
    done: "$13,600.00",
    target: "$20,000.00",
    color: "#0086C9",
    isComplete: true,
  },
  {
    id: 6,
    name: "Goal name",
    progress: 100,
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    done: "$13,600.00",
    target: "$20,000.00",
    color: colors.primary[600],
    isComplete: true,
  },
  {
    id: 7,
    name: "Goal name",
    progress: 100,
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    done: "$13,600.00",
    target: "$20,000.00",
    color: "#FD6F8E",
    isComplete: true,
  },
  {
    id: 8,
    name: "Car",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 100,
    done: "$13,600.00",
    target: "$20,000.00",
    color: "#B692F6",
    isComplete: true,
  },
  {
    id: 9,
    name: "Goal name",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 100,
    done: "$13,600.00",
    target: "$20,000.00",
    color: "#3E4784",
    isComplete: true,
  },
  {
    id: 10,
    name: "Goal name",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 100,
    done: "$13,600.00",
    target: "$20,000.00",
    color: colors.warning[400],
    isComplete: true,
  },
  {
    id: 11,
    name: "Goal name",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 100,
    done: "$13,600.00",
    target: "$20,000.00",
    color: colors.success[500],
    isComplete: true,
  },
  {
    id: 1,
    name: "Car",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 68,
    done: "$13,600.00",
    target: "$20,000.00",
    color: colors.success[600],
    personal: true,
    isComplete: false,
  },
  {
    id: 2,
    name: "Goal name",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 48,
    done: "$13,600.00",
    target: "$20,000.00",
    color: colors.warning[500],
    personal: true,
    isComplete: false,
  },
  {
    id: 3,
    name: "Goal name",
    description: "I decided to buy a new car",
    date: new Date(),
    file: CounselorImg,
    progress: 87,
    done: "$13,600.00",
    target: "$20,000.00",
    color: colors.secondary[50],
    personal: true,
    isComplete: false,
  },
];

export const incomeItems = [
  {
    image: CounselorImg,
    name: "Joe Collins",
    category: "Transfer to a card",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Netflix",
    category: "Entertainment",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Shop",
    category: "Grocery",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Chipotle",
    category: "Restaurants ",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Shop",
    category: "Grocery",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Joe Collins",
    category: "Transfer to a card",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Netflix",
    category: "Entertainment",
    amount: "+ $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
];

export const expenseItems = [
  {
    image: CounselorImg,
    name: "Joe Collins",
    category: "Transfer to a card",
    amount: "- $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Shop",
    category: "Grocery",
    amount: "- $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Shop",
    category: "Grocery",
    amount: "- $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Chipotle",
    category: "Restaurants ",
    amount: "- $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
  {
    image: CounselorImg,
    name: "Shop",
    category: "Grocery",
    amount: "- $ 322",
    date: "20.08.2023",
    time: "8:12 AM",
  },
];

export const assetItems = [
  {
    name: "Savings account",
    category: "Short term",
    amount: "+$100",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
  {
    name: "Savings account",
    category: "Short term",
    amount: "-$42",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
  {
    name: "Savings account",
    category: "Short term",
    amount: "+$7,263",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
  {
    name: "Retirement portfolio",
    category: "Long term",
    amount: "+$4,872",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
  {
    name: "Retirement portfolio",
    category: "Long term",
    amount: "-$1,231",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
];

export const liabilityItems = [
  {
    name: "Auto loan",
    category: "Short term",
    amount: "-25%",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
  {
    name: "Credit card balance",
    category: "Short term",
    amount: "+25%",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
  {
    name: "Home mortgage",
    category: "Short term",
    amount: "-25%",
    date: "16 Feb 2023",
    time: "8:12 AM",
    balance: "$15,500",
  },
];

export const counselorSupportData = [
  {
    name: "Andy Boctor",
    status: "Applied",
    date: "28 June 2023",
    changes: [
      "Change Target amount to $12,000.00",
      "Change description to 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'",
    ],
  },
  {
    name: "Andy Boctor",
    status: "Declined",
    date: "28 June 2023",
    changes: [
      "Change Target amount to $12,000.00",
      "Change description to 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'",
    ],
  },
];

//Family Member
export const familyMembers = [
  { id: 1, name: "Joe Collins", avatar: CounselorImg },
  { id: 2, name: "Sarah Smith", avatar: CounselorImg },
];

export const summaries = [
  {
    title: "50-30-20",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    category: "Finances",
    image: SummaryImg2,
  },
  {
    title: "Savings",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    category: "Finances",
    image: SummaryImg1,
  },
  {
    title: "Savings",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    category: "Finances",
    image: SummaryImg1,
  },
];

export const notifications = [
  { title: "How to prepare for milestones", articles: 2, period: "Daily" },
  { title: "Understanding financial planning", articles: 3, period: "Weekly" },
  { title: "Managing personal savings", articles: 1, period: "Daily" },
  {
    title: "Steps to achieve financial independence",
    articles: 5,
    period: "Monthly",
  },
  {
    title: "Investment strategies for long term",
    articles: 4,
    period: "Weekly",
  },
  { title: "Understanding credit scores", articles: 2, period: "Daily" },
  { title: "How to prepare for milestones", articles: 2, period: "Daily" },
  { title: "Understanding financial planning", articles: 3, period: "Weekly" },
  { title: "Managing personal savings", articles: 1, period: "Daily" },
  {
    title: "Steps to achieve financial independence",
    articles: 5,
    period: "Monthly",
  },
  {
    title: "Investment strategies for long term",
    articles: 4,
    period: "Weekly",
  },
  { title: "Understanding credit scores", articles: 2, period: "Daily" },
  { title: "How to prepare for milestones", articles: 2, period: "Daily" },
  { title: "Understanding financial planning", articles: 3, period: "Weekly" },
  { title: "Managing personal savings", articles: 1, period: "Daily" },
  {
    title: "Steps to achieve financial independence",
    articles: 5,
    period: "Monthly",
  },
  {
    title: "Investment strategies for long term",
    articles: 4,
    period: "Weekly",
  },
  { title: "Understanding credit scores", articles: 2, period: "Daily" },
];
export const topics = [
  {
    name: "Finances",
    Icon: FinanceIcon,
  },
  {
    name: "Savings",
    Icon: ZapIcon,
  },
  {
    name: "Milestones",
    Icon: SmallMaximizeIcon,
  },
  {
    name: "Loans",
    Icon: SmallCoinIcon,
  },
  {
    name: "Assets",
    Icon: LayersIcon,
  },
  {
    name: "Liabilities",
    Icon: PocketIcon,
  },
  {
    name: "Management",
    Icon: RepeatIcon,
  },
  {
    name: "Rules",
    Icon: OpenBookIcon,
  },
];
export const calculatorData = [
  {
    title: "Car Payment",
    description:
      "The Auto Loan Calculator is mainly intended for car purchases within the U.S.",
    icon: TrackIcon,
    linkUrl: "/car-payment",
  },
  {
    title: "Credit card payoff",
    description:
      "The Auto Loan Calculator is mainly intended for car purchases within the U.S.",
    icon: CreditCardIcon,
    linkUrl: "/credit-card-payoff",
  },
  {
    title: "College savings",
    description:
      "The Auto Loan Calculator is mainly intended for car purchases within the U.S.",
    icon: BookIcon,
    linkUrl: "/college-savings",
  },
  {
    title: "Interest rate",
    description:
      "The Auto Loan Calculator is mainly intended for car purchases within the U.S.",
    icon: CompassIcon,
    linkUrl: "/interest-rate",
  },
  {
    title: "Principal and interest",
    description:
      "The Auto Loan Calculator is mainly intended for car purchases within the U.S.",
    icon: HeartIcon,
    linkUrl: "/principal-interest",
  },
  {
    title: "Debt elimination",
    description:
      "The Auto Loan Calculator is mainly intended for car purchases within the U.S.",
    icon: CoinsStackIcon,
    linkUrl: "/debt-elimination",
  },
];
export const commanderNotificationsData = [
  {
    id: 1,
    title:
      "Adam Smith has undergone a large abrupt decline in financial well-being related to a large increase in the debt-to-income ratio.",
    time: "June, 2024 10:45 AM",
    description:
      "Abrupt financial declines are leading component of self-harm risk. Check in with the immediate chain of command to see if their behavior has changed. For more information on warning signs and next steps, read short article in recommendations.",
    recommandation: [
      "https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/",
    ],
    status: "Unread",
  },
  {
    id: 2,
    title:
      "Adam Smith has 30+ days overdue in milestone training “First Duty Station”.",
    time: "June, 2024 10:45 AM",
    description:
      "For more information on warning signs and next steps, read short article in recommendations.",
    recommandation: [
      "https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/",
    ],
    status: "Unread",
  },
  {
    id: 3,
    title: "Major downgrade in individual financial health",
    time: "June, 2024 10:45 AM",
    description:
      "This soldier saw their debt rise by more than 20% since yesterday. Consider checking in to make sure their stress and mental health is okay.",
    recommandation: [
      "https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/",
    ],
    status: "Unread",
  },
  {
    id: 4,
    title: "Major downgrade in individual financial health",
    time: "June, 2024 10:45 AM",
    description:
      "This soldier saw their debt rise by more than 20% since yesterday. Consider checking in to make sure their stress and mental health is okay.",
    recommandation: [
      "https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/",
    ],
    status: "Read",
  },
  //give me 20 data
  ...Array.from({ length: 20 }, (_, i) => ({
    id: i + 5,
    title: `Notification ${i + 5}`,
    time: "June, 2024 10:45 AM",
    description: "This is a sample description for notification.",
    recommandation: [
      "https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/",
    ],
    status: i % 3 === 0 ? "Unread" : "Read",
  })),
];
export const mockBudgetData = [
  {
    category: "Needs",
    color: colors.warning[300],
  },
  {
    category: "Wants",
    color: "#48944B",
  },
  {
    category: "Savings",
    color: colors.pink[500],
  },
];
export const mockRelatedData = [
  {
    image: TeacherPng,
    title: "Financial Health",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s ",
  },
  {
    image: BusinessPng,
    title: "Savings",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: MoneyPng,
    title: "How to invest",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: CouplePng,
    title: "Savings",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];
export const mockClientNotificationData = {
  daily: [
    { title: "How to prepare for milestones", articles: 2 },
    { title: "How to create a budget", articles: 4 },
    { title: "Effective time management", articles: 3 },
    { title: "Work-life balance tips", articles: 1 },
    { title: "Developing a growth mindset", articles: 3 },
    { title: "Maximizing productivity", articles: 5 },
    { title: "Daily exercise for mental health", articles: 2 },
    { title: "Strategies for career growth", articles: 4 },
    { title: "How to review your progress", articles: 2 },
    { title: "How to prepare for milestones", articles: 2 },
    { title: "Setting weekly goals", articles: 3 },
  ],
  weekly: [
    { title: "How to prepare for milestones", articles: 2 },
    { title: "Setting weekly goals", articles: 3 },
    { title: "Weekly meal planning", articles: 2 },
    { title: "Building sustainable habits", articles: 3 },
    { title: "How to review your progress", articles: 2 },
  ],
  monthly: [],
  // monthly: [
  //   { title: "How to prepare for milestones", articles: 2 },
  //   { title: "Monthly budget review", articles: 4 },
  //   { title: "Long-term goal planning", articles: 3 },
  //   { title: "Reflecting on monthly achievements", articles: 1 },
  // ],
};
