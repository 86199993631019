import * as yup from "yup";

const loginSchema = yup.object().shape({
  user: yup.string().required(""),
  password: yup
    .string()
    .required("")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(
      /\d|[!@#$%^&*]/,
      "Password must contain at least one number or symbol"
    ),
});
const forgotSchema = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .email("Wrong email format. Please use example@gmail.com"),
});
const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("")
    .email("Wrong email format. Please use example@gmail.com"),
});
const userInfoSchema = yup.object().shape({
  userName: yup.string().required("Username is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phone_number: yup
    .string()
    .matches(/^\+1\s?(\d{3})\s?(\d{3})\s?(\d{4})$/, "Wrong phone format.")
    .required("Phone number is required"),
});

export const addressSchema = yup.object().shape({
  addr1: yup.string().required("Address Line 1 is required"),
  addr2: yup.string().optional(),
  city: yup.string().required("City is required"),
  stateProv: yup.string().required("State/Province is required"),
  postalCode: yup.string().required("Postal Code is required"),
  country: yup.string().required("Country is required"),
});

const spousePersonalInformationSchema = yup.object().shape({
  username: yup.string().required(""),
  // fullName: yup
  //   .string()
  //   .required("Full name is required")
  //   .matches(/^[a-zA-Z\s]+$/, "Full name can only contain letters and spaces")
  //   .min(2, "Full name must be at least 2 characters")
  //   .max(50, "Full name cannot exceed 50 characters"),
  // dateOfBirth: yup
  //   .string()
  //   .required("Date of birth is required")
  //   .matches(
  //     /^(\d{2})\.(\d{2})\.(\d{4})$/,
  //     "Date of birth must be in the format DD.MM.YYYY"
  //   ),
});
const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(
      /\d|[!@#$%^&*]/,
      "Password must contain at least one number or symbol"
    ),
  repeatPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "The passwords don't match. Please try again."
    )
    .required("Repeat password is required"),
});
// LoginAccountModalValidation

const loginAccountModalSchema = yup.object().shape({
  phone_number: yup
    .string()
    .matches(/^\+1\s?(\d{3})\s?(\d{3})\s?(\d{4})$/, "Wrong phone format.")
    .required("Phone number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(
      /\d|[!@#$%^&*]/,
      "Password must contain at least one number or symbol"
    ),
});
const connectAccountByCardSchema = yup.object().shape({
  accountHolderName: yup
    .string()
    .required("Account holder’s name is required")
    .min(3, "Name must be at least 3 characters"),
  accountNumber: yup.string().required("Account number is required"),
  expirationDate: yup
    .string()
    .required("Expiration date is required")
    .matches(
      /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
      "Expiration date must be in MM/YY format"
    ),
  cvv: yup
    .string()
    .required("CVV is required")
    .matches(/^[0-9]{3}$/, "CVV must be exactly 3 digits"),
});

export {
  loginSchema,
  forgotSchema,
  newPasswordSchema,
  registerSchema,
  spousePersonalInformationSchema,
  userInfoSchema,
  loginAccountModalSchema,
  connectAccountByCardSchema,
};
