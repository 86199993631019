import * as React from "react";
const CreditCardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={47} height={47} x={0.5} y={0.5} fill="#EFF8FF" rx={5.5} />
    <rect width={47} height={47} x={0.5} y={0.5} stroke="#7CD4FD" rx={5.5} />
    <path
      stroke="#0086C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 22h22m-20-6h18a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H15a2 2 0 0 1-2-2V18a2 2 0 0 1 2-2Z"
    />
  </svg>
);
export default CreditCardIcon;
