import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowRightIcon } from "assets/icons";
import DoughnutCard from "components/UnitScore/DoughnutCard";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getGoals } from "store/actions/GoalAction";
import { selectGoals } from "store/reducers/GoalReducer";
import { selectAssets } from "store/reducers/TransactionReducer";
import typography from "theme/typography";
import checkGoalComplete from "utils/checkGoalComplete";

const GoalsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goalsData = useAppSelector(selectGoals);
  const { data: bankAccounts } = useAppSelector(selectAssets);
  const possibleAccounts = bankAccounts?.filter(
    (account) => account.currentValue !== null
  );
  // Set Estimated Monthly Income (hardcoded as $800 for now)
  const estimatedMonthlyIncome = 1500;

  useEffect(() => {
    dispatch(getGoals());
  }, [dispatch]);
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: "1px solid #e0e0e0",
        backgroundColor: "#fff",
        marginBottom: 2,
        height: 416,
        maxHeight: 416,
        py: 4,
        px: 4,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h5" fontWeight={600} color={"primary.700"}>
          Goals
        </Typography>

        <Button onClick={() => navigate("/goals")}>
          <ArrowRightIcon />
        </Button>
      </Box>
      <Grid container spacing={2}>
        {goalsData
          // ?.slice(1)
          ?.slice(-3)
          ?.map((goal, index) => (
            <Grid key={index} item xs={4} height={350}>
              <DoughnutCard
                value={
                  checkGoalComplete(
                    possibleAccounts?.find(
                      (account) =>
                        account?.id === goal?.goalColor?.split("?")[1]
                    )?.currentValue || 0,
                    goal?.goalAmount
                  ) || `0`
                }
                key={goal.id}
                label={goal.name}
                {...goal}
                goalColor={goal?.goalColor?.split("?")[0]}
                details
                amountStyle={{ ...typography.h6 }}
                onClickDetails={() => {
                  navigate(`/goals/${goal?.id}`, {
                    state: {
                      accountBalance:
                        possibleAccounts?.find(
                          (account) =>
                            account?.id === goal?.goalColor?.split("?")[1]
                        )?.currentValue || 0,
                      estimatedMonthlyIncome: estimatedMonthlyIncome,
                    },
                  });
                }}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default GoalsContainer;
