import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { TRoles } from "../../types/types";
import { login, register } from "../actions/AuthAction";

interface IUser {
  id: number;
  email: string;
  userName: string;
  role: TRoles;
  spouseInviteToken: string;
}
interface InitialState {
  user: null | {
    jwt: string;
    data: IUser;
  };
  status: "active" | "inactive" | "connectAccount";
  selectedRole: TRoles;
}
const initialState: InitialState = {
  user: null,
  selectedRole: "Client",
  status: "active",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRole: (state, { payload }) => {
      state.selectedRole = payload;
    },
    changeStatus: (state, { payload }) => {
      state.status = payload;
    },
    signOut: (state) => {
      state.user = null;
      localStorage.removeItem("jwt");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.status = "active";
      localStorage.setItem("jwt", payload?.jwt);
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.user = payload?.data;
      state.status = "inactive";
      localStorage.setItem("jwt", payload?.data?.jwt);
    });
  },
});

export const getRole = (state: RootState) => state.auth.selectedRole;
export const getUser = (state: RootState) => state.auth.user;
export const getStatus = (state: RootState) => state.auth.status;
export const { setRole, signOut, changeStatus } = authSlice.actions;
export const authReducer = authSlice.reducer;
