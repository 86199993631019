import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { DashboardHeader, DeleteAdminModal, Table } from "components";
import CreateUnitModal from "components/admin/CreateUnitModal";
import useModal from "hooks/useModal";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { deleteUnitIfEmpty, getUnits } from "store/actions/AdminAction";
import { selectUnits } from "store/reducers/AdminReducer";
import { IColumn } from "types/types";
import { showToast } from "utils/showToast";

const Units = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const unitsData = useAppSelector(selectUnits);
  const {
    isVisible: isCreateUnitModalVisible,
    onClose: onCloseCreateUnitModal,
    onOpen: onOpenCreateUnitModal,
    onOpenWithValue: onOpenCreateUnitModalWithValue,
  } = useModal();
  const {
    isVisible: isDeleteModalVisible,
    onClose: onDeleteModalClose,
    onOpenWithValue: onDeleteModalOpen,
  } = useModal();
  const [searchParams] = useSearchParams();
  const page = +searchParams.get("page") || 1;
  const limit = +searchParams.get("limit") || 10;
  console.log(unitsData);

  // Columns configuration for the Table component
  const columns: IColumn[] = [
    {
      id: "name",
      label: "Unit Name",
      render: (row) => <Typography>{row.name}</Typography>,
    },
    {
      id: "rosterCount",
      label: "Rosters Count",
      render: (row) => (
        <Typography pl={2}>{row?.rosters?.length || 0}</Typography>
      ),
    },
    {
      id: "viewRosters",
      label: "View Rosters",
      render: (row) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            navigate(`/units/${row?.id}/rosters`, {
              state: {
                data: row?.rosters,
              },
            })
          }
          sx={{ ml: 3 }}
          disabled={!row?.rosters || row?.rosters?.length === 0}
        >
          View
        </Button>
      ),
    },
    {
      id: "Edit",
      label: "Edit",
      render: (row) => (
        <Button
          variant="contained"
          color="info"
          size="small"
          sx={{ width: 70, height: 30, minWidth: 0, ml: -5 }}
          onClick={() => {
            onOpenCreateUnitModalWithValue({
              id: row?.id,
              name: row?.name,
            } as any);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      id: "Delete",
      label: "Delete",
      render: (row) => (
        <Button
          variant="contained"
          color="error"
          size="small"
          sx={{ width: 70, height: 30, minWidth: 0, ml: -3 }}
          onClick={() => {
            onDeleteModalOpen(row?.id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  // Fetch unit data from the API or store
  const fetchUnitsData = useCallback(async () => {
    setLoading(true);
    await dispatch(getUnits());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchUnitsData();
  }, [fetchUnitsData]);
  const paginatedData = unitsData?.slice((page - 1) * limit, page * limit);

  return (
    <Box>
      <DashboardHeader page="Units">
        <Button
          onClick={() => onOpenCreateUnitModal()}
          sx={{
            backgroundColor: "primary.main",
            mr: 2,
            px: 2.5,
            py: 1.75,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          <Typography
            textTransform={"capitalize"}
            variant="h6"
            fontWeight={600}
            ml={0.5}
            color={"white"}
          >
            Add Unit
          </Typography>
        </Button>
      </DashboardHeader>

      <Box py={6} px={10}>
        {loading ? (
          <LinearProgress />
        ) : (
          <Table
            total={paginatedData?.length}
            data={paginatedData}
            columns={columns}
            handleChange={() => {}}
          />
        )}
      </Box>

      <DeleteAdminModal
        title="unit"
        handleCloseModal={onDeleteModalClose}
        open={isDeleteModalVisible}
        handleDelete={async () => {
          const response = await dispatch(
            deleteUnitIfEmpty(isDeleteModalVisible as number)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            showToast("Unit deleted successfully", "success");
            onDeleteModalClose();
            fetchUnitsData(); // Refresh data after deletion
          }
        }}
      />
      <CreateUnitModal
        open={isCreateUnitModalVisible as boolean}
        handleClose={onCloseCreateUnitModal}
      />
    </Box>
  );
};

export default Units;
