import { Theme } from "@emotion/react";
import {
  FormControl,
  Select as MUISelect,
  MenuItem,
  SelectChangeEvent,
  SxProps,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import typography from "theme/typography";

interface IOption {
  value: string;
  label: string;
}
interface ISelect {
  options: IOption[];
  label?: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  placeholder?: string;
}
const Select = ({
  options,
  label,
  value,
  onChange,
  sx,
  labelSx,
  placeholder,
}: ISelect) => {
  return (
    <FormControl variant="outlined">
      <Typography variant="h6" color={"primary.800"} sx={{ mb: 2 }}>
        {label}
      </Typography>
      <MUISelect
        displayEmpty={true}
        renderValue={(value) =>
          value?.length ? (
            Array.isArray(value) ? (
              value.join(", ")
            ) : (
              value
            )
          ) : (
            <Typography
              mt={0.5}
              variant="h6"
              fontWeight={400}
              color={"primary.500"}
            >
              {placeholder}
            </Typography>
          )
        }
        value={value}
        onChange={onChange}
        disabled={false}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.200",
            borderRadius: 2,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.50",
          },
          backgroundColor: "white",
          ...sx,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 200,
              mt: 1,
              borderRadius: 2,
              "& .MuiMenuItem-root": {
                padding: "10px 16px",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
                "&.Mui-selected": {
                  backgroundColor: "secondary.50",
                  color: "white",
                },
              },
              "& .MuiList-root": {
                py: 0,
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              ...typography.h6,
              height: 40,
              color: option.value !== value ? "primary.main" : "white",
              backgroundColor:
                option.value === value ? "secondary.50" : "inherit",
              // "&:hover": {
              //   backgroundColor: "primary.light",
              // },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
