import * as React from "react";
const SearchOffIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={71}
    fill="none"
    {...props}
  >
    <rect width={64} height={64} x={3} y={3.838} fill="#FEF0C7" rx={28} />
    <rect
      width={64}
      height={64}
      x={3}
      y={3.838}
      stroke="#FFFAEB"
      strokeWidth={6}
      rx={28}
    />
    <path
      fill="#FDB022"
      fillRule="evenodd"
      d="M26.198 39.764a9.001 9.001 0 0 0 11.983.669l6.874 6.874a1 1 0 0 0 1.414-1.414l-6.874-6.875a9.001 9.001 0 0 0-13.396-11.982 9 9 0 0 0 0 12.728Zm1.415-11.313a7 7 0 0 0 9.893 9.905l.006-.006.006-.006a7 7 0 0 0-9.905-9.893Z"
      clipRule="evenodd"
    />
    <path
      fill="#FDB022"
      d="M29.533 30.373a1 1 0 0 1 1.414 0l1.65 1.65 1.65-1.65a1 1 0 1 1 1.414 1.415l-1.65 1.65 1.65 1.65a1 1 0 1 1-1.414 1.414l-1.65-1.65-1.65 1.65a1 1 0 1 1-1.414-1.415l1.65-1.65-1.65-1.65a1 1 0 0 1 0-1.414Z"
    />
  </svg>
);
export default SearchOffIcon;
