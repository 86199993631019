import { Box } from "@mui/material";
import { NotificationIcon } from "assets/icons";
import { Button, DashboardHeader, Input, OverallUnitScore } from "components";
import RousterTable from "components/UnitRoster/RousterTable";
import { overallScoreData } from "datas";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const UnitRosters = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Box>
      <DashboardHeader page={"Unit Rosters"}>
        <Box display={"flex"} alignItems={"center"} gap={6}>
          <Input
            search
            sx={{ width: 388 }}
            placeholder="Search by title, day, category"
          />
          <Button
            onClick={() => navigate("/notifications")}
            sx={{ width: 48, height: 48, p: 0 }}
          >
            <NotificationIcon />
          </Button>
        </Box>
      </DashboardHeader>
      <Box px={10} py={12}>
        <OverallUnitScore
          unitId={searchParams.get("unitId")}
          data={overallScoreData}
        />
        <RousterTable />
      </Box>
    </Box>
  );
};

export default UnitRosters;
