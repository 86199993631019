import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import Link from "@mui/material/Link";
import React from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { login } from "store/actions/AuthAction";
import { getRole } from "store/reducers/AuthReducer";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { showToast } from "utils/showToast";
import { Button, Input } from "../";
import { loginSchema } from "../../validation/authValidation";
import { TRoles } from "types/types";
interface LoginFormInputs {
  user: string;
  password: string;
}

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(getIsLoading);
  const role = useAppSelector(getRole);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema) as Resolver<LoginFormInputs>,
    mode: "onSubmit",
  });

  const onSubmit = async (data: LoginFormInputs) => {
    const body = {
      password: data?.password,
      role: role?.replace(" ", "") as TRoles,
    };
    if (data?.user.includes("@")) {
      body["email"] = data?.user;
    } else {
      body["userName"] = data?.user;
    }
    const response = await dispatch(login(body));
    if (response?.meta?.requestStatus === "fulfilled") {
      showToast("Successfully logged in", "success");
      navigate("/");
    }
  };
  return (
    <Box width={"100%"}>
      <FormControl
        fullWidth
        sx={{ gap: 6, mb: 1 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="user"
          control={control}
          render={({ field }) => (
            <Input
              required
              label="Enter username or email"
              error={!!errors.user}
              helperText={errors.user?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              fullWidth
              required
              label="Enter Password"
              error={!!errors.password}
              helperText={errors.password?.message}
              type="password"
              {...field}
            />
          )}
        />
      </FormControl>
      <Link
        href="/forgot"
        display={"block"}
        textAlign={"right"}
        underline="none"
        sx={{ fontWeight: 600 }}
      >
        Forgot Password?
      </Link>
      <Button
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        type="submit"
        disabled={!isDirty || !isValid}
        variant="contained"
        fullWidth
        label="Log in"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default LoginForm;
