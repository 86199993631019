import { Theme } from "@emotion/react";
import { Box, Grid, SxProps, Typography } from "@mui/material";
import React from "react";
import { TFinancialStatus } from "types/types";

interface Factor {
  score: number;
  label: string;
}

const factors: Factor[] = [
  { score: 12.5, label: "Spend less than income" },
  { score: 12.0, label: "Have manageable debt" },
  { score: 10.0, label: "Have sufficient liquid savings" },
  { score: 12.5, label: "Have appropriate insurance" },
  { score: 12.5, label: "Have sufficient long-term savings" },
  { score: 8.0, label: "Have a prime credit score" },
  { score: 11.5, label: "Plan ahead financially" },
  { score: 9.0, label: "Pay bills on time" },
];
interface ICalculationBreakdown {
  average?: boolean;
  score?: boolean;
  status?: TFinancialStatus;
  value?: string | number;
  valueSx?: SxProps<Theme>;
}
const CalculationBreakdown = ({
  average,
  score,
  status,
  value,
  valueSx,
}: ICalculationBreakdown) => {
  return (
    <Box bgcolor={"white"} p={4} borderRadius={1.75}>
      <Box display={"flex"} alignItems={"center"} gap={4}>
        <Typography variant="h3" color={"primary.800"} fontWeight={500}>
          {average || score ? "Score:" : "Result:"}
        </Typography>
        <Typography variant="h2" color="primary" fontWeight={700} sx={valueSx}>
          {value}
        </Typography>
      </Box>
      {average && (
        <Box display={"flex"} alignItems={"center"} gap={4} mt={6}>
          <Typography variant="h3" color={"primary.800"} fontWeight={500}>
            {average ? "Average Status:" : "Status:"}
          </Typography>
          <Typography variant="h2" color="primary" fontWeight={700}>
            {status}
          </Typography>
        </Box>
      )}
      <Typography variant="h5" fontWeight={600} color={"primary.700"} mt={6}>
        How it was calculated
      </Typography>
      <Grid container spacing={2} mt={4}>
        {factors.map((factor, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                height: 92,
                p: 3,
                borderRadius: 2,
                borderWidth: 1,
                borderStyle: "solid",
                backgroundColor: index % 2 === 0 ? "primary.50" : "white",
                borderColor: index % 2 === 0 ? "white" : "primary.50",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" color={"primary.700"} fontWeight={600}>
                {factor.score?.toFixed(1)}
              </Typography>
              <Typography variant="h6" color={"primary.500"} mt={2}>
                {factor.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CalculationBreakdown;
