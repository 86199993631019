import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { TRoles } from "types/types";
import { handleError } from "utils/handleError";

const register = createAsyncThunk(
  "auth/register",
  async (
    data: {
      userName?: string;
      password?: string;
      role?: TRoles;
      fullName?: string;
      dateOfBirth?: Date;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("auth/register", data);
      return response?.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const otpSend = createAsyncThunk(
  "auth/otp/send",
  async (data: { email: string }, { rejectWithValue }) => {
    try {
      const response = await request.post("auth/otp/send", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const otpVerify = createAsyncThunk(
  "auth/otp/verify",
  async (data: { email: string; otp_code: string }, { rejectWithValue }) => {
    try {
      const response = await request.post("auth/otp/verify", data);
      localStorage.setItem("jwt", response?.data?.jwt);
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
const otpResend = createAsyncThunk(
  "auth/otp/resend",
  async (data: { email: string }, { rejectWithValue }) => {
    try {
      const response = await request.post("auth/otp/resend", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const login = createAsyncThunk(
  "auth/login",
  async (
    data: { email?: string; userName?: string; password: string; role: TRoles },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("auth/login", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
const signOnFiserv = createAsyncThunk(
  "auth/signOnFiserv",
  async (
    data: {
      userId?: string;
      password?: string;
      role?: TRoles;
      fullName?: string;
      dateOfBirth?: Date;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("fiserv/user/signOn", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const spouseInvitation = createAsyncThunk(
  "auth/spouse-invitation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.post("auth/spouse-invitation");
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const forgotOtpSend = createAsyncThunk(
  "auth/forgetPassword",
  async (data: { email: string }, { rejectWithValue }) => {
    try {
      const response = await request.post("auth/forgetPassword", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: { password: string }, { rejectWithValue }) => {
    try {
      const response = await request.patch("auth/resetPassword", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const spouseOtpSend = createAsyncThunk(
  "auth/spouse/otp/send",
  async (data: { email: string }, { rejectWithValue }) => {
    try {
      const response = await request.post("auth/spouse/otp/send", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const spouseRegister = createAsyncThunk(
  "auth/register",
  async (
    data: { userName: string; password: string; role: TRoles },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("auth/register", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export {
  forgotOtpSend,
  login,
  otpResend,
  otpSend,
  otpVerify,
  register,
  resetPassword,
  signOnFiserv,
  spouseInvitation,
  spouseOtpSend,
  spouseRegister,
};
