import * as React from "react";
const SmallPlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props?.fill || "#0C425F"}
        d="M7.833 13.333a.667.667 0 1 0 1.334 0V8.667h4.666a.667.667 0 1 0 0-1.334H9.167V2.667a.667.667 0 0 0-1.334 0v4.666H3.167a.667.667 0 0 0 0 1.334h4.666v4.666Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h16v16H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SmallPlusIcon;
