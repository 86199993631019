import * as React from "react";
const ContactIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16.457 2.883a31.332 31.332 0 0 0-8.913 0 3.197 3.197 0 0 0-2.728 2.874l-.127 1.396a53.505 53.505 0 0 0 0 9.695l.127 1.395a3.196 3.196 0 0 0 2.728 2.875c2.956.424 5.958.424 8.913 0a3.196 3.196 0 0 0 2.73-2.875l.126-1.395c.293-3.225.293-6.47 0-9.695l-.127-1.396a3.196 3.196 0 0 0-2.729-2.874ZM10.001 9a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-2 6.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3 1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ContactIcon;
