import { Avatar, Box, LinearProgress, Typography } from "@mui/material";
import {
  Button,
  DashboardHeader,
  Input,
  MultiDatePicker,
  RightSidebar,
  Table,
  TransferInfo,
} from "components";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getTransactions } from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import {
  selectIncomes,
  selectTransactionsInfo,
} from "store/reducers/TransactionReducer";
import typography from "theme/typography";
import { IColumn } from "types/types";
import GreenWall from "../../../assets/images/green-wall.png";

const Incomes = () => {
  const { isVisible, onOpenWithValue, onClose } = useModal();
  const incomes = useAppSelector(selectIncomes);
  const { totalIncomes } = useAppSelector(selectTransactionsInfo);
  const loading = useAppSelector(getIsLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get parameters from URL or set defaults
  const page = +searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [search, setSearch] = useState("");

  const columns: IColumn[] = [
    {
      id: "title",
      label: "Title",
      render: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={row.avatar || GreenWall} alt={row.title} />
          <Typography style={{ marginLeft: "10px" }} color={"primary.700"}>
            {row.title}
          </Typography>
        </div>
      ),
    },
    {
      id: "date",
      label: "Date & Time",
      render: (row) => (
        <Typography
          variant="h6"
          fontWeight={400}
          color={"primary.500"}
          whiteSpace={"pre"}
        >{`${dayjs(row?.date).format("DD MMM YYYY, HH:mm A")}`}</Typography>
      ),
      style: { color: "primary.500" },
    },
    {
      id: "amount",
      label: "Amount",
      render: (row) => (
        <Typography
          variant="body1"
          fontWeight={600}
          color={"primary.700"}
          whiteSpace={"pre"}
        >{`$ ${row.amount?.amt}`}</Typography>
      ),
      style: { ...typography.body1, color: "primary.700" },
    },
  ];

  const updateURLParams = useCallback(
    (newPage?: number) => {
      const params = new URLSearchParams();
      params.set("page", (newPage || page).toString());
      params.set("limit", limit.toString());
      startDate && params.set("startDate", startDate);
      endDate && params.set("endDate", endDate);
      setSearchParams(params);
    },
    [page, setSearchParams, limit, startDate, endDate]
  );

  useEffect(() => {
    updateURLParams();
    dispatch(
      getTransactions({
        page,
        limit: +limit,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
      })
    );
  }, [dispatch, page, limit, updateURLParams, startDate, endDate]);

  const handleDateChange = (newDate) => {
    const startDate = newDate[0];
    const endDate = newDate[1];
    if (startDate && endDate) {
      searchParams.set("startDate", startDate?.format("YYYY-MM-DD"));
      searchParams.set("endDate", endDate?.format("YYYY-MM-DD"));
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    updateURLParams(1); // Reset to page 1 on search change
  };

  return (
    <Box width={"100%"}>
      <DashboardHeader
        goBack
        page="Income"
        handleGoBack={() => navigate("/financial-statement")}
      >
        <Box width={426}>
          <Input
            sx={{ width: 311 }}
            onChange={handleSearchChange}
            fullWidth
            placeholder="Search by title"
            search
            value={search}
          />
        </Box>
      </DashboardHeader>
      <Box sx={{ px: 10, pb: 6, mt: -3 }}>
        <Box display={"flex"} alignItems={"center"} gap={6}>
          <MultiDatePicker
            dates={startDate && endDate ? [startDate, endDate] : []}
            onChange={handleDateChange}
            type="all"
            label="Date Range"
            mainSx={{ width: 311 }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              setSearchParams({}); // Clear all URL search parameters
              setSearch(""); // Reset search state
            }}
            sx={{ width: 180, height: 43, marginTop: 6.5 }}
            label="Clear filters"
            disabledBgColor="primary.100"
            disabledTextColor="primary.300"
          />
        </Box>
        {loading ? (
          <LinearProgress />
        ) : (
          <Table
            total={totalIncomes}
            data={incomes?.filter((item) =>
              item?.title?.toLowerCase()?.includes(search?.toLowerCase())
            )}
            columns={columns}
            onRowClick={(row) => {
              onOpenWithValue(row?.accountId);
            }}
            handleChange={({ page }) => {
              updateURLParams(page as number);
            }}
          />
        )}
        <RightSidebar
          open={isVisible as boolean}
          onClose={onClose}
          title="Transfer"
          subTitle={dayjs(
            incomes?.find(
              (item) => item?.accountId === isVisible || item?.id === isVisible
            )?.date
          ).format("DD MMM YYYY, HH:mm A")}
        >
          <TransferInfo
            data={incomes?.find(
              (item) => item?.accountId === isVisible || item?.id === isVisible
            )}
          />
        </RightSidebar>
      </Box>
    </Box>
  );
};

export default Incomes;
