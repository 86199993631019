import { Box, InputAdornment, Typography } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Button, Input, Select } from "components";
import useModal from "hooks/useModal";
import React, { useState } from "react";

const AmountSelect = ({ onApply }) => {
  const { isVisible, onOpenWithValue, onClose } = useModal();
  const [filter, setFilter] = useState({
    type: "", // filterType
    amount1: "", // amount1
    amount2: "", // amount2 (for 'between' option)
  });
  return (
    <Box position={"relative"} zIndex={1000}>
      <Input
        label="Amount"
        disabled
        InputProps={{
          endAdornment: <ArrowDropDownIcon />,
          style: { backgroundColor: "white", height: 44, width: 250 },
        }}
        onClick={() => {
          onOpenWithValue(isVisible ? 0 : 1);
        }}
        placeholder="Select an amount"
        value={
          isVisible && filter?.amount1
            ? "Select an amount"
            : filter.type === "equal to"
            ? `= equal to ${filter.amount1}`
            : filter.type === "more than"
            ? `> more than ${filter.amount1}`
            : filter.type === "less than"
            ? `< less than ${filter.amount1}`
            : filter.type === "between"
            ? `() between ${filter.amount1} and ${filter.amount2}`
            : ""
        }
      />
      {isVisible ? (
        <Box
          position={"absolute"}
          bgcolor={"white"}
          top={78}
          left={-70}
          width={270}
          height={190}
          py={3}
          px={4}
          borderRadius={2}
          boxShadow={" 0px 4px 4px 0px #00000014"}
        >
          <Select
            value={filter.type}
            onChange={(e) =>
              setFilter({
                ...filter,
                type: e.target.value,
              })
            }
            sx={{
              width: 238,
              height: 36,
              textAlign: "center",
              border: "1px solid #0C425F",
              borderRadius: 2,
              color: "#0C425F",
              fontSize: 13,
            }}
            // MenuProps={{
            //   PaperProps: {
            //     sx: {
            //       maxHeight: 300,
            //     },
            //   },
            // }}
            label="Amount"
            options={[
              { value: "equal to", label: "equal to" },
              { value: "more than", label: "more than >" },
              { value: "less than", label: "less than <" },
              { value: "between", label: "between ()" },
            ]}
          />

          <Box
            sx={{
              mt: 2,
              display: "flex",
              gap: 2,
            }}
          >
            <Input
              value={filter.amount1}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  amount1: e.target.value,
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontSize={13}>$</Typography>
                  </InputAdornment>
                ),
                style: {
                  height: 36,
                  width: filter.type !== "between" ? 238 : "100%",
                },
                sx: {
                  "& .MuiInputBase-input": {
                    pl: "0px!important",
                  },
                },
              }}
            />
            {filter.type === "between" && (
              <>
                <Typography variant="h6" color={"primary.main"} mt={1.5} mx={2}>
                  and
                </Typography>
                <Input
                  value={filter.amount2}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      amount2: e.target.value,
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography fontSize={13}>$</Typography>
                      </InputAdornment>
                    ),
                    style: {
                      height: 36,
                      paddingLeft: "0px!important",
                    },
                    sx: {
                      "& .MuiInputBase-input": {
                        pl: "0px!important",
                      },
                    },
                  }}
                />
              </>
            )}
          </Box>
          <Box mt={5} display={"flex"} gap={2} justifyContent={"flex-end"}>
            <Button
              onClick={onClose}
              sx={{ width: 70, height: 40 }}
              variant="text"
              label="Cancel"
            />
            <Button
              onClick={() => {
                onApply(filter);
                onClose();
              }}
              sx={{ width: 70, height: 40 }}
              variant="text"
              label="Apply"
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default AmountSelect;
