import { Box } from "@mui/material";
import { NotificationIcon } from "assets/icons";
import {
  Button,
  DashboardHeader,
  EmptySearchResult,
  FinancialStatusDashboard,
  Input,
  OverallUnitScore,
  ReportLoadError,
  ScoreDashboard,
  ScoreLineDashboard,
} from "components";
import { scoreDashboardData } from "datas";
import { scorLineData } from "datas/chart";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getAllUnitsByCommanderId } from "store/actions/UnitsAction";
import { getUser } from "store/reducers/AuthReducer";
import { selectOverallUnitId } from "store/reducers/UnitReducer";

const UnitScore = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const hasUnit = useAppSelector(selectOverallUnitId);
  const user = useAppSelector(getUser);

  const getAllUnits = useCallback(async () => {
    await dispatch(getAllUnitsByCommanderId(user?.data?.id));
  }, [dispatch, user?.data?.id]);

  useEffect(() => {
    getAllUnits();
  }, [getAllUnits]);

  // const filteredOverallScoreData = overallScoreData.filter((item) =>
  //   item.label.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  // const filteredFinancialStatusData = financialStatusData.filter((item) =>
  //   item.title.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const filteredScoreDashboardData = scoreDashboardData.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const isShowLineBoard = scorLineData?.datasets?.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      <DashboardHeader page={"Unit Score"}>
        <Box display={"flex"} alignItems={"center"} gap={6}>
          <Input
            onChange={(e) => setSearchQuery(e.target.value)}
            search
            sx={{ width: 388 }}
            placeholder="Search by title, day, category"
          />
          <Button
            onClick={() => navigate("/notifications")}
            sx={{ width: 48, height: 48, p: 0 }}
          >
            <NotificationIcon />
          </Button>
        </Box>
      </DashboardHeader>
      {!hasUnit ? (
        <ReportLoadError />
      ) : // filteredOverallScoreData?.length === 0 &&
      // filteredFinancialStatusData?.length === 0 &&
      filteredScoreDashboardData?.length === 0 &&
        isShowLineBoard?.length === 0 ? (
        <EmptySearchResult />
      ) : (
        <Box px={10} py={12}>
          {/* {filteredOverallScoreData.length > 0 && ( */}
          <OverallUnitScore unitId={searchParams.get("unitId")} />
          {/* )} */}
          <FinancialStatusDashboard />
          <ScoreDashboard data={filteredScoreDashboardData} />
          {isShowLineBoard.length > 0 && (
            <ScoreLineDashboard
              unitId={searchParams.get("unitId")}
              period={searchParams.get("period") || "Monthly"}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
export default UnitScore;
