import React from "react";
import { Box, Typography } from "@mui/material";
import { SearchOffIcon } from "assets/icons";

interface EmptyProps {
  message?: string;
  subMessage?: string;
}

const Empty: React.FC<EmptyProps> = ({
  message = "No data here",
  subMessage = "Please, try another filter option.",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        py: 5,
        bgcolor: "white",
        width: "100%",
      }}
    >
      <SearchOffIcon />
      <Typography
        variant="h3"
        sx={{
          mt: 2,
          fontWeight: 600,
          color: "primary.main",
          padding: "0 10px",
          borderRadius: 2,
        }}
      >
        {message}
      </Typography>
      <Typography variant="body2" color="primary.800" sx={{ mt: 1 }}>
        {subMessage}
      </Typography>
    </Box>
  );
};

export default Empty;
