import { Box, Fade, IconButton, Modal, Typography } from "@mui/material";
import { CloseIcon } from "assets/icons";
import React from "react";

interface RightSidebarProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  subTitle: string;
}

const RightSidebar: React.FC<RightSidebarProps> = ({
  open,
  onClose,
  children,
  title,
  subTitle,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={() => <></>}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "fixed",
            right: 0,
            top: 0,
            width: 385,
            height: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            px: 4,
            pt: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: 4,
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography variant="h5" fontWeight={600} color={"primary.700"}>
                {title}
              </Typography>
              <Typography variant="h6" color={"primary.500"}>
                {subTitle}
              </Typography>
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default RightSidebar;
