import React from "react";
import { Box } from "@mui/material";
import getFinancialStatusColor from "utils/getFinancialStatusColor";

interface DistributionBarProps {
  notReady: number;
  marginallyReady: number;
  ready: number;
}

const DistributionBar: React.FC<DistributionBarProps> = ({
  notReady,
  marginallyReady,
  ready,
}) => {
  return (
    <Box
      display="flex"
      height={16}
      borderRadius={8}
      overflow="hidden"
      width="100%"
    >
      <Box
        width={`${notReady}%`}
        bgcolor={getFinancialStatusColor("Not Ready")}
      />
      <Box
        width={`${marginallyReady}%`}
        bgcolor={getFinancialStatusColor("Marginally Ready")}
      />
      <Box width={`${ready}%`} bgcolor={getFinancialStatusColor("Ready")} />
    </Box>
  );
};

export default DistributionBar;
