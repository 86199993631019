import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { ShareIcon } from "assets/icons";
import {
  Button,
  CalculationBreakdown,
  DashboardHeader,
  TaskList,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getScoreMe } from "store/actions/FinancialStatementsAction";
import { selectScore } from "store/reducers/TransactionReducer";

const FinancialHealthScore = () => {
  const score = useAppSelector(selectScore);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleGetScore = useCallback(async () => {
    setLoading(true);
    await dispatch(getScoreMe());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    !score && handleGetScore();
  }, [score, dispatch, handleGetScore]);
  return (
    <Box width={"100%"}>
      <DashboardHeader page="Financial Health Score" goBack>
        <Button sx={{ gap: 2, borderColor: "secondary.main", height: 40 }}>
          <ShareIcon />
          <Typography>Share</Typography>
        </Button>
      </DashboardHeader>
      <Grid container px={10} py={6} columnSpacing={6}>
        <Grid item xs={8}>
          {loading ? (
            <LinearProgress />
          ) : (
            <CalculationBreakdown score value={score} />
          )}
          {/* <RelatedInformation /> */}
        </Grid>
        <Grid item xs={4}>
          <TaskList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialHealthScore;
