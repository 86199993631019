import * as yup from "yup";

export const addExpenseSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "The title must consist of at least 3 symbols."),
  amount: yup
    .number()
    .required("Amount is required")
    .positive("Amount must be positive")
    .min(1, "Amount must be bigger than 0"),
  // status: yup.string().required("Status is required"),
  category: yup.string().required("Category is required"),
  lastUpdated: yup.date().required("Last updated are required"),
});
export const addIncomeSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "The title must consist of at least 3 symbols."),
  amount: yup
    .number()
    .required("Amount is required")
    .positive("Amount must be positive")
    .min(1, "Amount must be bigger than 0"),
  // status: yup.string().required("Status is required"),
  lastUpdated: yup.date().required("Last update are required"),
});
export const addAssetSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "The title must consist of at least 3 symbols."),
  type: yup.string().required("Type is required"),
  term: yup.string().required("Term is required"),
  currentValue: yup
    .number()
    .required("Current value is required")
    .positive("Current value must be positive")
    .min(1, "Current value must be bigger than 0"),
  // status: yup.string().required("Status is required"),
  lastUpdated: yup.date().required("Last update are required"),
});
export const addLiabilitySchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "The title must consist of at least 3 symbols."),
  type: yup.string().required("Type is required"),
  term: yup.string().required("Term is required"),
  currentBalance: yup
    .number()
    .required("Current balance is required")
    .positive("Current balance must be positive")
    .min(1, "Current balance must be bigger than 0"),
  // status: yup.string().required("Status is required"),
  lastUpdated: yup.date().required("Last update are required"),
});
