import * as React from "react";
const StarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M12.866 2.996a1 1 0 0 0-1.73 0L8.422 7.673a1.25 1.25 0 0 1-.894.61L2.44 9.05c-.854.13-1.153 1.208-.488 1.76l3.789 3.138c.35.291.516.75.43 1.197l-.99 5.205a1 1 0 0 0 1.447 1.072l4.79-2.522a1.25 1.25 0 0 1 1.165 0l4.79 2.522a1 1 0 0 0 1.447-1.072l-.99-5.205a1.25 1.25 0 0 1 .43-1.197l3.789-3.139c.665-.551.365-1.63-.489-1.759l-5.086-.768a1.25 1.25 0 0 1-.895-.609l-2.714-4.677Z"
    />
  </svg>
);
export default StarIcon;
