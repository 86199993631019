import { Box, Typography } from "@mui/material";
import { SuccessIcon } from "assets/icons";
import React from "react";

const GoalSuccess = ({ goalData }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <SuccessIcon />
      <Typography variant="h4" fontWeight={600} color={"primary.900"} mt={1}>
        Success
      </Typography>
      <Typography variant="h6" color={"primary.700"} fontWeight={400} mt={2}>
        You have created new goal "{goalData.name}"
      </Typography>
      <Typography variant="h6" color={"primary.700"} fontWeight={400} mt={2}>
        Amount: $ {goalData?.goalAmount?.toLocaleString()}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: "8px",
          overflow: "hidden",
          my: 5,
        }}
      >
        <img
          src={URL?.createObjectURL(goalData?.file)}
          alt={goalData.name}
          style={{
            width: "100%",
            height: 220,
            borderRadius: "8px",
            objectFit: "contain",
          }}
        />
      </Box>
    </Box>
  );
};

export default GoalSuccess;
