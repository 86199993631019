export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const scorLineData = {
  labels: months,
  datasets: [
    {
      label: "Average Financial Readiness",
      data: [20, 25, 50, 40, 45, 30, 55, 60, 65, 70, 75, 80],
      borderColor: "#B692F6",
      fill: false,
      pointRadius: 0,
    },
    {
      label: "Median Financial Readiness",
      data: [25, 20, 30, 25, 28, 27, 29, 26, 30, 32, 35, 38],
      borderColor: "#F670C7",
      fill: false,
      pointRadius: 0,
    },
    {
      label: "Distribution of Financial Readiness",
      data: [45, 40, 65, 50, 55, 47, 60, 70, 75, 80, 85, 90],
      borderColor: "#36BFFA",
      fill: false,
      pointRadius: 0,
    },
    {
      label: "Size of Standard Deviation",
      data: [85, 90, 75, 70, 72, 80, 78, 77, 75, 73, 70, 68],
      borderColor: "#FDB022",
      fill: false,
      pointRadius: 0,
    },
  ],
};
export const financialAnalyticsData = {
  labels: months,
  datasets: [
    {
      label: "Expenses",
      data: [
        4000, 7000, 6000, 8000, 6500, 7500, 9000, 8500, 7000, 10000, 9000, 8000,
      ],
      backgroundColor: "#FFA500",
      barPercentage: 0.9,
      borderRadius: 8,
    },
    {
      label: "Incomes",
      data: [
        15000, 20000, 17000, 21000, 16000, 19000, 22000, 20000, 18000, 23000,
        21000, 19000,
      ], //convert it to 5K 10k ..
      backgroundColor: "#32D583",
      barPercentage: 0.9,
      borderRadius: 8,
    },
  ],
};
