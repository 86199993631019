import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { ArrowRightIcon, SmallPlusIcon } from "assets/icons";
import { Button } from "components";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type SummaryCardData = {
  lastUpdated?: string;
  nextPaymentDate?: string;
  percentage: ReactNode;
  type: ReactNode;
  currentValue?: number;
  currentBalance?: number;
  id: number;
  title: string;
  date: Date;
  amount?: { amt: number; currency: string };
}[];
interface SummaryCardProps {
  title: string;
  data: SummaryCardData;
  onAdd: () => void;
}

const SummaryCardForBigData = ({ title, data, onAdd }: SummaryCardProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        borderRadius: 2,
        border: "1px solid #e0e0e0",
        backgroundColor: "#fff",
        py: 4,
        px: 1.5,
        marginBottom: 2,
        height: 428,
        maxHeight: 428,
        overflowY: "hidden",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2.5}
        height={28}
      >
        <Typography variant="h5" fontWeight={600} color={"primary.700"}>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            onClick={onAdd}
            sx={{ borderColor: "primary.main", mr: 7.5, px: 2.5, py: 0.75 }}
          >
            <SmallPlusIcon />
            <Typography variant="h6" fontWeight={600} ml={0.5}>
              Add
            </Typography>
          </Button>
          <IconButton
            onClick={() => {
              navigate(`${title?.toLowerCase()}`);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box>

      <List
        sx={{
          pt: 0,
          maxHeight: 4 * 96,
          overflowY: "auto",
          px: 2.5,
        }}
      >
        {data?.map((item) => (
          <ListItem
            key={item?.id}
            sx={{
              justifyContent: "space-between",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "primary.50",
              mt: 4,
              borderRadius: 1.5,
              p: 4,
              height: 76,
              "&:hover": {
                backgroundColor: "#F8FBFF",
                borderColor: "#D6E3FC",
              },
            }}
            alignItems="flex-start"
          >
            <Box width={"30%"} display={"flex"}>
              <Box>
                <Typography variant="h6" fontWeight={600} color={"primary.700"}>
                  {item.title}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={"primary.500"}
                  mt={1}
                >
                  ${item.currentBalance || item?.currentValue}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h6" fontWeight={400} color={"primary.700"}>
              {item?.type}
            </Typography>
            <Typography
              width={"10%"}
              variant="h6"
              fontWeight={600}
              color={"primary.700"}
            >
              {item?.percentage}
            </Typography>
            <Typography variant="subtitle1" color={"primary.500"} mt={1}>
              {item?.nextPaymentDate
                ? dayjs(item?.nextPaymentDate)?.format("DD MMM YYYY, HH:mm A")
                : item?.lastUpdated
                ? dayjs(item?.lastUpdated)?.format("DD MMM YYYY, HH:mm A")
                : ""}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SummaryCardForBigData;
