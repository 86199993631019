import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { TrashIcon, UploadIcon } from "assets/icons";
import { Input } from "components";
import React, { useState } from "react";
import { Controller } from "react-hook-form";

const GoalFormStepTwo = ({ control, errors, handlePhotoChange, file }) => {
  const [selectedFile, setSelectedFile] = useState(file);
  const [preview, setPreview] = useState(file);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
      handlePhotoChange(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreview(null);
    handlePhotoChange(null);
  };

  return (
    <Box sx={{ gap: 2, mb: 1 }}>
      <Controller
        name="goalAmount"
        control={control}
        render={({ field }) => (
          <Input
            label="Goal amount"
            variant="outlined"
            type="number"
            fullWidth
            // error={!!errors.amount}
            // helperText={errors.amount?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...field}
            sx={{
              "& .MuiInputBase-input": {
                pl: 0.5,
              },
            }}
          />
        )}
      />
      <Controller
        name="color"
        control={control}
        render={({ field }) => (
          <Input
            label="Color"
            variant="outlined"
            type="color"
            fullWidth
            {...field}
            sx={{
              mt: 4,
              "& .MuiInputBase-input": {
                pl: 0.5,
              },
            }}
          />
        )}
      />

      <Typography variant="h6" fontWeight={500} mb={2} mt={6}>
        Photos
      </Typography>
      <Box>
        {!selectedFile ? (
          <Box
            sx={{
              border: "1px dashed #D0D5DD",
              borderRadius: "8px",
              padding: "32px",
              textAlign: "center",
              backgroundColor: "#F9FAFB",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#F3F4F6",
              },
            }}
            onClick={() => document.getElementById("file-input").click()}
          >
            <input
              type="file"
              id="file-input"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <UploadIcon />
            <Typography variant="h6" color="primary.900" fontWeight={500}>
              Click here to upload photo
            </Typography>
            <Typography variant="body2" color="primary.500">
              or drag and drop
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              display: "inline-block",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              src={preview}
              alt="Uploaded Preview"
              style={{
                width: 144,
                height: 144,
                borderRadius: 2,
                objectFit: "contain",
              }}
            />
            <IconButton
              onClick={handleRemoveImage}
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
                backgroundColor: "white",
                color: "#FFF",
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <TrashIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GoalFormStepTwo;
