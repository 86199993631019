import { Box, Paper, Typography } from "@mui/material";
import { Button, SupportStatusChip } from "components";
import { counselorSupportData } from "datas";
import useModal from "hooks/useModal";
import React, { useState } from "react";
import typography from "theme/typography";
import { TCounselorSupportStatus } from "types/types";
import ChangesFromConsuler from "./ChangesFromConsuler";

const CounselorSupport = () => {
  // State to track visibility of changes for each counselor
  const [showDetails, setShowDetails] = useState<number | null>(null);
  const { isVisible, onClose, onOpenWithValue } = useModal();

  // Function to toggle visibility of changes
  const handleClick = (index: number) => {
    setShowDetails(showDetails === index ? null : index); // toggle between expanding/collapsing
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          padding: 4,
          height: 400,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          border: "1px solid #EAECF0",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            fontWeight={600}
            color={"primary.700"}
            mb={6}
          >
            Counselor support
          </Typography>
          {showDetails !== null &&
          counselorSupportData?.[showDetails]?.changes.length > 0 ? (
            <Box mt={2}>
              <Box display={"flex"} alignItems={"center"} mb={3}>
                <Typography
                  width={85}
                  variant="h6"
                  color={"primary.500"}
                  fontWeight={400}
                >
                  Counselor:
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  {counselorSupportData?.[showDetails]?.name}
                </Typography>
              </Box>
              <Box display={"flex"} alignItems={"flex-start"}>
                <Typography variant="h6" color={"primary.500"} fontWeight={400}>
                  Changes:
                </Typography>
                <ol style={{ marginTop: 0 }}>
                  {counselorSupportData?.[showDetails]?.changes?.map(
                    (change, idx) => (
                      <li key={idx}>
                        <Typography variant="h6" color={"primary.700"}>
                          {change}
                        </Typography>
                      </li>
                    )
                  )}
                </ol>
              </Box>
              <Box display={"flex"} alignItems={"center"} mb={2}>
                <Typography
                  width={85}
                  variant="h6"
                  color={"primary.500"}
                  fontWeight={400}
                >
                  Date:
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  {counselorSupportData?.[showDetails]?.date}
                </Typography>
              </Box>
            </Box>
          ) : counselorSupportData?.length > 0 ? (
            counselorSupportData.map((item, index) => (
              <Button
                onClick={() => handleClick(index)}
                key={index}
                variant="text"
                fullWidth
                sx={{ p: 0, mb: 3 }}
                disableRipple
              >
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    p: 2,

                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Box display={"flex"} alignItems={"center"} mb={2}>
                      <Typography
                        width={80}
                        variant="h6"
                        color={"primary.500"}
                        fontWeight={400}
                        textAlign={"left"}
                      >
                        Counselor:
                      </Typography>
                      <Typography variant="h6" color={"primary.700"}>
                        {item.name}
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography
                        width={80}
                        variant="h6"
                        color={"primary.500"}
                        fontWeight={400}
                        textAlign={"left"}
                      >
                        Date:
                      </Typography>
                      <Typography variant="h6" color={"primary.700"}>
                        {item.date}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems="flex-end"
                  >
                    <SupportStatusChip
                      status={item.status as TCounselorSupportStatus}
                    />
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenWithValue(index);
                      }}
                      label="View changes"
                      variant="text"
                      sx={{ mt: 2, backgroundColor: "transparent" }}
                      labelSx={{ ...typography.subtitle1, fontWeight: 600 }}
                      disableTouchRipple
                      //   disabledHoverColor //! if you want to disable hover
                    />
                  </Box>
                </Box>
              </Button>
            ))
          ) : (
            <Typography
              variant="h6"
              fontWeight={400}
              color={"primary.500"}
              sx={{ marginTop: 2 }}
            >
              You haven't contacted a counselor about this Goal yet.
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            label="Send to the Counselor"
            variant="contained"
            fullWidth
            sx={{ height: 48 }}
          />
          <Button
            label="Ask for advice via chat"
            variant="outlined"
            fullWidth
            sx={{ marginTop: 4, height: 48 }}
          />
        </Box>
      </Paper>
      <ChangesFromConsuler
        data={counselorSupportData?.[isVisible as number]?.changes}
        handleCloseModal={onClose}
        open={isVisible || isVisible === 0}
      />
    </>
  );
};

export default CounselorSupport;
