import toast from "react-hot-toast";

type ToastType = "success" | "error" | "warning" | "info";

const toastStyles = {
  success: {
    background: "#4caf50",
    color: "white",
  },
  error: {
    background: "#f44336",
    color: "white",
  },
  warning: {
    background: "#ff9800",
    color: "white",
  },
  info: {
    background: "#2196f3",
    color: "white",
  },
};

export const showToast = (message: string, type: ToastType) => {
  toast(message, {
    style: {
      ...toastStyles[type],
    },
    icon: type === "success" ? "✅" : type === "error" ? "❌" : "⚠️",
  });
};
