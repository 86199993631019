import { Box, Grid, LinearProgress } from "@mui/material";
import {
  DashboardHeader,
  ExpensesCategoriesChart,
  MultiDatePicker,
  RelatedInformation,
  RuleFiftyThirtyTwenty,
} from "components";
import BudgetAnalysis from "components/BasicBudgetAnalysis/BudgetAnalysis";
import { mockRelatedData } from "datas";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  getTransactions,
  getTransactionsLastMonth,
} from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";

const BasicBudgetAnalysis = () => {
  const [dates, setDates] = React.useState([]);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);

  useEffect(() => {
    dispatch(getTransactions({}));
  }, [dispatch]);
  useEffect(() => {
    if (dates.length > 1) {
      dispatch(
        getTransactions({
          startDate: dayjs(dates[0]).format("YYYY-MM-DD"),
          endDate: dayjs(dates[1]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        getTransactionsLastMonth({
          startDate: dayjs(dates[0]).format("YYYY-MM-DD"),
          endDate: dayjs(dates[1]).format("YYYY-MM-DD"),
        })
      );
    }
  }, [dispatch, dates]);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      flex={1}
    >
      <DashboardHeader page="Basic Budget Analysis" goBack>
        <MultiDatePicker
          dates={dates}
          onChange={(newDate) => setDates(newDate)}
          sx={{ top: 78, width: 352 }}
        />
      </DashboardHeader>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={6} mt={6} pb={6} px={10}>
          {/* Budget Analysis Cards */}
          <Grid item xs={8}>
            <BudgetAnalysis />
            <RuleFiftyThirtyTwenty />
          </Grid>
          <Grid item xs={4}>
            <ExpensesCategoriesChart listSx={{ maxHeight: 470 }} />
          </Grid>
          <RelatedInformation data={mockRelatedData} row={3} />
        </Grid>
      )}
    </Box>
  );
};

export default BasicBudgetAnalysis;
