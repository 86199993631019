import { Box, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons";
import { Button } from "components";
import React from "react";
import { useNavigate } from "react-router-dom";

interface IDashboardHeaderProps {
  page: string;
  children?: React.ReactNode;
  goBack?: boolean;
  handleGoBack?: () => void;
}
const DashboardHeader = ({
  page,
  children,
  goBack,
  handleGoBack,
}: IDashboardHeaderProps) => {
  const navigate = useNavigate();
  return (
    <Box
      width={"100%"}
      height={96}
      px={10}
      bgcolor={"white"}
      display={"flex"}
      alignItems={"center"}
    >
      <Box display={"flex"} alignItems={"center"} gap={1} marginRight={"auto"}>
        {goBack && (
          <Button
            onClick={() => (handleGoBack ? handleGoBack() : navigate(-1))}
            variant="text"
          >
            <ArrowLeftIcon />
          </Button>
        )}
        <Typography variant="h3" fontWeight={600}>
          {page}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default DashboardHeader;
