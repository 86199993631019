import { Theme } from "@emotion/react";
import {
  Box,
  IconButton,
  InputAdornment,
  SxProps,
  Typography,
} from "@mui/material";
import { CalendarIcon, ChevronBottomIcon } from "assets/icons";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Calendar, Value } from "react-multi-date-picker";
import Input from "./Input";
import "./multiDatePicker.css";

interface MultiDatePickerProps {
  dates: dayjs.Dayjs[] | string[];
  onChange: (value: dayjs.Dayjs[]) => void;
  format?: string;
  sx?: SxProps<Theme>;
  type?: "default" | "all";
  label?: string;
  mainSx?: SxProps<Theme>;
}
const MultiDatePicker = ({
  dates,
  onChange,
  format = "D MMM, YYYY",
  type = "default",
  label,
  sx,
  mainSx,
}: MultiDatePickerProps) => {
  const formatRange = (value) => {
    if (value?.length === 0) return null;
    const startDate = dayjs(value[0]).format("D");
    const endDate = dayjs(value[value.length - 1]).format(format);
    if (value.length === 1) return value[0]?.format(format);
    if (type === "all") return dayjs(value[0]).format(format) + " - " + endDate;
    return `${startDate} - ${endDate}`;
  };
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: 10,
        mb: 8,
        ...mainSx,
      }}
    >
      {label && (
        <Typography variant="h6" fontWeight={400} color={"primary.800"} mb={1}>
          {label}
        </Typography>
      )}
      <Input
        //add calendar icon to input left
        value={dates?.length > 0 ? formatRange(dates) : []}
        placeholder="Select Date Range"
        sx={{ width: "100%", mb: 1 }}
        //change input text color
        InputProps={{
          readOnly: true,
          style: { color: "#344054", background: "white", height: 44 },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="end" onClick={() => setOpen(!open)}>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setOpen(!open)}>
                <ChevronBottomIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {open && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            top: 220,
            ...sx,
          }}
        >
          <Calendar
            value={dates as unknown as Value[]}
            onChange={onChange as (value: unknown) => void}
            weekDays={["S", "M", "T", "W", "T", "F", "S"]}
            weekStartDayIndex={1}
            range
            rangeHover
            className="primary"
          />
        </Box>
      )}
    </Box>
  );
};

// Formatting the date range to display it in the button

export default MultiDatePicker;
