import * as React from "react";
const BagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.25 5.46v1.42l-1.694.138A2.61 2.61 0 0 0 3.19 9.202c-.041.258-.08.516-.113.775a.298.298 0 0 0 .168.308l.077.036c5.429 2.57 11.93 2.57 17.358 0l.077-.036a.298.298 0 0 0 .169-.308 26.402 26.402 0 0 0-.114-.775 2.61 2.61 0 0 0-2.367-2.184L16.75 6.88V5.46a1.75 1.75 0 0 0-1.49-1.731l-1.22-.183a13.75 13.75 0 0 0-4.08 0l-1.22.183a1.75 1.75 0 0 0-1.49 1.73Zm6.567-.43a12.25 12.25 0 0 0-3.634 0l-1.22.183a.25.25 0 0 0-.213.247v1.315a56.825 56.825 0 0 1 6.5 0V5.46a.25.25 0 0 0-.213-.247l-1.22-.183Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M21.118 12.07a.2.2 0 0 0-.282-.17c-5.571 2.466-12.1 2.466-17.672 0a.2.2 0 0 0-.282.17c-.102 1.911 0 3.831.307 5.726a2.61 2.61 0 0 0 2.367 2.185l1.872.15c3.043.246 6.101.246 9.144 0l1.872-.15a2.61 2.61 0 0 0 2.367-2.185c.307-1.895.41-3.815.307-5.726Z"
    />
  </svg>
);
export default BagIcon;
