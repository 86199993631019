import { Box, Typography } from "@mui/material";
import { CenterModal } from "components";
import React from "react";
const ChangesFromConsuler = ({ data, handleCloseModal, open }) => {
  return (
    <CenterModal
      open={open}
      onClose={handleCloseModal}
      title="Changes from Counselor"
      sx={{ width: 480 }}
    >
      <Box>
        {data?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "8px",
              width: "100%",
              mt: 3,
            }}
          >
            {data?.map((task, index) => (
              <Box
                key={index}
                sx={{
                  padding: "12px",
                  backgroundColor: "primary.100",
                  borderRadius: "8px",
                  display: "flex",
                  gap: 1,
                }}
              >
                <Typography variant="h6" color="primary.900" fontWeight={400}>
                  {index + 1}.
                </Typography>
                <Typography variant="h6" color="primary.900" fontWeight={400}>
                  {task}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography variant="body1" color="primary.700">
            No changes provided by the counselor.
          </Typography>
        )}
      </Box>
    </CenterModal>
  );
};

export default ChangesFromConsuler;
