import React from "react";
import { Box, Typography } from "@mui/material";
import { WarningIcon } from "assets/icons";
import { Button } from "components";

const ReportLoadError = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        textAlign: "center",
        padding: 2,
        mt: 22,
      }}
    >
      <WarningIcon />
      <Typography variant="h3" color={"primary.main"} fontWeight={700} mt={10}>
        Sorry, we couldn't load your reports now
      </Typography>
      <Typography variant="body1" color="primary.800" fontWeight={400} mt={4}>
        Please contact the Administrator for advice or select a different unit
        to view
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ width: 312, mt: 10 }}
        onClick={() => {}}
        label="Refresh"
      />
    </Box>
  );
};

export default ReportLoadError;
