import { Box, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons";
import { ChooseRole } from "components";
import React, { useCallback, useState } from "react";
import { TLoginSteps } from "types/types";
import { Button, LoginForm } from "../../components";

const Login = () => {
  const [step, setStep] = useState<TLoginSteps>("role");

  const changeStep = useCallback(() => {
    setStep("role");
  }, []);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={step === "role" ? "center" : "flex-start"}
      >
        {step !== "role" && (
          <Button
            onClick={changeStep}
            size="large"
            variant="outlined"
            sx={{ borderRadius: 2, gap: 2, mb: 12 }}
          >
            <ArrowLeftIcon />
            <Typography variant="h6" textTransform={"none"}>
              Back
            </Typography>
          </Button>
        )}
        <Typography color={"primary.900"} variant="h2" mb={14}>
          Welcome to Poncho!
        </Typography>
        {step === "role" ? (
          <ChooseRole
            nextStep={() => {
              setStep("login");
            }}
          />
        ) : (
          <LoginForm />
        )}
      </Box>

      <Typography
        width={"100%"}
        variant="h6"
        fontWeight={400}
        sx={{ mt: 2 }}
        textAlign={"center"}
      >
        Don’t have an account?
        <a
          href={"/register"}
          style={{
            color: "#0C425F",
            textDecoration: "underline",
            fontWeight: "bold",
            marginLeft: 4,
          }}
        >
          {"Register"}
        </a>
      </Typography>
    </Box>
  );
};

export default Login;
