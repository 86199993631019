import { Box, Popover } from "@mui/material";
import { Button } from "components";
import React from "react";

interface OptionsPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
}

const OptionsPopover: React.FC<OptionsPopoverProps> = ({
  anchorEl,
  onClose,
  open,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box p={2} display={"flex"} flexDirection={"column"}>
        <Button
          variant="text"
          fullWidth
          onClick={onClose}
          sx={{ justifyContent: "flex-start", width: 243, height: 40 }}
          label="View information"
          labelSx={{ color: "primary.700" }}
        />
        <Button
          variant="text"
          fullWidth
          onClick={onClose}
          sx={{ justifyContent: "flex-start", width: 243, height: 40 }}
          label="Order the financial consultation"
          labelSx={{ color: "primary.700" }}
        />
      </Box>
    </Popover>
  );
};

export default OptionsPopover;
