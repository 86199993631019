import { Grid } from "@mui/material";
import React from "react";
import { useAppSelector } from "store";
import { selectFinancialReport } from "store/reducers/UnitReducer";
import { StatusScoreCardProps } from "types/types";
import ScoreCard from "./ScoreCard";

const ScoreDashboard = ({ data }: { data: StatusScoreCardProps[] }) => {
  const financialReport = useAppSelector(selectFinancialReport);

  return (
    <Grid container spacing={4} sx={{ mt: 4 }}>
      {data.map((score, index) => (
        <Grid item xs={6} key={index}>
          <ScoreCard
            data={{
              ...score,
              percentage:
                index === 0
                  ? financialReport?.financialReadinessDistribution?.notReady ||
                    0
                  : financialReport?.financialReadinessDistribution?.ready || 0,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ScoreDashboard;
