import * as React from "react";
const BookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={47} height={47} x={0.5} y={0.5} fill="#FFFAEB" rx={5.5} />
    <rect width={47} height={47} x={0.5} y={0.5} stroke="#FEC84B" rx={5.5} />
    <path
      stroke="#DC6803"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 31.5a2.5 2.5 0 0 1 2.5-2.5H32m-16 2.5a2.5 2.5 0 0 0 2.5 2.5H32V14H18.5a2.5 2.5 0 0 0-2.5 2.5v15Z"
    />
  </svg>
);
export default BookIcon;
