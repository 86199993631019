import React from "react";
import { Box, Typography, List, ListItem, Checkbox, Chip } from "@mui/material";
import { tasks } from "datas";

const TaskList: React.FC = () => {
  return (
    <Box bgcolor={"white"} p={4} borderRadius={2}>
      <Typography variant="h5">What should be done</Typography>
      <List>
        {tasks.map((task, index) => (
          <ListItem
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: 1,
              borderStyle: "solid",
              borderColor: "primary.50",
              borderRadius: 2,
              mb: 4,
            }}
          >
            <Checkbox sx={{ p: 0 }} edge="start" />
            <Box>
              <Typography variant="h6" color={"primary.900"}>
                {task?.label}
              </Typography>
              <Typography variant="h6" color={"primary.500"} mt={0.5}>
                {task?.description}
              </Typography>
              <Chip
                sx={{ borderRadius: 1.5, height: 24, mt: 2 }}
                label={`+${task?.priority.toString()}`}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TaskList;
