import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

export const getAllUnitsByCommanderId = createAsyncThunk(
  "unitsByCommanderId/getAll",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await request.get(`/units/commander`);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
export const getUnitById = createAsyncThunk(
  "unitsById/get",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await request.get(`/units/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

//create unit

export const createUnit = createAsyncThunk(
  "units/create",
  async (
    data: {
      name: string;
      address: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      phone: string;
      email: string;
      commanderId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("/units", data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// getFinancialReportByUnitId

export const getFinancialReportByUnitId = createAsyncThunk(
  "financialReportByUnitId/get",
  async (
    {
      unitId,
      period,
      dateRange,
    }: { unitId: string; period: string; dateRange: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get(
        `/commander/unit/${unitId}/financial-report`,
        {
          params: {
            period,
            dateRange,
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

//getconsultationReportByUnitId

export const getconsultationReportByUnitId = createAsyncThunk(
  "consultationReport/get",
  async (
    {
      unitId,
      financialStatus,
      consultationStatus,
      statusDuration,
      page = 1,
      limit = 10,
    }: {
      unitId: string;
      financialStatus?: string;
      consultationStatus?: string;
      statusDuration?: string;
      page?: number;
      limit?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get(
        `/commander/unit/${unitId}/consultation-report`,
        {
          params: {
            ...(financialStatus &&
              financialStatus !== "All" && { financialStatus }),
            ...(consultationStatus &&
              consultationStatus !== "All" && { consultationStatus }),
            ...(statusDuration &&
              statusDuration !== "All" && { statusDuration }),
            page,
            limit,
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// getOverallUnitReport

export const getOverallUnitReport = createAsyncThunk(
  "overallUnitReport/get",
  async (unitId: string, { rejectWithValue }) => {
    try {
      const response = await request.get(
        `/commander/unit/${unitId}/overall-report`
      );
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
