const formatPhoneNumber = (value: string) => {
  // Remove all non-digit characters
  const cleaned = value.replace(/\D/g, "");

  // Apply the formatting pattern
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  } else if (cleaned.length <= 3) {
    return `+${cleaned}`;
  } else if (cleaned.length <= 6) {
    return `+${cleaned.slice(0, 1)} ${cleaned.slice(1)}`;
  } else if (cleaned.length <= 10) {
    return `+${cleaned.slice(0, 1)} ${cleaned.slice(1, 4)} ${cleaned.slice(4)}`;
  }
  return value; // Return as-is if it's not in the expected format
};
export default formatPhoneNumber;
