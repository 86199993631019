import * as React from "react";
const SettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox={props?.viewBox || "0 0 16 16"}
    fill="none"
    {...props}
  >
    <g
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props?.strokeWidth || 1.5}
      clipPath="url(#a)"
    >
      <path d="M7.997 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
      <path d="M12.93 10a1.1 1.1 0 0 0 .22 1.213l.04.04a1.333 1.333 0 1 1-1.886 1.886l-.04-.04a1.102 1.102 0 0 0-1.213-.22 1.101 1.101 0 0 0-.667 1.007v.113a1.333 1.333 0 1 1-2.667 0v-.06a1.1 1.1 0 0 0-.72-1.006 1.1 1.1 0 0 0-1.213.22l-.04.04a1.333 1.333 0 0 1-2.176-1.454c.067-.162.165-.31.29-.433l.04-.04a1.1 1.1 0 0 0 .22-1.213 1.1 1.1 0 0 0-1.007-.667h-.114a1.333 1.333 0 0 1 0-2.667h.06A1.1 1.1 0 0 0 3.064 6a1.1 1.1 0 0 0-.22-1.213l-.04-.04a1.333 1.333 0 1 1 1.887-1.887l.04.04a1.1 1.1 0 0 0 1.213.22h.053a1.1 1.1 0 0 0 .667-1.006v-.114a1.333 1.333 0 0 1 2.667 0v.06a1.1 1.1 0 0 0 .666 1.007 1.1 1.1 0 0 0 1.214-.22l.04-.04a1.334 1.334 0 1 1 1.886 1.887l-.04.04a1.1 1.1 0 0 0-.22 1.213v.053a1.1 1.1 0 0 0 1.007.667h.113a1.333 1.333 0 1 1 0 2.667h-.06a1.1 1.1 0 0 0-1.006.666Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SettingsIcon;
