import { Theme } from "@emotion/react";
import { Box, Grid, LinearProgress, SxProps, Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getOverallUnitReport } from "store/actions/UnitsAction";
import { selectOverallUnitId } from "store/reducers/UnitReducer";
import DoughnutCard from "./DoughnutCard";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutCardProps {
  label: string;
  goalColor: string;
  value: number;
}

interface OverallUnitScoreProps {
  data?: DoughnutCardProps;
  cardStyle?: SxProps<Theme>;
}

const OverallScoreForUnit = ({ data, cardStyle }: OverallUnitScoreProps) => {
  // const [overallScoreData, setOverallScoreData] = useState(null);
  const dispatch = useAppDispatch();
  const unitId = useAppSelector(selectOverallUnitId);
  const [loading, setLoading] = useState(true);

  const getOverallScoreData = useCallback(async () => {
    setLoading(true);
    const response = await dispatch(getOverallUnitReport(unitId));
    if (response?.meta?.requestStatus === "fulfilled") {
      // setOverallScoreData(response?.payload);
      // setOverallScoreData(null);
    }
    setLoading(false);
  }, [dispatch, unitId]);
  useEffect(() => {
    if (unitId) getOverallScoreData();
  }, [unitId, getOverallScoreData]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Typography
        variant="h4"
        color={"primary.main"}
        fontWeight={600}
        gutterBottom
      >
        Overall Unit Score
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Present Financial Security"
            goalColor="#B692F6"
            value={68}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Present Freedom of Choice"
            goalColor="#B692F6"
            value={68}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Future Financial Security"
            goalColor="#B692F6"
            value={68}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Future Freedom of Choice"
            goalColor="#B692F6"
            value={68}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverallScoreForUnit;
