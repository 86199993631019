import { Theme } from "@emotion/react";
import {
  Box,
  Table as MuiTable,
  Paper,
  SxProps,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Empty } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams
import { IColumn } from "types/types";
import Pagination from "./Pagination";

interface ITable {
  data: unknown[];
  total?: number;
  columns: IColumn[];
  sx?: SxProps<Theme>;
  onRowClick?: (row: { [key: string]: number }) => void;
  handleChange?: (value: {
    page?: number | string;
    limit?: number | string;
  }) => void;
}

const Table = ({
  data,
  columns,
  sx,
  onRowClick,
  total,
  handleChange,
}: ITable) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get `page` and `limit` from the URL, fallback to defaults
  const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
  const limitFromUrl = parseInt(searchParams.get("limit") || "10", 10);

  const [page, setPage] = useState(pageFromUrl); // Default to page from URL
  const [rowsPerPage, setRowsPerPage] = useState(limitFromUrl); // Default to limit from URL
  const [sortConfig, setSortConfig] = useState<{
    orderBy: string | null;
    direction: "asc" | "desc";
  }>({
    orderBy: null,
    direction: "asc",
  });

  const pageCount = useMemo(() => {
    const count = Math.ceil(total / rowsPerPage);
    if (count === 1 && page > 1) setPage(count);
    return count;
  }, [rowsPerPage, page, total]);

  // Handle page change and update URL
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    // Update the page in the URL
    handleChange
      ? handleChange({
          page: newPage.toString(),
          limit: rowsPerPage.toString(),
        })
      : setSearchParams({
          page: newPage.toString(),
          limit: rowsPerPage.toString(),
        });
  };

  // Handle sort change
  const handleSort = (columnId: string) => {
    const isAsc =
      sortConfig.orderBy === columnId && sortConfig.direction === "asc";
    setSortConfig({
      orderBy: columnId,
      direction: isAsc ? "desc" : "asc",
    });
  };

  // Sorted data based on current sort configuration
  const sortedData = useMemo(() => {
    if (!sortConfig.orderBy) return data;
    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.orderBy!];
      const bValue = b[sortConfig.orderBy!];

      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  // Sync the page and limit from URL when component mounts
  useEffect(() => {
    setPage(pageFromUrl);
    setRowsPerPage(limitFromUrl);
  }, [pageFromUrl, limitFromUrl]);

  return (
    <Paper sx={{ bgcolor: "#F7F7F7", ...sx }}>
      <Box
        sx={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "primary.50",
          borderRadius: 2,
          overflow: "hidden",
          borderTopWidth: 0,
          flex: 1,
        }}
      >
        {sortedData?.length === 0 ? (
          <Empty />
        ) : (
          <TableContainer>
            <MuiTable>
              <TableHead>
                <TableRow sx={{ borderColor: "red" }}>
                  {columns?.map((column) => (
                    <TableCell
                      sx={{
                        px: 6,
                        py: 3,
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        borderTopColor: "primary.50",
                        borderBottomColor: "primary.50",
                        bgcolor: "background.default",
                        color: "primary.400",
                      }}
                      key={column.id}
                      sortDirection={
                        sortConfig.orderBy === column.id
                          ? sortConfig.direction
                          : false
                      }
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={sortConfig.orderBy === column.id}
                          direction={
                            sortConfig.orderBy === column.id
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData?.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() =>
                      sortConfig.orderBy ? handleSort(sortConfig.orderBy) : null
                    }
                    sx={{
                      minHeight: 72,
                      height: 72,
                      "&:hover": {
                        "& td": {
                          backgroundColor: "primary.100",
                          transition: "background-color 0.3s ease-in-out",
                        },
                      },
                    }}
                  >
                    {columns?.map((column) => (
                      <TableCell
                        sx={{
                          px: 6,
                          py: 3,
                          bgcolor: "white",
                          color: "primary.500",
                          ...column.style,
                        }}
                        key={column.id}
                        onClick={() =>
                          column?.handleClick
                            ? column?.handleClick(row)
                            : onRowClick &&
                              onRowClick(
                                row as { [key: string]: number } | undefined
                              )
                        }
                      >
                        {column.render ? column.render(row) : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
        )}

        {pageCount > 1 && sortedData.length > 0 && (
          <Pagination
            count={pageCount}
            page={page}
            onPageChange={handleChangePage}
          />
        )}
      </Box>
    </Paper>
  );
};

export default Table;
