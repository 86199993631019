import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { CalculatorForm, CalculatorResult, DashboardHeader } from "components";
import { useParams } from "react-router-dom";

const Calculate = () => {
  const { name } = useParams();

  const [results, setResults] = useState(undefined);

  const handleCalculation = (calculatedResults) => {
    setResults(calculatedResults);
  };

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      flex={1}
    >
      <DashboardHeader goBack page={name} />
      <Grid container spacing={6} mt={6} pb={6} px={10} height={"100%"}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <CalculatorForm onCalculate={handleCalculation} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CalculatorResult results={results} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calculate;
