import * as React from "react";
const SmallArrowRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#475467"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.336 8h9.333m0 0L8.003 3.335m4.666 4.667-4.666 4.666"
    />
  </svg>
);
export default SmallArrowRightIcon;
