import { Box, Card, CardContent, Typography } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "assets/icons";
import React from "react";
import DecreasedChart from "../../assets/images/decreased-chart.png";
import IncreasedChart from "../../assets/images/increased-chart.png";

interface FinancialCardProps {
  title: string;
  value: string;
  change: string;
  changeType: "increase" | "decrease";
  period: "month" | "year";
  chart?: boolean;
  valueSx?: React.CSSProperties;
}
const FinancialCard = ({
  title,
  value,
  change,
  changeType,
  period,
  chart,
  valueSx,
}: FinancialCardProps) => {
  return (
    <Card sx={{ boxShadow: 0, border: "1px solid #EAECF0" }}>
      <CardContent>
        <Typography variant="body1" color={"primary.700"}>
          {title}
        </Typography>
        <Box display={"flex"} justifyContent={"space-between"} mt={4}>
          <Box>
            <Typography variant="h2" mb={4} sx={valueSx}>
              {value}
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              {changeType === "increase" ? <ArrowUpIcon /> : <ArrowDownIcon />}
              <Typography
                variant="h6"
                color="primary.500"
                fontWeight={500}
                ml={1}
              >
                <Typography display={"inline-block"} color="success.700" mr={2}>
                  {change}
                </Typography>
                vs last {period}
              </Typography>
            </Box>
          </Box>
          {chart &&
            (changeType === "increase" ? (
              <img src={IncreasedChart} alt="Increased Chart" />
            ) : (
              <img src={DecreasedChart} alt="Decreased Chart" />
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};
export default FinancialCard;
