import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import colors from "theme/colors";

const LiabilityHeader = ({ data }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Paper elevation={0} sx={{ p: 4, border: "1px solid #EAECF0" }}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h6" color="primary.500" fontWeight={400}>
                Total left to pay
              </Typography>
              <Typography
                variant="h3"
                fontWeight={600}
                color="primary.900"
                mt={2}
              >
                ${data?.totalPay}
              </Typography>
            </Box>
            <Box textAlign="right">
              <Typography variant="h6" color="primary.500" fontWeight={400}>
                Next payment
              </Typography>
              <Typography
                variant="h5"
                fontWeight={600}
                color="primary.900"
                mt={2}
              >
                {data?.nextPaymentDate ||
                  dayjs().add(1, "day").format("DD MMMM")}
              </Typography>
            </Box>
          </Box>

          <Box mt={4}>
            <Typography variant="h6" color="primary.500" fontWeight={400}>
              Current amount
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" color={"primary.700"} fontWeight={600}>
                ${data?.totalCurrentAmount}
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                sx={{ marginTop: 1 }}
              >
                ${data?.totalPercentage}%
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={data?.totalPercentage}
              sx={{
                height: 8,
                borderRadius: 8,
                marginTop: 2,
                background: colors.primary["50"],
              }}
            />
          </Box>
        </Paper>
      </Grid>

      {/* Right Section: Support Section */}
      <Grid item xs={5}>
        <Paper
          elevation={0}
          sx={{
            p: 4,
            border: "1px solid #EAECF0",
            height: 173,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color={"primary.700"} fontWeight={600}>
            Personal Financial Counselor support
          </Typography>
          <Box display={"flex"} gap={4}>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "none",
              }}
              disabled
            >
              Ask for advice via chat
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                textTransform: "none",
                color: "textSecondary",
                borderColor: "textSecondary",
                "&:disabled": {
                  backgroundColor: "#F2F4F7",
                },
              }}
              disabled
            >
              Share financial info
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LiabilityHeader;
