import * as React from "react";
const CoinsStockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#1075F2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.45 2.815c.001-.818-1.668-1.481-3.724-1.481C2.669 1.334 1 1.998 1 2.815m0 0c0 .819 1.668 1.482 3.726 1.482S8.45 3.634 8.45 2.815v5.749M1 2.815v8.89c0 .818 1.668 1.48 3.726 1.48.997 0 1.898-.157 2.566-.41M1.001 5.777C1 6.597 2.668 7.26 4.726 7.26s3.726-.663 3.726-1.482m-1.115 4.02c-.672.262-1.594.425-2.611.425C2.668 10.223 1 9.56 1 8.741m11.684.236a3.32 3.32 0 0 1 0 4.714 3.367 3.367 0 0 1-4.742 0 3.32 3.32 0 0 1 0-4.714 3.367 3.367 0 0 1 4.742 0Z"
    />
  </svg>
);
export default CoinsStockIcon;
