import { Avatar, Box, LinearProgress, Typography } from "@mui/material";
import {
  AmountSelect,
  Button,
  DashboardHeader,
  Input,
  MultiDatePicker,
  RightSidebar,
  Select,
  Table,
  TransferInfo,
} from "components";
import { expenseCategories } from "datas/select";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"; // Use useSearchParams
import { useAppDispatch, useAppSelector } from "store";
import {
  getTransactions,
  updateExpense,
} from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import {
  selectExpenses,
  selectTransactionsInfo,
} from "store/reducers/TransactionReducer";
import typography from "theme/typography";
import { IColumn, TAmountFilterType } from "types/types";
import { showToast } from "utils/showToast";

const Expenses = () => {
  const { isVisible, onOpenWithValue, onClose } = useModal();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Manage search params
  const expenses = useAppSelector(selectExpenses);
  const { totalExpenses } = useAppSelector(selectTransactionsInfo);
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const category = searchParams.get("category");
  const amountFilterType = searchParams.get("amountFilterType");
  const amount1 = searchParams.get("amount1");
  const amount2 = searchParams.get("amount2");
  const page = +(searchParams.get("page") || 1);
  const limit = +(searchParams.get("limit") || 10);
  const loading = useAppSelector(getIsLoading);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dispatch = useAppDispatch();

  // Update URL parameters based on filter changes
  const updateURLParams = (key, value) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    startDate && searchParams.set("startDate", startDate);
    endDate && searchParams.set("endDate", endDate);
    setSearchParams(searchParams, { replace: true });
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // Handle category selection change
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    updateURLParams("category", value);
  };

  // Handle amount filter change
  const handleAmountFilterChange = (newFilter) => {
    updateURLParams("amountFilterType", newFilter.type);
    updateURLParams("amount1", newFilter.amount1);
    if (newFilter.type === "between") {
      updateURLParams("amount2", newFilter.amount2);
    } else {
      updateURLParams("amount2", "");
    }
  };

  // Handle date filter change
  const handleDateChange = (newDate) => {
    const startDate = newDate[0];
    const endDate = newDate[1];
    if (startDate && endDate) {
      updateURLParams("startDate", startDate?.format("YYYY-MM-DD"));
      updateURLParams("endDate", endDate?.format("YYYY-MM-DD"));
      updateURLParams("page", "1"); // Reset to page 1 on date change
    }
  };

  // Load transactions on component mount or URL parameter changes
  useEffect(() => {
    dispatch(
      getTransactions({
        page: page,
        limit: limit,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        categoriesFilter: category ? category : undefined,
        amountFilterType: amountFilterType?.toUpperCase() as TAmountFilterType,
        singleValue: amountFilterType !== "between" ? +amount1 : undefined,
        rangeValue:
          amountFilterType === "between" ? [+amount1, +amount2] : undefined,
      })
    );
  }, [
    dispatch,
    startDate,
    endDate,
    category,
    searchParams,
    amountFilterType,
    amount1,
    amount2,
    page,
    limit,
  ]);

  const columns: IColumn[] = [
    {
      id: "title",
      label: "Title",
      render: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={row.avatar} alt={row.title} />
          <Typography style={{ marginLeft: "10px" }} color={"primary.700"}>
            {row.title}
          </Typography>
        </div>
      ),
      handleClick(row) {
        onOpenWithValue(row?.accountId || row?.id);
      },
      style: { maxWidth: 400 },
    },
    {
      id: "category",
      render(row) {
        //render select to change category
        return (
          <Select
            sx={{ width: 240 }}
            options={expenseCategories}
            value={
              selectedCategory?.id === row.accountId
                ? selectedCategory.category
                : row.category
            }
            onChange={async (e) => {
              // Get the selected category value
              const selectedCat = e.target.value;

              // Dispatch the updateExpense action with the new category
              const response = await dispatch(
                updateExpense({
                  id: row?.transactionId || row?.id || row?.accountId,
                  data: {
                    category: selectedCat,
                    title: row.title,
                    amount: row.amount?.amt,
                    lastUpdated: new Date().toISOString(),
                  },
                })
              );
              if (response?.meta.requestStatus === "fulfilled") {
                setSelectedCategory({
                  id: row?.accountId,
                  category: selectedCat,
                });
                showToast("Category updated successfully", "success");
              }
            }}
          />
        );
      },
      label: "Category",
    },
    {
      id: "date",
      label: "Date & Time",
      render: (row) => (
        <Typography
          variant="h6"
          fontWeight={400}
          color={"primary.500"}
          whiteSpace={"pre"}
        >{`${dayjs(row?.date).format("DD MMM YYYY, HH:mm A")}`}</Typography>
      ),
      handleClick(row) {
        onOpenWithValue(row?.accountId || row?.id);
      },
      style: { color: "primary.500" },
    },
    {
      id: "amount",
      label: "Amount",
      render: (row) => (
        <Typography
          variant="body1"
          fontWeight={600}
          color={"primary.700"}
          whiteSpace={"pre"}
        >{`$ ${row.amount?.amt}`}</Typography>
      ),
      handleClick(row) {
        onOpenWithValue(row?.accountId || row?.id);
      },
      style: { ...typography.body1, color: "primary.700" },
    },
  ];

  return (
    <Box width={"100%"}>
      <DashboardHeader
        goBack
        page="Expenses"
        handleGoBack={() => navigate("/financial-statement")}
      >
        <Box width={426}>
          <Input
            fullWidth
            placeholder="Search by title"
            search
            value={search}
            onChange={handleSearchChange}
          />
        </Box>
      </DashboardHeader>
      <Box sx={{ px: 10, pb: 6, mt: -2 }}>
        <Box mb={5} display={"flex"} alignItems={"center"} gap={6}>
          <Select
            value={category}
            label="Categories"
            placeholder="Select category"
            options={expenseCategories}
            onChange={handleCategoryChange}
            sx={{
              width: 252,
              height: 44,
              borderColor: "red",
              fontWeight: 400,
              color: "primary.500",
            }}
          />
          <Box sx={{ width: 311 }}>
            <MultiDatePicker
              dates={startDate && endDate ? [startDate, endDate] : []}
              onChange={handleDateChange}
              type="all"
              label="Date Range"
            />
          </Box>

          <AmountSelect onApply={handleAmountFilterChange} />
          <Button
            variant="outlined"
            onClick={() => {
              setSearchParams({}); // Clear all URL search parameters
              setSearch(""); // Reset search state
              setSelectedCategory(null); // Reset selected category
            }}
            sx={{ width: 180, height: 44, marginTop: 5.5 }}
            label="Clear filters"
            disabledBgColor="primary.100"
            disabledTextColor="primary.300"
          />
        </Box>
        {loading ? (
          <LinearProgress />
        ) : (
          <Table
            total={totalExpenses}
            data={expenses?.filter((item) =>
              item?.title?.toLowerCase()?.includes(search?.toLowerCase())
            )}
            columns={columns}
            handleChange={({ page, limit }) => {
              updateURLParams("page", page.toString());
              updateURLParams("limit", limit.toString());
            }}
          />
        )}
        <RightSidebar
          open={isVisible as boolean}
          onClose={onClose}
          title="Transfer"
          subTitle={dayjs(
            expenses?.find(
              (item) => item?.accountId === isVisible || item?.id === isVisible
            )?.date
          ).format("DD MMM YYYY, HH:mm A")}
        >
          <TransferInfo
            data={expenses?.find(
              (item) => item?.accountId === isVisible || item?.id === isVisible
            )}
          />
        </RightSidebar>
      </Box>
    </Box>
  );
};

export default Expenses;
