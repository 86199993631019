import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

export const createGoal = createAsyncThunk(
  "goals/create",
  async (
    //Argument of type 'FormData' is not assignable to parameter of type
    goalData: FormData,
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post("/goals", goalData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
export const getGoals = createAsyncThunk(
  "goals/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get("/goals");
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
export const updateGoal = createAsyncThunk(
  "goals/update",
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: FormData;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.patch(`/goals/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteGoal = createAsyncThunk(
  "goals/delete",
  async (goalId: string, { rejectWithValue }) => {
    try {
      await request.delete(`/goals/${goalId}`); // API call to delete a goal
      return goalId; // Return the data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle the error
      return rejectWithValue(errorMessage); // Reject with an error message
    }
  }
);
export const getGoal = createAsyncThunk(
  "goals/getById",
  async (goalId: string, { rejectWithValue }) => {
    try {
      const response = await request.get(`/goals/${goalId}`); // API call to get a goal
      return response.data; // Return the data on success
    } catch (error) {
      const errorMessage = handleError(error); // Handle the error
      return rejectWithValue(errorMessage); // Reject with an error message
    }
  }
);

export const goalActions = {
  createGoal,
  getGoals,
  updateGoal,
  deleteGoal,
};
