import * as React from "react";
const WalletIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m21.01 10.171.002 3.623c-.025.345-.058.69-.098 1.034a1.273 1.273 0 0 1-1.123 1.106c-1.84.205-3.743.205-5.583 0a1.272 1.272 0 0 1-1.123-1.106c-.22-1.879-.22-3.777 0-5.656a1.272 1.272 0 0 1 1.123-1.105 25.345 25.345 0 0 1 5.583 0c.587.065 1.055.53 1.123 1.105.038.333.07.666.096 1Zm-4.01.33a1.5 1.5 0 1 0 0 2.999 1.5 1.5 0 0 0 0-3Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M20.403 6.04c.156.27-.136.57-.445.536a26.845 26.845 0 0 0-5.917 0 2.772 2.772 0 0 0-2.446 2.422 25.846 25.846 0 0 0 0 6.005 2.772 2.772 0 0 0 2.446 2.421c1.95.218 3.966.218 5.917 0 .31-.035.605.269.448.54a4.971 4.971 0 0 1-3.78 2.45l-.652.068c-3.31.349-6.65.326-9.956-.068l-.432-.052a3.931 3.931 0 0 1-3.431-3.383 37.641 37.641 0 0 1 0-9.957 3.931 3.931 0 0 1 3.431-3.384l.432-.051a44.65 44.65 0 0 1 9.956-.069l.652.069a4.96 4.96 0 0 1 3.777 2.453Z"
    />
  </svg>
);
export default WalletIcon;
