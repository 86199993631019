import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl, Typography } from "@mui/material";
import {
  Button,
  CustomDatePicker,
  Input,
  Select,
  ToogleButtons,
} from "components";
import { assetsTypes } from "datas/select";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { createAsset } from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { showToast } from "utils/showToast";
import { addAssetSchema } from "validation/financialStatementValidation";

const AddAssetForm = ({ handleClose, onSuccess }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(addAssetSchema),
    defaultValues: { term: "Short Term" },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const response = await dispatch(createAsset(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      showToast("Asset added successfully", "success");
      onSuccess();
      handleClose();
    }
  };

  const handleTermChange = (event, newTerm) => {
    if (newTerm !== null) {
      setValue("term", newTerm);
    }
  };

  return (
    <FormControl
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%", gap: 6 }}
    >
      {/* Title Input */}
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Title"
            placeholder="e.g., Savings Account"
            fullWidth
            error={!!errors.title}
            helperText={errors.title?.message}
            InputProps={{ style: { height: 40 } }}
          />
        )}
      />

      {/* Type Select */}
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label="Type"
            placeholder="Select the option"
            options={assetsTypes}
            sx={{
              width: "100%",
              height: 40,
              borderColor: "red",
              fontWeight: 400,
              color: "primary.500",
            }} // Adjust to your actual options
          />
        )}
      />

      {/* Term Selection */}
      <Box sx={{ mt: 2, width: "100%" }}>
        <Typography variant="h6">Term</Typography>
        <ToogleButtons
          list={[
            { value: "Short Term", label: "Short Term" },
            { value: "Long Term", label: "Long Term" },
          ]}
          value={watch("term") || "Short Term"}
          onChange={handleTermChange}
        />
      </Box>

      {/* Last Update Date Picker */}
      <Controller
        name="lastUpdated"
        control={control}
        render={({ field }) => (
          <CustomDatePicker
            selectedDate={
              dayjs(watch("lastUpdated")) as Dayjs | null | undefined
            }
            onChange={field.onChange}
            format="DD MMM YYYY HH:mm"
            label="Last update"
            time
          />
        )}
      />

      {/* Current Balance Input */}
      <Controller
        name="currentValue"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Current value"
            placeholder="e.g., $x,xxx.xx"
            type="number"
            fullWidth
            error={!!errors.currentValue}
            helperText={errors.currentValue?.message}
            InputProps={{ style: { height: 40 } }}
          />
        )}
      />

      {/* Continue Button */}
      <Button
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: 4 }}
        disabled={!isDirty || !isValid}
        loading={loading}
        onClick={handleSubmit(onSubmit)}
      >
        Continue
      </Button>
    </FormControl>
  );
};

export default AddAssetForm;
