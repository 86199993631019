import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { PlusIcon, QuestionIcon } from "assets/icons";
import {
  Button,
  CreateGoalModal,
  DashboardHeader,
  DoughnutCard,
  FinancialCard,
  TabBar,
} from "components";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getAssets } from "store/actions/FinancialStatementsAction";
import { getGoals } from "store/actions/GoalAction";
import { selectGoals } from "store/reducers/GoalReducer";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { selectAssets } from "store/reducers/TransactionReducer";
import checkGoalComplete from "utils/checkGoalComplete";

const Goals = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { isVisible, onOpen, onClose } = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goals = useAppSelector(selectGoals);
  const { data: bankAccounts } = useAppSelector(selectAssets);
  const loading = useAppSelector(getIsLoading);

  const possibleAccounts = bankAccounts?.filter(
    (account) => account.currentValue !== null
  );

  // Calculate Account Balance
  const accountBalance = useMemo(() => {
    return possibleAccounts?.reduce(
      (total, account) => total + account.currentValue,
      0
    );
  }, [possibleAccounts]);

  // Set Estimated Monthly Income (hardcoded as $800 for now)
  const estimatedMonthlyIncome = (accountBalance / 500)?.toFixed(2);

  // Calculate Goals Achieved This Year
  const goalsAchieved = useMemo(() => {
    return goals.filter(
      (goal) =>
        checkGoalComplete(
          possibleAccounts?.find(
            (account) => account?.id === goal?.goalColor?.split("?")[1]
          )?.currentValue || 0,
          goal.goalAmount
        ) >= 100
    ).length;
  }, [goals, possibleAccounts]);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: "Personal goals" },
    { label: "Goals from Counselor", icon: <QuestionIcon />, disabled: true },
    { label: "Goals history" },
  ];

  const filteredGoals = useMemo(() => {
    if (activeTab === 0) {
      return goals;
    } else if (activeTab === 2) {
      return goals.filter((goal, index) => {
        const isCompleted = dayjs(goal?.dateToCompete).isBefore(dayjs());
        const isAchieved =
          checkGoalComplete(
            possibleAccounts?.find(
              (account) => account?.id === goal?.goalColor?.split("?")[1]
            )?.currentValue || 0,
            goal?.goalAmount
          ) >= 100;
        return isCompleted || isAchieved;
      });
    }
  }, [activeTab, goals, possibleAccounts]);

  useEffect(() => {
    dispatch(getGoals());
    bankAccounts?.length < 11 && dispatch(getAssets({ limit: 1000 }));
  }, [dispatch, bankAccounts?.length]);

  return (
    <Box width={"100%"}>
      <DashboardHeader page="Goals" />

      {/* Financial Cards */}
      <Box px={10}>
        <Grid container spacing={6} mt={2}>
          <Grid item xs={4}>
            <FinancialCard
              title="Account balance"
              value={`$${accountBalance.toFixed(2)}`}
              change="40%"
              changeType="increase"
              period="month"
              chart
            />
          </Grid>
          <Grid item xs={4}>
            <FinancialCard
              title="Est monthly income"
              value={`$${estimatedMonthlyIncome}`}
              change="20%"
              changeType="increase"
              period="month"
              chart
            />
          </Grid>
          <Grid item xs={4}>
            <FinancialCard
              title="Goal achieved this year"
              value={goalsAchieved.toString()}
              change="10%"
              changeType="decrease"
              period="year"
              chart
            />
          </Grid>
        </Grid>

        <Box mt={10}>
          <TabBar tabs={tabs} value={activeTab} onChange={handleTabChange} />
        </Box>
        {loading ? (
          <LinearProgress style={{ marginTop: 10 }} />
        ) : (
          <Grid container spacing={6} mt={2}>
            {filteredGoals?.map((goal, index) => (
              <Grid key={index} item xs={3} height={350}>
                <DoughnutCard
                  value={
                    checkGoalComplete(
                      possibleAccounts?.find(
                        (account) =>
                          account?.id === goal?.goalColor?.split("?")[1]
                      )?.currentValue || 0,
                      goal?.goalAmount
                    ) || `0`
                  }
                  key={goal?.id}
                  label={goal?.name}
                  {...goal}
                  details
                  goalColor={goal?.goalColor?.split("?")[0]}
                  onClickDetails={() => {
                    navigate(`/goals/${goal?.id}`, {
                      state: {
                        accountBalance:
                          possibleAccounts?.find(
                            (account) =>
                              account?.id === goal?.goalColor?.split("?")[1]
                          )?.currentValue || 0,
                        estimatedMonthlyIncome: estimatedMonthlyIncome,
                      },
                    });
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={3} height={350}>
              <Button
                onClick={onOpen}
                sx={{
                  height: "100%",
                  width: "100%",
                  border: "2px dashed #bdbdbd",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <PlusIcon />
                <Typography
                  variant="body1"
                  mt={2}
                  fontWeight={600}
                  color={"primary.900"}
                >
                  Add new goal
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <CreateGoalModal open={isVisible as boolean} handleClose={onClose} />
    </Box>
  );
};

export default Goals;
