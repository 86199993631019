import React, { useState, useEffect } from "react";
import Router from "./router";
import { Provider } from "react-redux";
import { store } from "./store";
import "App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { Toaster } from "react-hot-toast";
import { NetworkError } from "components";

function App() {
  const queryClient = new QueryClient();
  const [hasNetworkError, setHasNetworkError] = useState(false);

  // Effect to detect network errors
  useEffect(() => {
    const handleOffline = () => {
      setHasNetworkError(true);
    };
    const handleOnline = () => {
      setHasNetworkError(false);
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  // Function to refresh the page
  const handleRetry = () => {
    if (navigator.onLine) {
      setHasNetworkError(false);
    } else {
      setHasNetworkError(true);
    }
  };

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          {hasNetworkError ? (
            <NetworkError onRetry={handleRetry} />
          ) : (
            <Router />
          )}
        </ThemeProvider>
        <Toaster toastOptions={{ duration: 1500 }} />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
