import * as React from "react";
const MenuCalendarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.75 4a.75.75 0 0 0-1.5 0v1.816a3.375 3.375 0 0 0-2.873 2.899l-.087.653-.042.332a.493.493 0 0 0 .493.55h16.517a.493.493 0 0 0 .492-.55c-.013-.11-.027-.222-.042-.332l-.086-.653a3.375 3.375 0 0 0-2.873-2.899V4a.75.75 0 0 0-1.5 0v1.668a47.91 47.91 0 0 0-8.5 0V4ZM20.944 12.226a.494.494 0 0 0-.496-.476H3.55a.494.494 0 0 0-.495.476c-.06 1.807.05 3.619.329 5.41a3.01 3.01 0 0 0 2.678 2.532l1.193.118c3.155.31 6.333.31 9.488 0l1.193-.118a3.01 3.01 0 0 0 2.679-2.532c.279-1.791.388-3.603.329-5.41Z"
    />
  </svg>
);
export default MenuCalendarIcon;
