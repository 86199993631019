import { Box, Grid } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons";
import { AccountsList, Button, ConnectedAccountsList } from "components";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

const ConnectAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const role = location.state?.role;
  const [connectedAccounts, setConnectedAccounts] = useState([
    { id: 1, name: "My Pay", number: "**** 4906", balance: "$3,234.54" },
  ]);

  return (
    <Box
      width={"100%"}
      pr={30}
      pl={55}
      py={"5%"}
      display={"flex"}
      flexDirection={"column"}
      bgcolor={"#F7F7F7"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Button
          startIcon={<ArrowLeftIcon />}
          variant="outlined"
          sx={{ width: 94, height: 40 }}
          onClick={() =>
            navigate("/register", {
              state: { step: "successRegister" },
            })
          }
        >
          Back
        </Button>
        <img src={Logo} alt="Logo" style={{ width: 112, height: 50 }} />
      </Box>

      <Grid container spacing={4} mt={7} flex={1}>
        <Grid item xs={8}>
          <AccountsList
            onNext={() => {
              if (role && role === "FamilyMember") {
                navigate("/login", {
                  replace: true,
                });
              } else {
                navigate("/register", {
                  state: { step: "invitation" },
                });
              }
            }}
          />
        </Grid>

        {/* Right Side: Connected Accounts */}
        <Grid item xs={4}>
          <ConnectedAccountsList
            data={connectedAccounts}
            setData={setConnectedAccounts}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConnectAccount;
