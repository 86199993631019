import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  getAllUnitsByCommanderId,
  getFinancialReportByUnitId,
  getUnitById,
} from "store/actions/UnitsAction";

// Create goal async thunk

interface IRosters {
  id: string;
  name: string;
  image: {
    url: string;
  }[];
}
interface IFinancialReport {
  averageFinancialReadinessScore: number;
  medianFinancialReadinessScore: number;
  standardDeviation: number;
  financialReadinessDistribution: {
    notReady: number;
    marginallyReady: number;
    ready: number;
  };
}
interface UnitState {
  unit: {
    id: string;
    name: string;
    rosters: IRosters;
  };
  dateRange: string;
  financialReport: IFinancialReport;
  overallUnitId: string;
}

const initialState: UnitState = {
  unit: null,
  financialReport: null,
  overallUnitId: null,
  dateRange: null,
};

// Goals slice
const unitSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    setOverallUnitId: (state, action) => {
      state.overallUnitId = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Unit
    builder.addCase(getUnitById.fulfilled, (state, { payload }) => {
      state.unit = payload?.data;
    });
    builder.addCase(
      getAllUnitsByCommanderId.fulfilled,
      (state, { payload }) => {
        state.unit = payload?.[0];
        if (!state.overallUnitId || !payload?.[0]) {
          state.overallUnitId = payload?.[0]?.id;
        }
      }
    );
    builder.addCase(
      getFinancialReportByUnitId.fulfilled,
      (state, { payload }) => {
        state.financialReport = payload;
      }
    );
  },
});

const selectUnitState = (state: RootState) => state.unit;

export const selectUnit = createSelector(
  selectUnitState,
  (state) => state.unit
);

export const selectOverallUnitId = (state: RootState) =>
  state.unit.overallUnitId;
export const selectFinancialReport = (state: RootState) =>
  state.unit.financialReport;
export const selectDateRange = (state: RootState) => state.unit.dateRange;

export const { setOverallUnitId, setDateRange } = unitSlice.actions;
export default unitSlice.reducer;
