import { Box, LinearProgress, Typography } from "@mui/material";
import { Button, DashboardHeader, FinancialStatus, Table } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getUnitById } from "store/actions/AdminAction";
import { selectSelectedUnit } from "store/reducers/AdminReducer";
import typography from "theme/typography";
import { IColumn } from "types/types";

const Rosters = () => {
  const data = useAppSelector(selectSelectedUnit);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const unitId = useParams()?.id;
  const page = +searchParams.get("page") || 1;
  const limit = +searchParams.get("limit") || 10;

  const columns: IColumn[] = [
    {
      id: "firstName",
      label: "First & Last Name",
      style: { ...typography.body1 },
      sortable: true,
      render: (row) => (
        <Typography sx={{ ...typography.body1 }}>
          {row?.user?.firstName} {row?.user?.lastName}
        </Typography>
      ),
    },
    {
      id: "rank",
      label: "Rank",
      style: { ...typography.body1 },
      sortable: true,
    },
    {
      id: "finance_status",
      label: "Financial Readiness Status",
      render: (row) => <FinancialStatus status={row.finance_status} />,
    },
    {
      id: "status_duration",
      label: "Financial Readiness Score",
      style: { ...typography.body1 },
    },
    {
      id: "training",
      label: "Current on Training",
      render: (row) => <Typography>{row.training ? "Yes" : "No"}</Typography>,
      style: { ...typography.body1 },
    },
    {
      id: "training_status",
      label: "Training Status",
      render: (row) => (
        <Button
          variant="contained"
          label={row.training_status}
          disabled={row.training_status === "Completed"}
          sx={{
            ...typography.body1,
            width: 106,
            height: 36,
            bgcolor: "success.500",
          }}
        />
      ),
    },
  ];
  const fetchUnitsData = useCallback(async () => {
    setLoading(true);
    await dispatch(getUnitById(unitId));
    setLoading(false);
  }, [dispatch, unitId]);

  useEffect(() => {
    fetchUnitsData();
  }, [fetchUnitsData]);

  const paginatedData = data?.rosters?.slice((page - 1) * limit, page * limit);

  return (
    <Box>
      <DashboardHeader
        goBack
        page="Rosters"
        handleGoBack={() => navigate("/units")}
      />
      <Box px={6} py={8}>
        {loading ? (
          <LinearProgress />
        ) : (
          <Table
            data={paginatedData}
            columns={columns}
            total={data?.rosters?.length}
          />
        )}
      </Box>
    </Box>
  );
};

export default Rosters;
