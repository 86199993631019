import { Box, Typography } from "@mui/material";
import { CopyIcon } from "assets/icons";
import { Button } from "components";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch } from "store";
import { spouseInvitation } from "store/actions/AuthAction";

const InviteFamilyMember = () => {
  const [inviteToken, setInviteToken] = useState(null);
  const dispatch = useAppDispatch();

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://dev.poncho.us/invitation/${inviteToken}`
    );
    toast("Copied to clipboard", { style: { color: "green" } });
  };
  const generateInviteToken = useCallback(async () => {
    const response = await dispatch(spouseInvitation());
    if (response?.meta?.requestStatus === "fulfilled") {
      setInviteToken(response?.payload?.inviteToken);
    }
  }, [dispatch]);
  useEffect(() => {
    generateInviteToken();
  }, [generateInviteToken]);

  return (
    <Box>
      <Typography
        variant="h6"
        color={"primary.500"}
        fontWeight={400}
        sx={{ marginBottom: 2, pr: 2 }}
      >
        Once your spouse accepts the invitation and signs up for Poncho, you can
        set up accesses and communicate with each other.
      </Typography>

      <Typography mt={6} variant="h6" color={"primary.700"}>
        Share this invitation link with your family member
      </Typography>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        height={40}
        bgcolor={"primary.100"}
        px={3.5}
        py={2.5}
        mt={2}
        borderRadius={2}
      >
        <Typography
          variant="h6"
          fontWeight={400}
          color={"primary.900"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {`https://dev.poncho.us/invitation/${inviteToken}`}
        </Typography>
        <Button
          onClick={handleCopy}
          variant="text"
          sx={{ width: 20, height: 20, p: 0 }}
        >
          <CopyIcon />
        </Button>
      </Box>

      <Button
        onClick={() => {}}
        variant="contained"
        fullWidth
        size="large"
        label="Share"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default InviteFamilyMember;
