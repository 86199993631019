import { Box, Typography } from "@mui/material";
import { CopyIcon } from "assets/icons";
import { Button } from "components";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch } from "store";
import { spouseInvitation } from "store/actions/AuthAction";

const SpouseInvitation = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const [invite, setInvite] = useState(false);
  const [inviteToken, setInviteToken] = useState(false);

  const handleYes = useCallback(async () => {
    if (!invite) {
      setInvite(true);
      const response = await dispatch(spouseInvitation());
      if (response?.meta?.requestStatus === "fulfilled") {
        setInviteToken(response?.payload?.inviteToken);
      }
    }
  }, [invite, dispatch]);

  return (
    <Box width={"100%"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Button
          onClick={() => setInvite(false)}
          sx={{
            width: "48%",
            borderColor: !invite ? "primary.main" : "primary.300",
          }}
          label="No"
        />
        <Button
          onClick={handleYes}
          sx={{
            width: "48%",
            borderColor: invite ? "primary.main" : "primary.300",
          }}
          label="Yes"
        />
      </Box>
      {invite && (
        <>
          <Typography mt={6} variant="h6" color={"primary.700"}>
            Share this invitation link with your family member
          </Typography>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            height={40}
            bgcolor={"primary.100"}
            px={3.5}
            py={2.5}
            mt={2}
            borderRadius={2}
          >
            <Typography
              variant="h6"
              fontWeight={400}
              color={"primary.900"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {`https://dev.poncho.us/invitation/${inviteToken}`}
            </Typography>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://dev.poncho.us/invitation/${inviteToken}`
                );
                toast("Copied to clipboard", { style: { color: "green" } });
              }}
              variant="text"
              sx={{ width: 20, height: 20, p: 0 }}
            >
              <CopyIcon />
            </Button>
          </Box>
        </>
      )}
      <Button
        onClick={() => {
          onNext();
          localStorage.setItem("invite", `${invite}`);
        }}
        variant="contained"
        fullWidth
        size="large"
        label="Continue"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default SpouseInvitation;
