import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import React from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { Button, Input } from "../";
import { addressSchema } from "validation/authValidation";

interface IAddress {
  onNext: () => void;
}

interface IAddressFormInputs {
  addr1: string;
  addr2?: string;
  city: string;
  stateProv: string;
  postalCode: string;
  country: string;
}

const Address = ({ onNext }: IAddress) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<IAddressFormInputs>({
    resolver: yupResolver(addressSchema) as Resolver<IAddressFormInputs>,
    mode: "onChange",
  });

  const onSubmit = (data: IAddressFormInputs) => {
    const localData = JSON.parse(localStorage.getItem("addressData"));
    const mergedData = { ...localData, ...data };
    localStorage.setItem("addressData", JSON.stringify(mergedData));
    onNext();
  };

  return (
    <Box width={"100%"}>
      <FormControl
        fullWidth
        sx={{ gap: 6, mb: 1 }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="addr1"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="Address Line 1"
              placeholder="123 Main St"
              error={!!errors.addr1}
              helperText={errors.addr1?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="addr2"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              label="Address Line 2"
              placeholder="Apt 4B"
              error={!!errors.addr2}
              helperText={errors.addr2?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="City"
              placeholder="New York"
              error={!!errors.city}
              helperText={errors.city?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="stateProv"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="State/Province"
              placeholder="NY"
              error={!!errors.stateProv}
              helperText={errors.stateProv?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="postalCode"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="Postal Code"
              placeholder="10001"
              error={!!errors.postalCode}
              helperText={errors.postalCode?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              required
              label="Country"
              placeholder="United States"
              error={!!errors.country}
              helperText={errors.country?.message}
              {...field}
            />
          )}
        />
      </FormControl>
      <Button
        onClick={handleSubmit(onSubmit)}
        type="submit"
        disabled={!isDirty || !isValid}
        variant="contained"
        fullWidth
        label="Continue"
        sx={{ mt: 10, height: 48 }}
      />
    </Box>
  );
};

export default Address;
