import React from "react";
import { Tabs, Tab, Box } from "@mui/material";

interface TabBarProps {
  tabs: { label: string; disabled?: boolean; icon?: React.ReactElement }[];
  value: number | string;
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void;
  containerSx?: object;
  tabContainerSx?: object;
  tabSx?: object;
  selectedColor?: string;
}
const TabBar = ({
  tabs,
  value,
  onChange,
  containerSx,
  tabContainerSx,
  tabSx,
  selectedColor,
}: TabBarProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        "& .MuiTabs-root": {
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          height: 40,
          minHeight: 40,
          overflow: "hidden",
          ...containerSx,
        },
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        TabIndicatorProps={{ style: { display: "none" } }} // Remove the default indicator
        sx={{
          "& .MuiTab-root": {
            minWidth: "auto",
            padding: "6px 20px",
            textTransform: "none",
            fontWeight: "bold",
            color: "#000", // Default color

            "&.Mui-selected": {
              backgroundColor: selectedColor || "#003366", // Background for the selected tab
              color: "white", // Text color for selected tab
            },
            "& .MuiTab-iconWrapper": {
              ml: 2,
            },
          },
          ...tabContainerSx,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            disabled={tab.disabled}
            icon={tab.icon}
            iconPosition="end"
            sx={{
              textTransform: "none",
              fontSize: 14,
              fontWeight: "500!important",
              height: 40,
              minHeight: 40,
              color: value === index ? "#003366" : "gray",
              backgroundColor: value === index ? "#E7EEF8" : "transparent",
              borderRadius:
                index === 0
                  ? "12px 0 0 12px"
                  : index === tabs.length - 1
                  ? "0 12px 12px 0"
                  : "0",
              "&.Mui-selected": {
                color: "#003366",
              },
              border: value !== index && "1px solid #EAECF0",
              ...tabSx,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabBar;
