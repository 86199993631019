import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Popover,
} from "@mui/material";
import { FilterIcon } from "assets/icons";
import typography from "theme/typography";
import { Button } from "components";

interface FilterOption {
  value: string;
  label: string;
}

interface Filter {
  label: string;
  options: FilterOption[];
}

interface ToolbarFilters {
  financialStatus: Filter;
  trainingStatus: Filter;
}
interface IFilterListProps {
  filters: ToolbarFilters;
  currentFilters: { financialStatus: string; trainingStatus: string };
  onApplyFilters: (selectedFilters: {
    financialStatus: string;
    trainingStatus: string;
  }) => void;
}

const FilterList = ({
  filters,
  onApplyFilters,
  currentFilters,
}: IFilterListProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(
    currentFilters || {
      financialStatus: "All",
      trainingStatus: "All",
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleApply = () => {
    onApplyFilters(selectedFilters);
    handleClose();
  };

  const handleClearAll = () => {
    setSelectedFilters({
      financialStatus: "All",
      trainingStatus: "All",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<FilterIcon />}
        sx={{
          width: 117,
          borderColor: "primary.200",
          backgroundColor: "white",
        }}
        label="Filters"
        labelSx={{ ...typography.body1 }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: 300 }}>
          {Object.entries(filters).map(([key, { label, options }]) => (
            <Box key={key} sx={{ mb: 2 }}>
              <Typography variant="h6" color="secondary.600">
                {label}
              </Typography>
              <FormGroup>
                {options?.map(({ value, label }) => (
                  <FormControlLabel
                    key={value}
                    control={
                      <Checkbox
                        checked={selectedFilters[key] === value}
                        onChange={() =>
                          handleChange({ target: { name: key, value } })
                        }
                        name={key}
                        value={value}
                        sx={{
                          "&.Mui-checked": {
                            color: "success.800",
                          },
                        }}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
            </Box>
          ))}
          <Button
            variant="contained"
            color="primary"
            label="Apply"
            fullWidth
            onClick={handleApply}
            sx={{
              mt: 2,
            }}
            labelSx={{ fontWeight: 600 }}
          />
          <Button
            label="Clear All"
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleClearAll}
            sx={{ mt: 2 }}
            labelSx={{ fontWeight: 600 }}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default FilterList;
