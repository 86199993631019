import * as React from "react";
const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={71}
    height={70}
    fill="none"
    {...props}
  >
    <rect width={64} height={64} x={3.5} y={3} fill="#FEE4E2" rx={28} />
    <rect
      width={64}
      height={64}
      x={3.5}
      y={3}
      stroke="#FEF3F2"
      strokeWidth={6}
      rx={28}
    />
    <path
      fill="#D92D20"
      d="M31.29 30.293a1 1 0 0 1 1.414 0l3.293 3.293 3.293-3.293a1 1 0 0 1 1.414 1.414L37.411 35l3.293 3.293a1 1 0 0 1-1.414 1.414l-3.293-3.293-3.293 3.293a1 1 0 0 1-1.414-1.414L34.583 35l-3.293-3.293a1 1 0 0 1 0-1.414Z"
    />
    <path
      fill="#D92D20"
      fillRule="evenodd"
      d="M29.753 24.025a56.67 56.67 0 0 1 12.488 0c2.436.272 4.402 2.19 4.689 4.64.492 4.209.492 8.46 0 12.67-.287 2.45-2.253 4.368-4.689 4.64a56.67 56.67 0 0 1-12.488 0c-2.436-.272-4.402-2.19-4.689-4.64a54.537 54.537 0 0 1 0-12.67c.287-2.45 2.253-4.368 4.689-4.64Zm12.266 1.987a54.67 54.67 0 0 0-12.044 0c-1.526.17-2.748 1.375-2.924 2.885a52.532 52.532 0 0 0 0 12.206c.177 1.51 1.398 2.714 2.924 2.884a54.67 54.67 0 0 0 12.044 0c1.526-.17 2.748-1.375 2.924-2.884a52.543 52.543 0 0 0 0-12.206c-.176-1.51-1.398-2.714-2.924-2.885Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ErrorIcon;
