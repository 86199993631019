import * as React from "react";
const PrevIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#3D687F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="m8 1.838-5 5 5 5"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .838h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default PrevIcon;
