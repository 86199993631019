import * as React from "react";
const OutlinedCheckboxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    {props?.filled ? (
      <path
        fill="#0C425F"
        d="M7.316 3.769a42.502 42.502 0 0 1 9.367 0c1.16.13 2.177.756 2.82 1.666L11.5 13.44l-2.47-2.47a.75.75 0 1 0-1.06 1.061l3 3a.75.75 0 0 0 1.06 0l8.115-8.115c.023.11.04.22.054.333.37 3.157.37 6.346 0 9.503-.215 1.837-1.69 3.276-3.516 3.48a42.512 42.512 0 0 1-9.367 0c-1.826-.204-3.3-1.643-3.516-3.48a40.902 40.902 0 0 1 0-9.503c.215-1.837 1.69-3.275 3.516-3.48Z"
      />
    ) : (
      <>
        <path
          fill={props?.fill || "#0C425F"}
          d="M7.483 5.26A2.486 2.486 0 0 0 5.29 7.422a39.401 39.401 0 0 0 0 9.154 2.486 2.486 0 0 0 2.193 2.163c2.977.333 6.057.333 9.033 0a2.486 2.486 0 0 0 2.193-2.163 39.41 39.41 0 0 0 .216-6.58.2.2 0 0 1 .058-.152l1.04-1.04a.198.198 0 0 1 .338.125 40.902 40.902 0 0 1-.162 7.821c-.215 1.837-1.69 3.276-3.516 3.48a42.512 42.512 0 0 1-9.367 0c-1.826-.204-3.3-1.643-3.516-3.48a40.902 40.902 0 0 1 0-9.503c.215-1.837 1.69-3.275 3.516-3.48a42.502 42.502 0 0 1 9.367 0 3.989 3.989 0 0 1 1.76.64c.1.066.11.21.025.295l-.803.803a.211.211 0 0 1-.25.033 2.487 2.487 0 0 0-.899-.28 41.003 41.003 0 0 0-9.033 0Z"
        />
        <path
          fill={props?.fill || "#0C425F"}
          d="M21.03 6.03a.75.75 0 0 0-1.06-1.06l-8.47 8.47-2.47-2.47a.75.75 0 1 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l9-9Z"
        />
      </>
    )}
  </svg>
);
export default OutlinedCheckboxIcon;
