import { Box, FormControl, Link } from "@mui/material";
import React from "react";
import Logo from "../../assets/images/logo.png";
import Input from "components/custom/Input";
import { Controller, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formatPhoneNumber from "utils/formatPhoneNumber";
import { loginAccountModalSchema } from "validation/authValidation";
import Button from "components/custom/Button";

interface LoginAccountModalInputs {
  phone_number: string;
  password: string;
}
const LoginAccountModal = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginAccountModalInputs>({
    resolver: yupResolver(
      loginAccountModalSchema
    ) as Resolver<LoginAccountModalInputs>,
    mode: "onChange",
  });

  const onSubmit = async (data: LoginAccountModalInputs) => {};
  return (
    <Box p={6} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <img src={Logo} alt="Logo" style={{ width: 77, height: 38 }} />
      <Box width={"100%"} mt={6}>
        <FormControl
          fullWidth
          sx={{ gap: 6, mb: 1 }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <Input
                type="tel"
                required
                label="Enter Phone Number"
                placeholder="+1 234 567 9121"
                error={!!errors.phone_number}
                helperText={errors.phone_number?.message}
                {...field}
                onChange={(e) => {
                  const formatted = formatPhoneNumber(e.target.value);
                  field.onChange(formatted); // Update form value
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                required
                label="Enter Password"
                error={!!errors.password}
                helperText={errors.password?.message}
                type="password"
                {...field}
              />
            )}
          />
        </FormControl>
        <Link
          // href="/forgot"
          display={"block"}
          textAlign={"right"}
          underline="none"
          sx={{ fontWeight: 600 }}
        >
          Forgot Password?
        </Link>
        <Button
          onClick={handleSubmit(onSubmit)}
          // loading={loading}
          type="submit"
          disabled={!isDirty || !isValid}
          variant="contained"
          fullWidth
          label="Connect Account"
          sx={{ mt: 6, height: 48 }}
        />
      </Box>
    </Box>
  );
};

export default LoginAccountModal;
