import * as React from "react";
const BulbIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M4.5 9.766C4.5 5.737 7.874 2.5 12 2.5c4.126 0 7.5 3.237 7.5 7.266 0 2.354-.973 4.478-2.795 5.817a.571.571 0 0 0-.228.32c-.054.228-.113.489-.174.769a.301.301 0 0 1-.294.238H7.991a.301.301 0 0 1-.294-.238c-.061-.28-.12-.541-.174-.77a.57.57 0 0 0-.228-.319C5.473 14.243 4.5 12.12 4.5 9.766ZM8.4 18.41a.302.302 0 0 0-.296.353c.093.556.162 1.053.183 1.397.063 1.041.83 1.899 1.855 2.12l.196.043a7.877 7.877 0 0 0 3.324 0l.196-.043c1.025-.221 1.792-1.079 1.855-2.12.021-.343.09-.84.184-1.397a.302.302 0 0 0-.297-.354H8.4Z"
    />
  </svg>
);
export default BulbIcon;
