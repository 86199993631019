import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

const getFamilyMembers = createAsyncThunk(
  "spouses/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(`/spouses`);
      return response?.data?.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);

const deleteFamilyMember = createAsyncThunk(
  "spouses/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      await request.delete(`/spouses/${id}`);
      return id;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
export { getFamilyMembers, deleteFamilyMember };
