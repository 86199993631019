import { Sidebar } from "../components";
import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface AppLayoutProps {
  children?: ReactNode;
}
const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <Box
      bgcolor={"#F7F7F7"}
      display={"flex"}
      width={"100%"}
      minHeight={"100vh"}
    >
      <Sidebar />
      <Box width={"calc(100% - 280px)"} ml={70}>
        {children}
      </Box>
    </Box>
  );
};

export default AppLayout;
