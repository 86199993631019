import { Box, Typography } from "@mui/material";
import { WarningIcon } from "assets/icons";
import { Button } from "components";
import React from "react";

const EmptyNotification = ({ onRetry }: { onRetry?: () => void }) => {
  return (
    <Box
      bgcolor={"white"}
      borderTop={"1px solid #EAECF0"}
      pt={22}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      height={"calc(100vh - 96px)"}
    >
      <WarningIcon />
      <Typography my={10} variant="h3" fontWeight={700} color="primary.main">
        No New Notifications Right Now!
      </Typography>
      <Button
        sx={{ width: 312, height: 40 }}
        variant="contained"
        color="primary"
        onClick={onRetry}
      >
        Refresh
      </Button>
    </Box>
  );
};

export default EmptyNotification;
