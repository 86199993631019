import * as React from "react";
const TrashIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#0C425F"
      d="M5.283 2.141h-.142a.143.143 0 0 0 .142-.143v.143h5.43v-.143c0 .079.063.143.142.143h-.143v1.286h1.286V1.998c0-.63-.513-1.143-1.143-1.143H5.14c-.63 0-1.143.513-1.143 1.143v1.429h1.285V2.14Zm9 1.286H1.713a.57.57 0 0 0-.572.571v.572c0 .078.064.143.142.143h1.079l.441 9.339c.029.609.532 1.09 1.141 1.09h8.107c.611 0 1.113-.48 1.141-1.09l.441-9.34h1.08a.143.143 0 0 0 .142-.142v-.572a.57.57 0 0 0-.572-.571Zm-2.37 10.428H4.083L3.65 4.713h8.696l-.432 9.142Z"
    />
  </svg>
);
export default TrashIcon;
