import { Theme } from "@emotion/react";
import { Box, Grid, LinearProgress, SxProps, Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getOverallUnitReport } from "store/actions/UnitsAction";
import { selectOverallUnitId } from "store/reducers/UnitReducer";
import DoughnutCard from "./DoughnutCard";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutCardProps {
  label: string;
  goalColor?: string;
  value?: number;
}

interface OverallUnitScoreProps {
  data?: DoughnutCardProps[];
  cardStyle?: SxProps<Theme>;
  unitId?: string;
}

const OverallUnitScore = ({ unitId }: OverallUnitScoreProps) => {
  const [overallScoreData, setOverallScoreData] = useState(null);
  const dispatch = useAppDispatch();
  const storeUnitId = useAppSelector(selectOverallUnitId);
  const unit = unitId || storeUnitId;

  const [loading, setLoading] = useState(true);

  const getOverallScoreData = useCallback(async () => {
    setLoading(true);
    const response = await dispatch(getOverallUnitReport(unit));
    if (response?.meta?.requestStatus === "fulfilled") {
      setOverallScoreData(response?.payload);
    }
    setLoading(false);
  }, [dispatch, unit]);
  useEffect(() => {
    if (unit) getOverallScoreData();
  }, [unit, getOverallScoreData]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Typography
        variant="h4"
        color={"primary.main"}
        fontWeight={600}
        gutterBottom
      >
        Overall Unit Score
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Readiness Levels"
            goalColor="#B692F6"
            value={(+overallScoreData?.readinessLevel)?.toFixed(1)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Numbers of Individuals"
            goalColor="#0086C9"
            value={(+overallScoreData?.numbersOfIndividuals
              ?.percentage)?.toFixed(1)}
            target={overallScoreData?.numbersOfIndividuals?.total}
            done={overallScoreData?.numbersOfIndividuals?.active}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Report Levels"
            increase={overallScoreData?.reportLevels}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DoughnutCard
            label="Suffered Individuals"
            increase={overallScoreData?.sufferedIndividuals}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverallUnitScore;
