import { CoinsIcon, MaximizeIcon, ShieldIcon, TaxIcon } from "assets/icons";

//Roles
const roles = [
  "Client",
  "Commander",
  "Super Admin",
  "Counselor",
  "Family Member",
];
// const paymentfinancialStatus = ["Completed", "Pending"];

const financialStatementsTypes = [
  {
    name: "Milestones",
    Icon: MaximizeIcon,
    disabled: true,
  },
  {
    name: "Loans",
    Icon: CoinsIcon,
    disabled: true,
  },
  {
    name: "Insurance",
    Icon: ShieldIcon,
    disabled: true,
  },
  {
    name: "Taxes",
    Icon: TaxIcon,
    disabled: true,
  },
];

const unitOptions = [
  { value: "My Units", label: "My Units" },
  { value: "Unit 1", label: "Unit 1" },
  { value: "Unit 2", label: "Unit 2" },
  { value: "Unit 3", label: "Unit 3" },
  { value: "Company", label: "Company" },
];
const periodOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Annual", label: "Annual" },
  { value: "Multi-year", label: "Multi-year" },
];
const permissions = [
  {
    id: 1,
    label: "Personal Financial Statement",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    label: "Goals",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 3,
    label: "Appointments",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];
const notificationStatusOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Read",
    value: "Read",
  },
  {
    label: "Unread",
    value: "Unread",
  },
];

export {
  roles,
  financialStatementsTypes,
  unitOptions,
  periodOptions,
  permissions,
  notificationStatusOptions,
};
