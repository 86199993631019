import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { ArrowRightIcon, CoinsStockIcon } from "assets/icons";
import { DashboardHeader, Input, TopicItem } from "components";
import { summaries, topics } from "datas";
import React from "react";

const Education = () => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      flex={1}
    >
      <DashboardHeader page="Education" />
      <Grid container px={10} py={6} spacing={6} height={"100%"}>
        <Grid item xs={12} height={"100%"}>
          <Box
            p={4}
            border={"1px solid #EAECF0"}
            borderRadius={1.75}
            bgcolor={"white"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Definitions</Typography>
              <Input
                search
                sx={{
                  width: 436,
                }}
                placeholder="Search"
              />
            </Box>
            <Typography
              mt={8}
              variant="h6"
              fontWeight={400}
              color={"primary.500"}
            >
              Popular topics to learn
            </Typography>
            <Grid container spacing={4} mt={0}>
              {topics.map((item, index) => (
                <TopicItem
                  key={index}
                  {...item}
                  sx={{ height: 94, py: 3 }}
                  small={true}
                />
              ))}
            </Grid>
          </Box>
          <Box
            p={4}
            border={"1px solid #EAECF0"}
            borderRadius={1.75}
            bgcolor={"white"}
            mt={6}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography variant="h5" fontWeight={600} color={"primary.600"}>
                  Summaries
                </Typography>
                <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                  Materials based on your financial situation
                </Typography>
              </Box>
              <ArrowRightIcon />
            </Box>
            <Grid container spacing={5.5} mt={1}>
              {summaries.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <Card
                    sx={{
                      bgcolor: "#F9FAFC",
                      height: 258,
                      p: 2,
                      boxShadow: 0,
                      border: "1px solid #EAECF0",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "100%",
                        height: 158,
                        objectFit: "cover",
                        borderRadius: 1.5,
                      }}
                      image={item.image}
                      alt={item.title}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ p: 0, pt: 4 }}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Typography variant="h6">{item.title}</Typography>
                          <Chip
                            sx={{
                              width: 94,
                              height: 20,
                              color: "#1075F2",
                              borderRadius: 3,
                              bgcolor: "#F0F7FF",
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#ABC2F9",
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                            icon={<CoinsStockIcon />}
                            label="Finances"
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          color="primary.500"
                          fontWeight={400}
                          mt={2}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {item.description}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        {/* <Grid item xs={4} maxHeight={"90vh"}>
          <NotificationListSmall />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Education;
