import * as React from "react";
const ZapIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#ECFDF3" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#6CE9A6" rx={5.5} />
    <path
      stroke="#027A48"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16.667 9.334-6.667 8h6l-.667 5.333 6.667-8h-6l.667-5.333Z"
    />
  </svg>
);
export default ZapIcon;
