import { Box, Typography } from "@mui/material";
import { Button, Input } from "components";
import React, { useState } from "react";

const DeleteFamilyMember = ({ name, onConfirm }) => {
  const [reason, setReason] = useState("");

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={400}
        pr={10}
        color={"primary.500"}
        sx={{ marginBottom: 5 }}
      >
        Enter a reason why you want to remove {name} from your family member
      </Typography>

      <Input
        fullWidth
        label="Enter the reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Divorce"
        variant="outlined"
        sx={{ marginBottom: 8 }}
      />

      <Button
        variant="contained"
        fullWidth
        label="Confirm"
        onClick={onConfirm}
        sx={{ height: 48 }}
      />
    </Box>
  );
};

export default DeleteFamilyMember;
