// src/components/NotificationItem.js
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import {
  BookmarkIcon,
  ChevronRightIcon,
  OutlinedCheckboxIcon,
} from "assets/icons";
import dayjs from "dayjs";
import React from "react";
import toast from "react-hot-toast";
import { useAppDispatch } from "store";
import { updateNotificationStatus } from "store/actions/NotificationAction";
import { showBottomToast } from "utils/showBottomToast";

const CommanderNotificationItem = ({
  id,
  name,
  created_at,
  message,
  read,
  saved,
  resolved,
  tabValue,
}) => {
  const dispatch = useAppDispatch();

  // Function to handle bookmark toggle
  const handleBookmarkToggle = async () => {
    const response = await dispatch(
      updateNotificationStatus({
        notificationId: id,
        statusData: {
          saved: !saved,
        },
        remove: tabValue === 1 ? true : false,
      })
    );
    if (response.meta.requestStatus === "fulfilled") {
      showBottomToast(
        `${
          saved
            ? "You removed notification from the list “Saved notifications”!"
            : "You added notification to the list “Saved notifications”!"
        }`,
        "success"
      );
    }
  };
  const handleResolveToggle = async () => {
    const response = await dispatch(
      updateNotificationStatus({
        notificationId: id,
        statusData: {
          resolved: !resolved,
        },
        remove: tabValue === 2 ? true : false,
      })
    );
    if (response.meta.requestStatus === "fulfilled") {
      showBottomToast(
        `${
          resolved
            ? "You removed notification from the list “Resolved notifications”!"
            : "You added notification to the list “Resolved notifications”!"
        }`,
        "success",
        resolved
          ? "The notification disappears from the saved list, but you can still find it in the resolved notifications."
          : undefined,
        () => {
          dispatch(
            updateNotificationStatus({
              notificationId: id,
              statusData: {
                resolved: resolved,
              },
              remove: tabValue !== 0,
            })
          );

          toast.dismiss();
        }
      );
    }
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        padding: 4,
        boxShadow: 0,
        backgroundColor: !read ? "secondary.300" : "white",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box mb={3}>
          <Typography variant="subtitle1" fontWeight={600} color="primary.800">
            {dayjs(created_at).format("MMMM, YYYY HH:mm A")}
          </Typography>
          <Typography
            my={1}
            variant="body1"
            color={"primary.main"}
            fontWeight={600}
          >
            {name}
          </Typography>
          <Typography variant="h6" fontWeight={400} color={"primary.main"}>
            {message}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"flex-end"}
          minWidth={266}
          ml={4}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "white",
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: "0px 1px 2px 0px #1018280D",
              borderColor: "primary.200",
              height: 40,
            }}
          >
            View Resources
          </Button>
          <IconButton
            onClick={handleBookmarkToggle}
            sx={{
              mx: 3,
              border: "1px solid",
              borderColor: "primary.200",
              borderRadius: 2,
              height: 40,
              width: 40,
              backgroundColor: "white",
            }}
          >
            <BookmarkIcon fill={saved} />
          </IconButton>
          <IconButton
            onClick={handleResolveToggle}
            sx={{
              border: "1px solid",
              borderColor: "primary.200",
              borderRadius: 2,
              height: 40,
              width: 40,
              backgroundColor: "white",
            }}
          >
            <OutlinedCheckboxIcon filled={resolved} />
          </IconButton>
        </Box>
      </Box>
      <Accordion
        sx={{
          boxShadow: 0,
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            transform: "rotate(0deg)",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronRightIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ width: 160, height: 20, minHeight: "20px!important", pl: 0 }}
        >
          <Typography variant="h6" color={"primary.800"}>
            Recommendations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            •{" "}
            <Link
              href="https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/"
              target="_blank"
              rel="noopener"
              variant="h6"
              color={"primary.700"}
            >
              https://www.militaryonesource.mil/health-wellness/prevention-care/when-a-service-member-may-be-at-risk-for-suicide/
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default CommanderNotificationItem;
