import * as React from "react";
const CompassIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={47} height={47} x={0.5} y={0.5} fill="#ECFDF3" rx={5.5} />
    <rect width={47} height={47} x={0.5} y={0.5} stroke="#6CE9A6" rx={5.5} />
    <path
      stroke="#039855"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24 34c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
    />
    <path
      stroke="#039855"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m28.24 19.76-2.12 6.36-6.36 2.12 2.12-6.36 6.36-2.12Z"
    />
  </svg>
);
export default CompassIcon;
