import { Box, Card, CardContent, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { IncreaseIcon } from "assets/icons";
import { Button } from "components";
import React from "react";
import { Doughnut } from "react-chartjs-2";

interface DoughnutCardProps {
  label: string;
  value?: number | string;
  goalColor?: string;
  increase?: string;
  target?: number | string;
  done?: number | string;
  details?: boolean;
  cardStyle?: SxProps<Theme>;
  amountStyle?: SxProps<Theme>;
  onClickDetails?: () => void;
}

const DoughnutCard = ({
  label,
  value,
  goalColor,
  increase,
  cardStyle,
  target,
  done,
  details,
  amountStyle,
  onClickDetails,
}: DoughnutCardProps) => {
  const createDoughnutData = (value, color) => ({
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [color, "#fff"],
        borderWidth: 0,
        cutout: "60%",
        borderRadius: value >= 100 ? 0 : 7,
      },
    ],
  });

  const createDoughnutOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
  });
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        borderRadius: 2,
        boxShadow: "none",
        border: "1px solid #E0E0E0",
        ...cardStyle,
      }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body1" color={"primary.main"} align="center">
          {label}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
          <Box
            sx={{
              position: "relative",
              width: 160,
              height: 160,
              backgroundColor: "white",
              boxShadow: "0px 1.6px 20.03px 0px #00000014",
              borderRadius: "50%",
              p: 2,
            }}
          >
            <Doughnut
              data={createDoughnutData(value, goalColor)}
              options={createDoughnutOptions()}
            />

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {value && (
                <Typography
                  fontSize={24}
                  lineHeight={38}
                  color="primary.900"
                  fontWeight={700}
                >
                  {value}%
                </Typography>
              )}
              {increase && (
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <IncreaseIcon />
                  <Typography
                    variant={"h3"}
                    fontWeight={700}
                    color={"primary.main"}
                  >
                    {increase}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {target && done && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography variant="body1" color="primary.700" sx={amountStyle}>
              {done} /
            </Typography>
            <Typography
              ml={1}
              variant="body1"
              color="primary.500"
              sx={amountStyle}
            >
              {target}
            </Typography>
          </Box>
        )}
        {details && (
          <Button
            label="Details"
            variant="outlined"
            onClick={onClickDetails}
            sx={{ mt: 5, height: 48 }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default DoughnutCard;
