import { Box, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TForgetSteps } from "types/types";
import { ArrowLeftIcon } from "../../assets/icons";
import { Button, ForgotForm, NewPasswordForm, Otp } from "../../components";

const Forgot = () => {
  const [step, setStep] = useState<TForgetSteps>("info");
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  const changeStep = useCallback((newStep: TForgetSteps) => {
    setStep(newStep);
  }, []);

  const handleBack = useCallback(() => {
    if (step === "newPassword") {
      changeStep("otp");
    } else if (step === "otp") {
      changeStep("info");
    } else {
      navigate(-1);
    }
  }, [step, changeStep, navigate]);

  return (
    <Box width={"100%"}>
      <Button
        onClick={handleBack}
        size="large"
        variant="outlined"
        sx={{ borderRadius: 2, gap: 2 }}
      >
        <ArrowLeftIcon />
        <Typography variant="h6" textTransform={"none"}>
          Back
        </Typography>
      </Button>
      <Typography variant="h2" mt={12} mb={14}>
        Forgot password
      </Typography>
      {step === "info" ? (
        <ForgotForm
          onNext={(data: string) => {
            changeStep("otp");
            setUserInfo(data);
          }}
        />
      ) : step === "otp" ? (
        <Otp onNext={() => changeStep("newPassword")} source={userInfo} />
      ) : (
        <NewPasswordForm onNext={() => navigate("/login")} reset={true} />
      )}
    </Box>
  );
};

export default Forgot;
