import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "api/request";
import { handleError } from "utils/handleError";

export const getUnitRosters = createAsyncThunk(
  "unitRosters/get",
  async (
    {
      unitId,
      financialStatus,
      trainingStatus,
      page = 1,
      limit = 10,
    }: {
      unitId: string;
      financialStatus: string;
      trainingStatus: string;
      page: number;
      limit: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.get(`/commander/unit/${unitId}/rosters`, {
        params: {
          ...(financialStatus &&
            financialStatus !== "All" && { financialStatus }),
          ...(trainingStatus && trainingStatus !== "All" && { trainingStatus }),
          page,
          limit,
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error);
      return rejectWithValue(errorMessage);
    }
  }
);
