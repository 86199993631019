import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Paper,
} from "@mui/material";
import { CloseIcon } from "assets/icons";

const ConnectedAccountsList = ({ data, setData }) => {
  const handleRemoveConnectedAccount = (accountId) => {
    setData(data.filter((acc) => acc.id !== accountId));
  };

  return (
    <Paper
      sx={{
        backgroundColor: "white",
        border: "1px solid #EAECF0",
        height: "100%",
        borderRadius: 2,
        p: 4,
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" fontWeight={600} color={"primary.900"}>
        Connected accounts
      </Typography>
      <Box mt={6} flex={1}>
        {data?.map((account) => (
          <Card
            key={account.id}
            sx={{
              mb: 2.5,
              border: "1px solid #EAECF0",
              borderRadius: 1.5,
              p: 0,
            }}
          >
            <CardContent sx={{ p: "8px!important" }}>
              <Box display="flex" alignItems={"flex-start"}>
                <Avatar
                  sx={{
                    width: 78,
                    height: 78,
                    borderRadius: 2,
                    mr: 4,
                  }}
                >
                  Avatar
                </Avatar>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={78}
                >
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    color={"primary.500"}
                  >
                    {account.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    color={"primary.900"}
                  >
                    {account.number}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    color={"primary.900"}
                  >
                    {account.balance}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => handleRemoveConnectedAccount(account.id)}
                  style={{ marginLeft: "auto" }}
                >
                  <CloseIcon fill="#667085" />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))}

        {/* No other connected accounts text */}
        {data?.length === 0 && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
          >
            <Typography variant="h6" color={"primary.500"}>
              There are no other connected accounts yet
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default ConnectedAccountsList;
