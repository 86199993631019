import { Box, Typography } from "@mui/material";
import React from "react";
import { TFinancialStatus } from "types/types";
import getFinancialStatusColor from "utils/getFinancialStatusColor";

interface FinancialStatusProps {
  status: TFinancialStatus;
}

const FinancialStatus: React.FC<FinancialStatusProps> = ({ status }) => {
  return (
    <Box display="flex" alignItems="center" gap={2.5}>
      <Box
        width={12}
        height={12}
        borderRadius="50%"
        bgcolor={getFinancialStatusColor(status)}
        mr={1}
      />
      <Typography variant="body1" fontWeight={400} color={"primary.main"}>
        {status}
      </Typography>
    </Box>
  );
};

export default FinancialStatus;
