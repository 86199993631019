import * as React from "react";
const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#98A2B3"
      d="m16.988 15.884-5.217-5.218a5.973 5.973 0 0 0 1.247-3.672 5.99 5.99 0 0 0-1.765-4.26A5.982 5.982 0 0 0 6.992.966 5.99 5.99 0 0 0 2.73 2.733 5.979 5.979 0 0 0 .965 6.994a5.99 5.99 0 0 0 1.766 4.261 5.979 5.979 0 0 0 4.26 1.766 5.977 5.977 0 0 0 3.67-1.246l5.218 5.216a.165.165 0 0 0 .233 0l.876-.874a.165.165 0 0 0 0-.233Zm-6.814-5.708a4.476 4.476 0 0 1-3.182 1.318 4.476 4.476 0 0 1-3.183-1.318 4.476 4.476 0 0 1-1.317-3.182c0-1.201.468-2.332 1.317-3.182a4.476 4.476 0 0 1 3.183-1.318c1.201 0 2.332.466 3.182 1.318a4.476 4.476 0 0 1 1.318 3.182 4.472 4.472 0 0 1-1.318 3.182Z"
    />
  </svg>
);
export default SearchIcon;
