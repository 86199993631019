const colors = {
  primary: {
    main: "#0C425F",
    "50": "#EAECF0",
    "100": "#F2F4F7",
    "200": "#C2D0D7",
    "300": "#D0D5DD",
    "400": "#98A2B3",
    "500": "#667085",
    "600": "#475467",
    "700": "#344054",
    "800": "#3D687F",
    "900": "#101828",
  },
  secondary: {
    main: "#557B8F",
    "50": "#85A0AF",
    "200": "#042F46",
    "300": "#E0E9ED",
    "600": "#6D8E9F",
  },
  error: {
    main: "#F04438",
    "50": "#FEF3F2",
    "400": "#F97066",
    "600": "#D92D20",
    "700": "#B42318",
  },
  success: {
    main: "#ECFDF3",
    "400": "#32D583",
    "500": "#6AA0A0",
    "600": "#039855",
    "700": "#027A48",
    "800": "#487070",
  },
  warning: {
    main: "#FFF6ED",
    "300": "#FEC84B",
    "400": "#FDB022",
    "500": "#F79009",
  },
  pink: {
    "500": "#9E77ED",
    "700": "#6941C6",
  },
  background: {
    default: "#FCFCFD",
  },
};
export default colors;
