import React from "react";
import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";
import { useAppSelector } from "store";

const Router = () => {
  const { user, status } = useAppSelector((state) => state.auth);

  if (!user?.jwt || status !== "active") return <AuthRouter />;
  else return <AppRouter />;
};

export default Router;
