import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const ToogleButtons = ({ list, value, onChange }) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      aria-label="term toggle"
      sx={{
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        padding: 1,
        display: "inline-flex",
        width: "100%",
      }}
    >
      {list?.map((item) => (
        <ToggleButton
          key={item.value}
          value={item.value}
          sx={{
            color: "#344054",
            width: "100%",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "none",
            padding: "8px 16px",
            border: 0,
            borderRadius: "6px",
            "&.Mui-selected": {
              backgroundColor: "#fff",
              color: "#344054",
              // boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1)",
              "&:hover": {
                backgroundColor: "#fff",
              },
            },
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
          disableRipple
        >
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToogleButtons;
