import { Box, Container } from "@mui/material";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import AuthImg from "../assets/images/authImg.png";
import CounselorImg from "../assets/images/counselor.png";
import Logo from "../assets/images/logo.png";
import { useAppSelector } from "../store";

interface AuthLayoutProps {
  children?: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const { selectedRole } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const isConnectAccountPage = location.pathname.includes("/connect-account");
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      bgcolor="primary.50"
      minHeight="100vh"
      sx={{ bgcolor: "background.default" }}
    >
      {isConnectAccountPage ? (
        children
      ) : (
        <>
          <Box width={"60%"}>
            <Container
              maxWidth="xs"
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                paddingY: 10,
                alignItems: "flex-start",
              }}
            >
              <img src={Logo} alt="Logo" style={{ width: 112, height: 50 }} />
              <Box
                width={"100%"}
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                alignItems={"center"}
                mt={20}
              >
                {children}
              </Box>
            </Container>
          </Box>
          <Box
            width={"40%"}
            sx={{
              backgroundImage: `url(${
                selectedRole !== "Counselor" ? AuthImg : CounselorImg
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "fixed",
              height: "100vh",
              right: 0,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default AuthLayout;
