import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  createAsset,
  createExpense,
  createIncome,
  createLiability,
  getAccountDiagram,
  getAssets,
  getLiabilities,
  getScoreMe,
  getTransactions,
  getTransactionsAnalytics,
  getTransactionsLastMonth,
  updateExpense,
} from "store/actions/FinancialStatementsAction";

interface TransactionState {
  incomes: any[];
  expenses: any[];
  assets: {
    totalItems: number;
    data: any[];
  };
  liabilities: {
    totalItems: number;
    accounts: any[];
    totalPay: number;
    totalCurrentAmount: number;
    totalPercentage: number;
    nextPaymentDate: string;
  };
  lastMonthExpense: number[];
  transactionsInfo: {
    totalIncomes: number;
    totalExpenses: number;
  };
  transactionsAnalytics: {
    monthlyExpense: {};
    monthlyIncome: {};
  };
  score: number;
  diagram: null | {
    accountType: string;
    currentBalance: number;
    purchaseCost: number;
    valueIncrease: number;
    percentageChange: number;
    transactions: {};
  };
}

const initialState: TransactionState = {
  incomes: [],
  expenses: [],
  assets: {
    totalItems: 0,
    data: [],
  },
  liabilities: {
    totalItems: 0,
    accounts: [],
    totalPay: 0,
    totalCurrentAmount: 0,
    totalPercentage: 0,
    nextPaymentDate: "",
  },
  lastMonthExpense: [],
  transactionsInfo: {
    totalIncomes: 0,
    totalExpenses: 0,
  },
  transactionsAnalytics: {
    monthlyExpense: {},
    monthlyIncome: {},
  },
  score: 0,
  diagram: null,
};

const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create Income
    builder.addCase(createIncome.fulfilled, (state, { payload }) => {
      state?.incomes?.unshift(payload);
    });

    // Create Expense
    builder.addCase(createExpense.fulfilled, (state, { payload }) => {
      state?.expenses?.unshift(payload);
    });

    // Get Transactions
    builder.addCase(getTransactions.fulfilled, (state, { payload }) => {
      state.expenses = payload?.data?.expenses;
      state.incomes = payload?.data?.incomes;
      state.transactionsInfo.totalExpenses = payload?.totalItemsExpenses;
      state.transactionsInfo.totalIncomes = payload?.totalItemsIncomes;
    });

    // Get Transactions Last Month
    builder.addCase(
      getTransactionsLastMonth.fulfilled,
      (state, { payload }) => {
        state.lastMonthExpense = payload?.data?.expenses;
      }
    );
    // Get Transactions Analytics
    builder.addCase(
      getTransactionsAnalytics.fulfilled,
      (state, { payload }) => {
        state.transactionsAnalytics.monthlyExpense =
          payload?.data?.monthlyExpense;
        state.transactionsAnalytics.monthlyIncome =
          payload?.data?.monthlyIncome;
      }
    );

    // Create Asset
    builder.addCase(createAsset.fulfilled, (state, { payload }) => {
      state?.assets?.data?.unshift(payload);
    });

    // Get Assets
    builder.addCase(getAssets.fulfilled, (state, { payload }) => {
      state.assets = payload;
    });

    // Create Liability
    builder.addCase(createLiability.fulfilled, (state, { payload }) => {
      state?.liabilities?.accounts?.unshift(payload);
    });

    // Get Liabilities
    builder.addCase(getLiabilities.fulfilled, (state, { payload }) => {
      state.liabilities = payload.data;
    });
    //getScoreMe
    builder.addCase(getScoreMe.fulfilled, (state, { payload }) => {
      state.score = payload;
    });
    //get Diagram
    builder.addCase(getAccountDiagram.fulfilled, (state, { payload }) => {
      state.diagram = payload;
    });
    builder.addCase(updateExpense.fulfilled, (state, { payload }) => {
      const index = state.expenses.findIndex((row) => {
        const id = row?.transactionId || row?.id || row?.accountId;
        return id === payload?.transactionId || id === payload?.id;
      });
      if (index !== -1) {
        state.expenses[index] = {
          ...state.expenses[index],
          category: payload?.category,
        };
      }
    });
  },
});

const selectTransaction = (state: RootState) => state.transactions;

export const selectIncomes = createSelector(
  selectTransaction,
  (state) => state.incomes
);
export const selectExpenses = createSelector(
  selectTransaction,
  (state) => state.expenses
);

export const selectAssets = createSelector(
  selectTransaction,
  (state) => state.assets
);
export const selectLiabilities = createSelector(
  selectTransaction,
  (state) => state.liabilities
);
export const selectLastMonthExpense = createSelector(
  selectTransaction,
  (state) => state.lastMonthExpense
);
export const selectTransactionsInfo = createSelector(
  selectTransaction,
  (state) => state.transactionsInfo
);
export const selectTransactionsAnalytics = createSelector(
  selectTransaction,
  (state) => state.transactionsAnalytics
);
export const selectScore = createSelector(
  selectTransaction,
  (state) => state.score
);
export const selectDiagram = createSelector(
  selectTransaction,
  (state) => state.diagram
);

export default transactionSlice.reducer;
