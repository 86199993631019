import { Box, LinearProgress, Typography } from "@mui/material";
import {
  AmountSelect,
  AssetInfo,
  Button,
  DashboardHeader,
  Input,
  MultiDatePicker,
  RightSidebar,
  Select,
  Table,
} from "components";
import { assetsTypes } from "datas/select";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getAssets } from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { selectAssets } from "store/reducers/TransactionReducer";
import { IColumn, TAmountFilterType } from "types/types";

const Assets = () => {
  const { isVisible, onOpenWithValue, onClose } = useModal();
  const assets = useAppSelector(selectAssets);
  const loading = useAppSelector(getIsLoading);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(); // Manage search params
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const category = searchParams.get("category");
  const amountFilterType = searchParams.get("amountFilterType");
  const amount1 = searchParams.get("amount1");
  const amount2 = searchParams.get("amount2");
  const page = +(searchParams.get("page") || 1);
  const limit = +(searchParams.get("limit") || 10);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("category") || ""
  );

  // Update URL parameters based on filter changes
  const updateURLParams = (key, value) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams, { replace: true });
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    updateURLParams("search", value);
  };

  // Handle category selection change
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
    updateURLParams("category", value);
  };

  // Handle date filter change
  const handleDateChange = (newDate) => {
    const startDate = newDate[0];
    const endDate = newDate[1];
    if (startDate && endDate) {
      updateURLParams("startDate", startDate?.format("YYYY-MM-DD"));
      updateURLParams("endDate", endDate?.format("YYYY-MM-DD"));
      updateURLParams("page", "1"); // Reset to page 1 on date change
    }
  };

  // Handle amount filter change
  const handleAmountFilterChange = (newFilter) => {
    updateURLParams("amountFilterType", newFilter.type);
    updateURLParams("amount1", newFilter.amount1);
    if (newFilter.type === "between") {
      updateURLParams("amount2", newFilter.amount2);
    } else {
      updateURLParams("amount2", "");
    }
  };

  // Fetch assets data on component mount or URL parameter changes
  useEffect(() => {
    dispatch(
      getAssets({
        page: page,
        limit: limit,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        categoriesFilter: category ? category : undefined,
        amountFilterType: amountFilterType?.toUpperCase() as TAmountFilterType,
        singleValue: amountFilterType !== "between" ? +amount1 : undefined,
        rangeValue:
          amountFilterType === "between" ? [+amount1, +amount2] : undefined,
      })
    );
  }, [
    dispatch,
    selectedCategory,
    searchParams,
    amountFilterType,
    amount1,
    amount2,
    page,
    limit,
    startDate,
    endDate,
    category,
  ]);

  const columns: IColumn[] = [
    {
      id: "title",
      label: "Title",
      render: (row) => (
        <Typography variant="body1" fontWeight={600} color={"primary.700"}>
          {row.title}
        </Typography>
      ),
    },
    {
      id: "type",
      label: "Type",
      style: { fontWeight: 500, color: "primary.700" },
    },
    {
      id: "term",
      label: "Term",
    },
    {
      id: "lastUpdate",
      label: "Last Update",
      render: (row) => (
        <Typography variant="h6" fontWeight={400} color={"primary.500"}>{`${
          row?.lastUpdated || row?.nextPaymentDate
            ? dayjs(row?.lastUpdated || row?.nextPaymentDate).format(
                "DD MMM YYYY, HH:mm A"
              )
            : ""
        }`}</Typography>
      ),
    },
    {
      id: "percentage",
      label: "Percentage",
      render: (row) => (
        <Typography variant="body1" fontWeight={600} color={"primary.700"}>
          {row?.percentage && row?.percentage + "%"}
        </Typography>
      ),
    },
    {
      id: "currentBalance",
      label: "Current Balance",
      render: (row) => (
        <Typography
          variant="body1"
          fontWeight={600}
          color={"primary.700"}
        >{`$ ${row?.currentValue || row?.currentBalance || ""}`}</Typography>
      ),
    },
  ];

  return (
    <Box width={"100%"}>
      <DashboardHeader goBack page="Assets">
        <Box width={426}>
          <Input
            fullWidth
            placeholder="Search by Title"
            search
            value={search}
            onChange={handleSearchChange}
          />
        </Box>
      </DashboardHeader>
      <Box sx={{ px: 10, py: 6 }}>
        <Box mb={5} display={"flex"} alignItems={"center"} gap={6}>
          <Select
            value={selectedCategory}
            label="Categories"
            placeholder="Select category"
            options={assetsTypes}
            onChange={handleCategoryChange}
            sx={{
              width: 252,
              height: 44,
              borderColor: "red",
              fontWeight: 400,
              color: "primary.500",
            }}
          />
          <Box sx={{ width: 311 }}>
            <MultiDatePicker
              dates={startDate && endDate ? [startDate, endDate] : []}
              onChange={handleDateChange}
              type="all"
              label="Date Range"
            />
          </Box>

          <AmountSelect onApply={handleAmountFilterChange} />
          <Button
            variant="outlined"
            onClick={() => {
              setSearchParams({}); // Clear all URL search parameters
              setSearch(""); // Reset search state
              setSelectedCategory(null); // Reset selected category
            }}
            sx={{ width: 180, height: 44, marginTop: 5.5 }}
            label="Clear filters"
            disabledBgColor="primary.100"
            disabledTextColor="primary.300"
          />
        </Box>
        {loading ? (
          <LinearProgress />
        ) : (
          <Table
            total={assets?.totalItems}
            data={assets?.data?.filter((item) =>
              item?.title?.toLowerCase()?.includes(search?.toLowerCase())
            )}
            columns={columns}
            onRowClick={(row) => onOpenWithValue(row?.accountId || row?.id)}
            handleChange={({ page, limit }) => {
              updateURLParams("page", page.toString());
              updateURLParams("limit", limit.toString());
            }}
          />
        )}
        <RightSidebar
          open={isVisible as boolean}
          onClose={onClose}
          title="Short term asset"
          subTitle={(() => {
            const date =
              assets?.data?.find(
                (item) =>
                  item?.accountId === isVisible || item?.id === isVisible
              )?.lastUpdated ||
              assets?.data?.find(
                (item) =>
                  item?.accountId === isVisible || item?.id === isVisible
              )?.nextPaymentDate;

            return date ? dayjs(date).format("DD MMM YYYY, HH:mm A") : "";
          })()}
        >
          <AssetInfo
            data={assets?.data?.find(
              (item) => item?.accountId === isVisible || item?.id === isVisible
            )}
          />
        </RightSidebar>
      </Box>
    </Box>
  );
};

export default Assets;
