import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { ShareIcon } from "assets/icons";
import {
  AddAssetForm,
  AddExpenceForm,
  AddIncomeForm,
  AddLiabilityForm,
  Button,
  CenterModal,
  DashboardHeader,
  ExpensesCategoriesChart,
  FinancialAnalytics,
  ScoreCard,
  SummaryCard,
  SummaryCardForBigData,
} from "components";
import FinancialStatementTypes from "components/FinancalStatement/Expenses/FinancialStatementTypes";
import GoalsContainer from "components/FinancalStatement/PersonalFinancialStatement/GoalsContainer";
import { financialStatementsTypes } from "constants/common";
import useModal from "hooks/useModal";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  getAssets,
  getLiabilities,
  getScoreMe,
  getTransactions,
} from "store/actions/FinancialStatementsAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import getStatusByScore from "utils/getStatusByScore";

const FinancialStatements = () => {
  const { isVisible, onOpenWithValue, onClose } = useModal();
  const loading = useAppSelector(getIsLoading);
  const { assets, liabilities, incomes, expenses, score } = useAppSelector(
    (state) => state.transactions
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (!expenses?.length || expenses?.length <= 10 || !incomes?.length) {
      dispatch(getTransactions({ page: 1, limit: 220 }));
    }
    if (!assets?.data?.length) {
      dispatch(getAssets({}));
    }
    if (!liabilities?.accounts?.length) {
      dispatch(getLiabilities({}));
    }

    if (!score) {
      dispatch(getScoreMe());
    }
  }, [dispatch, expenses, incomes, assets, liabilities, score]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <DashboardHeader page={"Personal Financial Statement"}>
        <Box>
          <Button sx={{ gap: 2, borderColor: "secondary.50", height: 40 }}>
            <ShareIcon />
            <Typography>Share</Typography>
          </Button>
          <Button
            onClick={() => navigate("basic-budget-analysis")}
            variant="contained"
            label="Basic Budget Analysis"
            sx={{ ml: 4, height: 40 }}
          />
        </Box>
      </DashboardHeader>
      <Box py={6} px={10}>
        <Grid container spacing={4}>
          {financialStatementsTypes?.map((item, index) => {
            return <FinancialStatementTypes key={index} {...item} />;
          })}
        </Grid>
        {loading ? (
          <LinearProgress sx={{ mt: 2 }} />
        ) : (
          <Grid container spacing={3} mt={6}>
            <Grid item xs={6}>
              <SummaryCard
                title="Incomes"
                totalAmount={incomes
                  ?.slice(0, 5)
                  ?.reduce(
                    (acc, item) => acc + (item?.amount?.amt || item?.amount),
                    0
                  )
                  ?.toFixed(2)}
                data={incomes?.slice(0, 5)}
                onAdd={() => {
                  onOpenWithValue(2);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SummaryCard
                title="Expenses"
                totalAmount={expenses
                  ?.slice(0, 5)
                  ?.reduce(
                    (acc, item) => acc + (item?.amount?.amt || item?.amount),
                    0
                  )
                  ?.toFixed(2)}
                data={expenses?.slice(0, 5)}
                onAdd={() => {
                  onOpenWithValue(1);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SummaryCardForBigData
                title="Assets"
                data={assets?.data?.slice(0, 4)}
                onAdd={() => {
                  onOpenWithValue(3);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SummaryCardForBigData
                title="Liabilities"
                data={liabilities?.accounts?.slice(0, 4)}
                onAdd={() => {
                  onOpenWithValue(4);
                }}
              />
            </Grid>
            <Grid item xs={7.5}>
              <FinancialAnalytics />
            </Grid>
            <Grid item xs={4.5}>
              {expenses?.length > 0 && <ExpensesCategoriesChart />}
            </Grid>
            <Grid item xs={4}>
              <ScoreCard
                data={{
                  title: "Financial Health Score",
                  status: getStatusByScore(score)?.status,
                  percentage: (score * 100) / 1000,
                  path: "test",
                  details: getStatusByScore(score)?.message,
                }}
                onDetailsClick={() => {
                  navigate("financial-health-score");
                }}
                value={score}
              />
            </Grid>
            <Grid item xs={8}>
              <GoalsContainer />
            </Grid>
          </Grid>
        )}
      </Box>
      <CenterModal
        title={
          isVisible === 1
            ? "Add Expense info manually"
            : isVisible === 2
            ? "Add Income info manually"
            : isVisible === 3
            ? "Add Assets info manually"
            : isVisible === 4
            ? "Add Liabilities info manually"
            : ""
        }
        open={isVisible}
        onClose={onClose}
        sx={{ width: 480 }}
        goBack
        close={false}
      >
        {isVisible === 1 ? (
          <AddExpenceForm
            onSuccess={() => {
              // fetchTransactions();
            }}
            handleClose={onClose}
          />
        ) : isVisible === 2 ? (
          <AddIncomeForm
            onSuccess={() => {
              // fetchTransactions();
            }}
            handleClose={onClose}
          />
        ) : isVisible === 3 ? (
          <AddAssetForm
            onSuccess={() => {
              // fetchAssetData();
            }}
            handleClose={onClose}
          />
        ) : isVisible === 4 ? (
          <AddLiabilityForm
            onSuccess={() => {
              // fetchLiabilityData();
            }}
            handleClose={onClose}
          />
        ) : null}
      </CenterModal>
    </Box>
  );
};

export default FinancialStatements;
