import { Box, Button, Grid, Typography } from "@mui/material";
import {
  ArrowRightIcon,
  QuestionIcon,
  SmallArrowRightIcon,
} from "assets/icons";
import React from "react";

interface ITopicItemTypes {
  name: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  sx?: Object;
  small?: boolean;
}
const TopicItem = ({ name, Icon, disabled, sx, small }: ITopicItemTypes) => {
  return (
    <Grid xs={3} item>
      <Button
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: 124,
          width: "100%",
          gap: 6,
          px: 4,
          backgroundColor: disabled ? "#F1F1F4" : "white",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "primary.300",
          borderRadius: 2,
          ...sx,
        }}
      >
        <Box
          width={"100%"}
          height={40}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Icon />
          {disabled && <QuestionIcon />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={600}
            color={"primary.700"}
            textTransform={"capitalize"}
          >
            {name}
          </Typography>
          {!small ? <ArrowRightIcon /> : <SmallArrowRightIcon />}
        </Box>
      </Button>
    </Grid>
  );
};

export default TopicItem;
