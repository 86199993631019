import * as React from "react";
const CheckboxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={8}
    fill="none"
    {...props}
  >
    <path
      fill={props?.fill || "#101828"}
      d="M4.5 8.125.75 4.405 1.942 3.25 4.5 5.763 10.057.25l1.193 1.185-6.75 6.69Z"
    />
  </svg>
);
export default CheckboxIcon;
