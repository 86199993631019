import * as React from "react";
const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={23.5} fill="#fff" stroke="#EAECF0" />
    <path
      stroke="#101828"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M33 27v4a2 2 0 0 1-2 2H17a2 2 0 0 1-2-2v-4m14-7-5-5m0 0-5 5m5-5v12"
    />
  </svg>
);
export default UploadIcon;
