import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "store";
import { getFinancialReportByUnitId } from "store/actions/UnitsAction";
import { selectUnit } from "store/reducers/UnitReducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ScoreLineDashboard = ({ unitId, period }) => {
  const selectedUnit = useAppSelector(selectUnit);
  const unitID = unitId || selectedUnit?.id;

  const dispatch = useAppDispatch();
  const [scorLineData, setScorLineData] = useState(null);

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (value: number) => `${value}%`,
        },
      },
    },
  };

  const fetchFinancialDataForAllMonths = useCallback(
    async (unitId: string) => {
      const currentYear = new Date().getFullYear();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]; // Assuming this array contains month names like ['January', 'February', ...]
      const promises = monthNames.map((month) => {
        return dispatch(
          getFinancialReportByUnitId({
            unitId,
            period: period,
            dateRange: `${month} ${currentYear}`,
          })
        );
      });

      // Wait for all promises to resolve
      const results = await Promise.all(promises);

      // Check if all responses are successful
      if (results.every((res) => res.meta?.requestStatus === "fulfilled")) {
        const data = results.map((res) => res.payload); // Assuming payload contains the necessary data
        // Set the data for the chart once all data is fetched
        setScorLineData({
          labels: monthNames,
          datasets: [
            {
              label: "Average Financial Readiness",
              data: data.map(
                (item) =>
                  (item.averageFinancialReadinessScore / 1000) * 100 || 0
              ), // Convert to percentage
              borderColor: "#B692F6",
              fill: false,
              pointRadius: 0,
            },
            {
              label: "Median Financial Readiness",
              data: data.map(
                (item) => (item.medianFinancialReadinessScore / 1000) * 100 || 0
              ), // Convert to percentage
              borderColor: "#F670C7",
              fill: false,
              pointRadius: 0,
            },
            {
              label: "Distribution of Financial Readiness",
              data: data.map(
                (item) =>
                  (item.financialReadinessDistribution?.ready / 100) * 100 || 0
              ), // Assuming this is already in percentage
              borderColor: "#36BFFA",
              fill: false,
              pointRadius: 0,
            },
            {
              label: "Size of Standard Deviation",
              data:
                data.map((item) => (item.standardDeviation / 1000) * 100) || 0, // Convert to percentage
              borderColor: "#FDB022",
              fill: false,
              pointRadius: 0,
            },
          ],
        });
      }
    },
    [dispatch, period]
  );

  useEffect(() => {
    if (unitID) {
      fetchFinancialDataForAllMonths(unitID);
    }
  }, [unitID, fetchFinancialDataForAllMonths]);

  if (!scorLineData) {
    return <LinearProgress />;
  }
  return (
    <Paper elevation={3} sx={{ padding: 2, mt: 6 }}>
      <Typography variant="h4" fontWeight={600}>
        Monthly Unit Scores Over Time
      </Typography>

      {/* Custom Legend */}
      <Box
        sx={{
          width: "50%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, auto)",
          gap: 2,
          mt: 4,
          mb: 6,
        }}
      >
        {scorLineData?.datasets?.map((dataset, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              mr: 3,
              fontSize: "14px",
            }}
          >
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: dataset.borderColor,
                borderRadius: 1.5,
                mr: 1,
              }}
            ></Box>
            <Typography variant="body2">{dataset.label}</Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ overflowX: "auto" }}>
        <Box sx={{ minWidth: "600px" }}>
          <Line
            height={304}
            width={1900}
            data={scorLineData}
            options={options as unknown}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ScoreLineDashboard;
