import * as React from "react";
const BookmarkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} {...props}>
    <path
      fill="#0C425F"
      fillRule="evenodd"
      d={
        !props?.fill
          ? "M11.921 2.419a23.778 23.778 0 0 0-7.848 0 1.194 1.194 0 0 0-.973.94 35.64 35.64 0 0 0-.142 13.327l.163.912 3.67-3.487a1.75 1.75 0 0 1 2.412 0l3.67 3.487.164-.912a35.642 35.642 0 0 0-.143-13.328 1.194 1.194 0 0 0-.973-.94ZM3.825.939a25.278 25.278 0 0 1 8.344 0 2.694 2.694 0 0 1 2.196 2.123c.922 4.58.972 9.291.148 13.889l-.221 1.235a1.323 1.323 0 0 1-2.214.726l-3.909-3.713a.25.25 0 0 0-.344 0l-3.908 3.713a1.323 1.323 0 0 1-2.214-.726L1.48 16.95A37.14 37.14 0 0 1 1.63 3.062 2.694 2.694 0 0 1 3.825.94Z"
          : "M11.132 1.186a25.028 25.028 0 0 0-8.262 0A2.444 2.444 0 0 0 .88 3.112 36.89 36.89 0 0 0 .73 16.907l.334 1.859a.732.732 0 0 0 1.224.401l4.023-3.823a1 1 0 0 1 1.378 0l4.024 3.823a.732.732 0 0 0 1.224-.401l.333-1.86a36.89 36.89 0 0 0-.147-13.794 2.444 2.444 0 0 0-1.992-1.926Z"
      }
      clipRule="evenodd"
    />
  </svg>
);
export default BookmarkIcon;
