import * as React from "react";
const RepeatIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#FEF3F2" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#FDA29B" rx={5.5} />
    <g clipPath="url(#a)">
      <path
        stroke="#B42318"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.333 8.666 22 11.333m0 0-2.667 2.666M22 11.333h-9.333A2.667 2.667 0 0 0 10 13.999v1.334m2.667 8L10 20.666m0 0 2.667-2.667M10 20.666h9.333A2.667 2.667 0 0 0 22 17.999v-1.333"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 8h16v16H8z" />
      </clipPath>
    </defs>
  </svg>
);
export default RepeatIcon;
