import React from "react";
import { Box, Typography } from "@mui/material";
import getFinancialStatusColor from "utils/getFinancialStatusColor";
import { TFinancialStatus } from "types/types";

interface StatusPercentageProps {
  status: TFinancialStatus;
  percentage?: number;
}

const StatusPercentage: React.FC<StatusPercentageProps> = ({
  status,
  percentage,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box
        width={16}
        height={16}
        bgcolor={getFinancialStatusColor(status)}
        borderRadius={1.5}
        mr={1}
      />
      <Typography variant="body1" flex={1} ml={3} fontWeight={500}>
        {status}
      </Typography>
      {percentage && (
        <Typography variant="body2" ml={1}>
          {percentage}%
        </Typography>
      )}
    </Box>
  );
};

export default StatusPercentage;
