import * as React from "react";
const SmallCoinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#F0F9FF" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#7CD4FD" rx={5.5} />
    <g clipPath="url(#a)">
      <path
        stroke="#026AA2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.284 10.773a4.912 4.912 0 1 1 6.946 6.947m-2.07.035a4.913 4.913 0 1 1-9.824 0 4.913 4.913 0 0 1 9.825 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 8h16v16H8z" />
      </clipPath>
    </defs>
  </svg>
);
export default SmallCoinIcon;
