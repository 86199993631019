import * as React from "react";
const ChevronRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#3D687F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.75 13.5 4.5-4.5-4.5-4.5"
    />
  </svg>
);
export default ChevronRightIcon;
