import { Box, Typography } from "@mui/material";
import { NextIcon, PrevIcon } from "assets/icons";
import { Button } from "components";
import React, { useCallback } from "react";

const DateRangeSelector = ({ currentDate, setCurrentDate }) => {
  const handlePreviousMonth = useCallback(() => {
    setCurrentDate(currentDate.subtract(1, "month"));
  }, [currentDate, setCurrentDate]);

  const handleNextMonth = useCallback(() => {
    setCurrentDate(currentDate.add(1, "month"));
  }, [currentDate, setCurrentDate]);

  return (
    <Box display="flex" flexDirection={"column"}>
      <Typography variant="h6" color="primary.800" sx={{ mb: 2 }}>
        Date Range
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          overflow: "hidden",
          height: 44,
          width: "fit-content",
          gap: 1,
        }}
      >
        <Button
          onClick={handlePreviousMonth}
          sx={{
            width: 24,
            height: 44,
            padding: 0,
            borderColor: "primary.200",
            backgroundColor: "white",
          }}
        >
          <PrevIcon />
        </Button>
        <Box
          sx={{
            px: 3,
            py: 1,
            width: 196,
            textAlign: "center",
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "primary.200",
            borderRadius: 2,
            height: 44,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "primary.main" }}>
            {currentDate.format("MMMM, YYYY")}
          </Typography>
        </Box>
        <Button
          onClick={handleNextMonth}
          sx={{
            width: 24,
            height: 44,
            padding: 0,
            borderColor: "primary.200",
            backgroundColor: "white",
          }}
        >
          <NextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default DateRangeSelector;
