import { Box, Typography } from "@mui/material";
import { WarningIcon } from "assets/icons";
import React from "react";

const EmptyFilteredNotification = ({ title }: { title: string }) => {
  return (
    <Box
      bgcolor={"white"}
      borderTop={"1px solid #EAECF0"}
      pt={5}
      pb={15}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      borderRadius={2}
    >
      <WarningIcon />
      <Typography
        mt={6}
        mb={4}
        variant="h3"
        fontWeight={700}
        color="primary.main"
      >
        You have no {title} messages
      </Typography>
      <Typography variant="body1" fontWeight={400} color="primary.800">
        Enjoy your inbox!
      </Typography>
    </Box>
  );
};

export default EmptyFilteredNotification;
