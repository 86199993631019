import * as React from "react";
const ArrowUpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#12B76A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.997 15.833V4.167m0 0L4.164 10m5.833-5.833L15.831 10"
    />
  </svg>
);
export default ArrowUpIcon;
