const convertToMonthlyArray = (data) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Map through the months and return the amount if it exists in `data`, otherwise 0
  return months.map((month) => {
    const value = data?.[month] || 0;
    return value < 0 ? value * -1 : value;
  });
};
export default convertToMonthlyArray;
