import { Theme } from "@emotion/react";
import { Box, IconButton, Modal, SxProps, Typography } from "@mui/material";
import { ArrowLeftIcon, CloseIcon } from "assets/icons";
import React from "react";

interface CustomModalProps {
  open: boolean | number;
  close?: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  blur?: boolean;
  sx?: SxProps<Theme>;
  goBack?: boolean;
}

const CenterModal: React.FC<CustomModalProps> = ({
  open,
  close = true,
  onClose,
  title,
  children,
  blur,
  sx,
  goBack,
}) => {
  return (
    <Modal
      open={Boolean(open)}
      onClose={onClose}
      slotProps={
        blur && {
          backdrop: {
            sx: {
              backgroundColor: "rgba(52, 64, 84, 0.4)",
              backdropFilter: "blur(3px)",
            },
          },
        }
      }
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          ...sx,
        }}
      >
        <Box
          display="flex"
          justifyContent={!goBack ? "space-between" : "flex-start"}
          alignItems="center"
          gap={goBack && 19.5}
        >
          {goBack && (
            <IconButton onClick={onClose}>
              <ArrowLeftIcon />
            </IconButton>
          )}
          <Typography variant="h5" fontWeight={700} color={"primary.700"}>
            {title}
          </Typography>
          {close && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Box mt={2}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default CenterModal;
