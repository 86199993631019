import React from "react";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
  Tooltip,
  Legend,
} from "chart.js";
import { Box } from "@mui/material";
import colors from "theme/colors";

const BarChart = ({ data, target }) => {
  // Register chart.js elements
  ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

  // Data for the bar chart
  const chartData = {
    labels: ["Needs", "Wants", "Savings"],
    datasets: [
      //default bar which empty  #F2F4F7
      {
        label: "Distribution",
        data: data,
        backgroundColor: [colors.warning[300], "#97f09a", colors.pink[500]],
        // borderRadius: {
        //   topLeft: 4,
        //   topRight: 4,
        // },
        barThickness: 140,
      }, // Thickness of the bars to give a compact look
      {
        label: "Distribution",
        data: target,
        backgroundColor: [colors.warning[500], "#48944B", colors.pink[700]], // Updated colors as per the request

        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },

        barThickness: 140,
      },
      {
        label: "Distribution",
        data: [100, 100, 100],
        backgroundColor: ["#F2F4F7", "#F2F4F7", "#F2F4F7"],
        borderRadius: {
          topLeft: 10,
          topRight: 10,
        },
        barThickness: 140,
      },
    ],
  };

  // Options for the bar chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        max: 100,
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Hides the percentage labels on the left (y-axis)
        },
        border: {
          display: false,
        },
      },
      x: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Hides the percentage labels on the left (y-axis)
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable the tooltip
      },
    },
  };

  return (
    // Limit the height and width of the chart container to match the image
    <Box sx={{ height: "300px", width: 470, marginLeft: "-3%" }}>
      <Bar data={chartData} options={options} style={{ marginRight: -100 }} />
    </Box>
  );
};

export default BarChart;
