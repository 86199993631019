import * as React from "react";
const FilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      stroke="#0C425F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.778 4.838H3l7.111 8.409v5.813l3.556 1.778v-7.591l7.11-8.41Z"
    />
  </svg>
);
export default FilterIcon;
