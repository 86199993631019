import { yupResolver } from "@hookform/resolvers/yup";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons";
import { Button } from "components";
import CenterModal from "components/common/CenterModal";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { createGoal, updateGoal } from "store/actions/GoalAction";
import { selectGoals } from "store/reducers/GoalReducer";
import { selectAssets } from "store/reducers/TransactionReducer";
import { showToast } from "utils/showToast";
import { createGoalSchema } from "validation/goalValidation";
import GoalFormStepOne from "./GoalFormStepOne";
import GoalFormStepTwo from "./GoalFormStepTwo";
import GoalSuccess from "./GoalSuccess";

// Combined validation schema for both steps

interface CreateGoalModalProps {
  open: boolean;
  handleClose: () => void;
  editId?: string;
  editData?: {
    name: string;
    description: string;
    dateToCompete: string;
    goalAmount: string;
    goalColor: string;
    imageSignedUrl: string;
  };
}
type TDisabledCheckList =
  | "name"
  | "description"
  | "dateToCompete"
  | "goalAmount"
  | "color"
  | "file";

const CreateGoalModal = ({
  open,
  handleClose,
  editId,
  editData,
}: CreateGoalModalProps) => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const { data: bankAccounts } = useAppSelector(selectAssets);
  const goals = useAppSelector(selectGoals);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(createGoalSchema),
    mode: "onChange",
  });

  const disabledCheckList: TDisabledCheckList[] = useMemo(
    () =>
      step === 1
        ? ["name", "description", "dateToCompete"]
        : ["goalAmount", "color", "file"],
    [step]
  );

  const watchFields = watch(disabledCheckList);
  const navigate = useNavigate();
  const disabled = useMemo(() => {
    return Object.values(watchFields || {})?.some((value) => !value);
  }, [watchFields]);

  const handleNext = () => {
    if (step === 1) {
      setStep(step + 1);
    } else if (step === 3) {
      setStep(1);
      reset();
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      handleClose();
    }
  };

  const handlePhotoChange = (file) => {
    setValue("file", file);
  };

  const handleDateChange = (date) => {
    setValue("dateToCompete", date);
  };

  const onSubmit = async (data) => {
    if (step === 2) {
      const formData = new FormData();

      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("dateToCompete", data.dateToCompete.toISOString()); // Convert date to ISO format
      formData.append("goalAmount", data?.goalAmount);

      const goalAccountIds = goals?.map(
        (goal) => goal?.goalColor?.split("?")[1]
      );
      let accountId = editData ? editData?.goalColor?.split("?")[1] : null;

      for (const account of bankAccounts) {
        if (!goalAccountIds?.includes(account?.id)) {
          // Append only the first non-matching account ID to formData
          accountId = account?.id?.toString();
          break; // Exit the loop as soon as the condition is met
        }
      }
      formData.append("goalColor", data.color + "?" + accountId);
      if (data?.file instanceof File) {
        formData.append("file", data?.file);
      }
      if (editId) {
        const result = await dispatch(
          updateGoal({ id: editId, data: formData })
        );
        if (result.meta.requestStatus === "fulfilled") {
          showToast("Goal updated successfully!", "success");
          handleClose();
          setStep(1);
          navigate(`/goals`);
        }
      } else {
        const result = await dispatch(createGoal(formData));
        if (result.meta.requestStatus === "fulfilled") {
          showToast("Goal created successfully!", "success");
          setStep(step + 1);
          // reset();
        }
      }
    }
  };
  useEffect(() => {
    if (editId) {
      // Assuming you have access to the goalsData or can fetch it
      setValue("name", editData?.name);
      setValue("description", editData?.description);
      setValue("dateToCompete", new Date(editData?.dateToCompete));
      setValue("goalAmount", +editData?.goalAmount);
      setValue("color", editData?.goalColor?.split("?")[0]);
      setValue("file", editData?.imageSignedUrl);
    }
  }, [editId, setValue, editData]);
  useEffect(() => {
    trigger();
  }, [step, trigger]);

  return (
    <CenterModal
      open={open}
      onClose={() => {
        handleClose();
        setStep(1);
      }}
      blur
      sx={{ width: 480, height: step === 1 ? 428 : "auto" }}
      close={false}
    >
      {step !== 3 && (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={28}
          mb={5}
        >
          <IconButton onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography variant="h5" fontWeight={600} color={"primary.900"}>
            {editId ? "Edit Goal" : "Create new goal"}
          </Typography>
          <Typography variant="h6" color={"primary.400"}>
            Step {step}/2
          </Typography>
        </Box>
      )}

      {step === 1 && (
        <GoalFormStepOne
          control={control}
          errors={errors}
          date={getValues().dateToCompete}
          handleDateChange={handleDateChange}
        />
      )}
      {step === 2 && (
        <GoalFormStepTwo
          control={control}
          errors={errors}
          handlePhotoChange={handlePhotoChange}
          file={editData ? editData?.imageSignedUrl : null}
        />
      )}
      {step === 3 && <GoalSuccess goalData={getValues()} />}

      <Button
        variant="contained"
        label={step === 3 ? "Close" : "Continue"}
        color="primary"
        fullWidth
        onClick={step === 2 ? handleSubmit(onSubmit) : handleNext}
        sx={{ mt: 4, height: 48 }}
        disabled={disabled}
      />
    </CenterModal>
  );
};

export default CreateGoalModal;
