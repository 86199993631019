import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import convertToLocaleString from "utils/convertToLocaleString";

interface ResultsProps {
  results:
    | {
        totalLoanAmount: number;
        saleTax: number;
        upfrontPayment: number;
        totalPayments: number;
        totalInterest: number;
        loanTerm: number;
        interestPercentage: number;
        principalPercentage: number;
      }
    | undefined;
}
const CalculatorResult = ({ results }: ResultsProps) => {
  const {
    totalLoanAmount,
    saleTax,
    upfrontPayment,
    totalPayments,
    totalInterest,
    loanTerm,
    interestPercentage,
    principalPercentage,
  } = results || {
    totalLoanAmount: 0,
    saleTax: 0,
    upfrontPayment: 0,
    totalPayments: 0,
    totalInterest: 0,
    loanTerm: 60,
    interestPercentage: 70,
    principalPercentage: 30,
  };
  const chartData = {
    labels: ["Interest", "Principal"],
    datasets: [
      {
        data: [interestPercentage, principalPercentage],
        backgroundColor: ["#0086C9", "#7CD4FD"],
        borderRadius: 10,
        borderWidth: 3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          font: {
            size: 12,
          },
        },
      },
    },
  };
  const legendData = [
    { color: "#0086C9", label: "Interest", value: interestPercentage },
    { color: "#7CD4FD", label: "Principal", value: principalPercentage },
  ];
  return (
    <Box
      p={4}
      bgcolor={"white"}
      border={"1px solid #EAECF0"}
      borderRadius={2}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Typography variant="h5" fontWeight={600} color={"pirmary.700"} mb={2}>
        Results
      </Typography>
      {results ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          flex={1}
        >
          <Grid container mt={3}>
            <Paper variant="outlined" sx={{ width: "100%", borderRadius: 2 }}>
              <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                  Total Loan Amount
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  ${convertToLocaleString(totalLoanAmount)}
                </Typography>
              </Box>
              <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                  Sale Tax
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  ${convertToLocaleString(saleTax)}
                </Typography>
              </Box>
              <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                  Upfront Payment
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  ${convertToLocaleString(upfrontPayment)}
                </Typography>
              </Box>
            </Paper>
            <Paper
              variant="outlined"
              sx={{ width: "100%", mt: 4, borderRadius: 2 }}
            >
              <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                  Total of {loanTerm} Payments
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  ${convertToLocaleString(totalPayments)}
                </Typography>
              </Box>
              <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} color={"primary.500"}>
                  Total Loan Interest
                </Typography>
                <Typography variant="h6" color={"primary.700"}>
                  ${convertToLocaleString(totalInterest)}
                </Typography>
              </Box>
            </Paper>
            <Paper
              variant="outlined"
              sx={{ width: "100%", mt: 4, borderRadius: 2 }}
            >
              <Box p={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} color={"primary.600"}>
                  Upfront Payment
                </Typography>
                <Typography variant="h6" fontWeight={700} color={"primary.700"}>
                  ${convertToLocaleString(upfrontPayment)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Box>
            <Box mt={4} height={200}>
              <Doughnut data={chartData} options={chartOptions as unknown} />
            </Box>
            {/* Custom Legend */}
            <Paper
              variant="outlined"
              sx={{ width: "100%", mt: 2, borderRadius: 2 }}
            >
              {legendData.map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  p={1.5}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        width: 30,
                        height: 16,
                        borderRadius: 1,
                        backgroundColor: item.color,
                        marginRight: 1.5,
                      }}
                    />
                    <Typography variant="h6" color={"primary.700"}>
                      {item.label}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    color={"primary.500"}
                  >
                    {item.value}
                  </Typography>
                </Box>
              ))}
            </Paper>
          </Box>
        </Box>
      ) : (
        <Typography variant="h6" fontWeight={400} color={"primary.500"}>
          Do the calculations first to display the results.
        </Typography>
      )}
    </Box>
  );
};

export default CalculatorResult;
