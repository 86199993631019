import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { CustomSwitch, DashboardHeader } from "components";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "store/actions/NotificationAction";
import { getIsLoading } from "store/reducers/LoadingReducer";
import { selectNotificationSettings } from "store/reducers/NotificationReducer";

const NotificationSettings = () => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectNotificationSettings);
  const loading = useAppSelector(getIsLoading);

  const handleSwitchChange = (type, settingsData) => {
    dispatch(updateNotificationSettings({ type, settingsData }));
  };

  useEffect(() => {
    dispatch(getNotificationSettings());
  }, [dispatch]);
  const findSetting = (name) =>
    settings.find((setting) => setting.name === name) || {};

  return (
    <Box>
      <DashboardHeader goBack page="Notifications settings" />
      {loading ? (
        <LinearProgress />
      ) : (
        <Paper
          sx={{
            my: 8,
            mx: 10,
            border: "1px solid #EAECF0",
            borderRadius: 2,
            p: 6,
            height: "calc(100vh - 160px)",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={400}
            color={"primary.500"}
            mb={6}
          >
            Select the kind of notifications you get about your activities and
            recommendations
          </Typography>
          <Divider sx={{ my: 8 }} />

          {/* Email Notifications */}
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <Typography variant="h5" fontWeight={600} color={"primary.700"}>
                Email notifications
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                color={"primary.500"}
                mt={1}
              >
                Get emails to find out what's going on when you're not online.
                You can turn these off.
              </Typography>
            </Grid>

            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", gap: 8 }}
            >
              <CustomSwitch
                checked={findSetting("news_and_updates")?.receive_email}
                onChange={(e) =>
                  handleSwitchChange("news_and_updates", {
                    receive_email: e.target.checked,
                  })
                }
                label="News and updates"
                description="News about product and feature updates"
              />

              <CustomSwitch
                checked={findSetting("tips_and_tutorials")?.receive_email}
                onChange={(e) =>
                  handleSwitchChange("tips_and_tutorials", {
                    receive_email: e.target.checked,
                  })
                }
                label="Tips and tutorials"
                description="Tips on getting more out of Poncho"
              />

              <CustomSwitch
                checked={findSetting("reminders")?.receive_email}
                onChange={(e) =>
                  handleSwitchChange("reminders", {
                    receive_email: e.target.checked,
                  })
                }
                label="Reminders"
                description="There are notifications to remind you of upcoming meetings"
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 8, mb: 6 }} />

          {/* Push Notifications */}
          <Grid container spacing={6} py={2}>
            <Grid item xs={4}>
              <Typography variant="h6">Push notifications</Typography>
              <Typography variant="body2" color="textSecondary">
                Get push notifications in-app to find out what's going on when
                you're online
              </Typography>
            </Grid>

            <Grid
              item
              xs={8}
              sx={{ display: "flex", flexDirection: "column", gap: 8 }}
            >
              <CustomSwitch
                checked={findSetting("reminders")?.receive_push}
                onChange={(e) =>
                  handleSwitchChange("reminders", {
                    receive_push: e.target.checked,
                  })
                }
                label="Reminders"
                description="There are notifications to remind you of upcoming meetings"
              />
              <CustomSwitch
                checked={findSetting("tips_and_tutorials")?.receive_push}
                onChange={(e) =>
                  handleSwitchChange("tips_and_tutorials", {
                    receive_push: e.target.checked,
                  })
                }
                label="Tips and tutorials"
                description="Tips on getting more out of Poncho"
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 8 }} />
        </Paper>
      )}
    </Box>
  );
};

export default NotificationSettings;
