import { Box } from "@mui/material";
import {
  DashboardHeader,
  IndividualsList,
  OverallScoreForUnit,
} from "components";
import React from "react";
import { useNavigate } from "react-router-dom";

const ScoreForUnit = () => {
  const navigate = useNavigate();
  return (
    <Box width={"100%"}>
      <DashboardHeader
        page="Score for the Unit"
        goBack
        handleGoBack={() => navigate("/unit-score")}
      />
      <Box px={10} py={12}>
        <OverallScoreForUnit cardStyle={{ height: 248 }} />
        <IndividualsList />
      </Box>
    </Box>
  );
};

export default ScoreForUnit;
