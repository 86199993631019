import * as React from "react";
const FinanceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#FFFAEB" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#FEC84B" rx={5.5} />
    <path
      stroke="#B54708"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.45 10.816c.001-.82-1.668-1.482-3.724-1.482-2.057 0-3.725.664-3.726 1.482m0 0c0 .818 1.668 1.481 3.726 1.481 2.057 0 3.725-.663 3.725-1.482v5.749M10 10.816v8.888c0 .819 1.668 1.482 3.726 1.482.997 0 1.898-.158 2.566-.412m-6.291-6.996c0 .819 1.667 1.482 3.725 1.482s3.726-.663 3.726-1.482m-1.115 4.02c-.672.262-1.594.425-2.611.425-2.058 0-3.725-.663-3.725-1.482m11.684.236a3.32 3.32 0 0 1 0 4.714 3.368 3.368 0 0 1-4.742 0 3.32 3.32 0 0 1 0-4.714 3.368 3.368 0 0 1 4.742 0Z"
    />
  </svg>
);
export default FinanceIcon;
