import * as React from "react";
const LayersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={31} height={31} x={0.5} y={0.5} fill="#FFF6ED" rx={5.5} />
    <rect width={31} height={31} x={0.5} y={0.5} stroke="#FEB273" rx={5.5} />
    <path
      stroke="#C4320A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.336 17.667 16.003 21l6.666-3.333M16.003 11l-6.667 3.333 6.667 3.334 6.666-3.334L16.003 11Z"
    />
  </svg>
);
export default LayersIcon;
