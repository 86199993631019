import { getUserById } from "./AdminAction";
import {
  forgotOtpSend,
  login,
  otpSend,
  otpVerify,
  register,
  resetPassword,
  signOnFiserv,
  spouseInvitation,
  spouseOtpSend,
  spouseRegister,
} from "./AuthAction";
import { checkHealth } from "./CommonAction";
import {
  createAsset,
  createExpense,
  createIncome,
  createLiability,
  getAssets,
  getLiabilities,
  getTransactions,
} from "./FinancialStatementsAction";
import { createGoal, getGoal } from "./GoalAction";
import {
  getAllNotifications,
  getNotificationSettings,
} from "./NotificationAction";
import { getUnitRosters } from "./UnitRostersAction";
import {
  getAllUnitsByCommanderId,
  getFinancialReportByUnitId,
  getOverallUnitReport,
  getUnitById,
} from "./UnitsAction";

//if you want to use loader for api request add it to this array
const actionTypesforLoader = [
  login,
  register,
  otpSend,
  otpVerify,
  spouseInvitation,
  forgotOtpSend,
  resetPassword,
  spouseOtpSend,
  spouseRegister,
  createGoal,
  createIncome,
  createExpense,
  createAsset,
  createLiability,
  getGoal,
  getUnitById,
  getAllUnitsByCommanderId,
  getOverallUnitReport,
  getUnitRosters,
  getAllUnitsByCommanderId,
  getFinancialReportByUnitId,
  checkHealth,
  signOnFiserv,
  getAllNotifications,
  getNotificationSettings,
  getLiabilities,
  getAssets,
  getTransactions,
  // getTransactionsAnalytics,
  getUserById,
];

export default actionTypesforLoader;
