import * as React from "react";
const WarningIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={70}
    fill="none"
    {...props}
  >
    <rect width={64} height={64} x={3} y={3} fill="#FEF0C7" rx={28} />
    <rect
      width={64}
      height={64}
      x={3}
      y={3}
      stroke="#FFFAEB"
      strokeWidth={6}
      rx={28}
    />
    <path
      stroke="#DC6803"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M35.001 29.666V35m0 5.333h.013m-2.293-17.52L21.428 41.666a2.666 2.666 0 0 0 2.28 4h22.586a2.667 2.667 0 0 0 2.28-4L37.281 22.813a2.666 2.666 0 0 0-4.56 0Z"
    />
  </svg>
);
export default WarningIcon;
