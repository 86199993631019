import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  createGoal,
  deleteGoal,
  getGoals,
  updateGoal,
} from "store/actions/GoalAction";

// Create goal async thunk

interface GoalState {
  goals: {
    id: string;
    name: string;
    description: string;
    goalAmount: number;
    dateToCompete: string;
    goalColor;
    image: {
      url: string;
    };
  }[];
}

const initialState: GoalState = {
  goals: [],
};

// Goals slice
const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create Goal
    builder.addCase(createGoal.fulfilled, (state, { payload }) => {
      state.goals = [payload, ...state.goals];
    });
    // Get Goals

    builder.addCase(getGoals.fulfilled, (state, { payload }) => {
      state.goals = payload?.data;
    });

    // Delete Goal
    builder.addCase(deleteGoal.fulfilled, (state, { payload }) => {
      state.goals = state.goals.filter((goal) => goal.id !== payload);
    });

    // Update Goal
    builder.addCase(updateGoal.fulfilled, (state, { payload }) => {
      state.goals = state.goals.map((goal) =>
        goal.id === payload.id ? payload : goal
      );
    });
  },
});

const selectGoal = (state: RootState) => state.goals;

export const selectGoals = createSelector(selectGoal, (state) => state.goals);

export default goalsSlice.reducer;
