import React from "react";
import { Box, Typography } from "@mui/material";
import { SuccessIcon } from "assets/icons";
import { Button } from "components";

interface ISuccess {
  message: string;
  nextButtonText: string;
  onNext: () => void;
  onSkip?: () => void;
  skipBtn?: boolean;
}
const SuccessPage = ({
  message,
  nextButtonText,
  onNext,
  onSkip,
  skipBtn,
}: ISuccess) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={"100%"}
      height={"100%"}
      justifyItems={"center"}
      mt={20}
    >
      <SuccessIcon />
      <Typography variant="h2" color={"primary.900"} sx={{ mt: 6 }}>
        Success
      </Typography>
      <Typography variant="h6" color={"primary.600"} sx={{ mt: 4 }}>
        {message}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onNext}
        sx={{ width: 386, height: 48, mt: 12 }}
        label={nextButtonText}
        size="large"
      />
      {skipBtn && (
        <Button
          variant="text"
          color="primary"
          onClick={onSkip}
          sx={{ width: 386, height: 48, mt: 4 }}
          label="Skip"
          size="large"
          labelSx={{ textDecoration: "underline" }}
        />
      )}
    </Box>
  );
};
export default SuccessPage;
