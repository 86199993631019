import * as yup from "yup";

const createGoalSchema = yup.object().shape({
  name: yup
    .string()
    .required("Goal name is required")
    .min(3, "The name must consist of at least 3 symbols."),
  description: yup
    .string()
    .required("Description is required")
    .min(3, "The description must consist of at least 3 symbols."),
  dateToCompete: yup.date().required("Date to complete is required"),
  color: yup.string().required("Color is required"),
  goalAmount: yup
    .number()
    .required("Goal amount is required")
    .min(1, "Amount must be greater than zero"),
  file: yup.mixed().required("Photo is required"),
});

export { createGoalSchema };
